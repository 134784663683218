import { PROVIDE_FEEDBACK_URL } from "src/app/constants";
import { SIM_TYPES } from "../simLinkGenerator/types";
export interface LinkConfig {
  label: string;
  type: "normal" | "sim" | "navigation";
  to?: string;
  simType?: SIM_TYPES;
}
export const LINK_LIST: LinkConfig[] = [
  {
    label: "Report a Bug",
    simType: SIM_TYPES.REPORT_A_BUG,
    type: "sim",
  },
  {
    label: "Suggest a Feature",
    simType: SIM_TYPES.SUGGEST_A_FEATURE,
    type: "sim",
  },
  {
    label: "Provide Feedback",
    to: PROVIDE_FEEDBACK_URL,
    type: "normal",
  },
  {
    label: "Report Missing Site",
    simType: SIM_TYPES.REPORT_MISSING_SITE,
    type: "sim",
  },
];
export const FOOTER_TITLE = "Kubera Team";
