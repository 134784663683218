import React from "react";
import { useEffect, useState } from "react";

import {
  Box,
  SpaceBetween,
  Button,
  Form,
  Header,
  ContentLayout,
  Select,
  SegmentedControl,
  SelectProps,
  SegmentedControlProps,
  Grid,
  Spinner,
  Alert,
  Link,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import type { RootState } from "../../../redux/store";
import {
  createSite,
  getSite,
  listSites,
  resetSitesState,
  updateSite,
} from "../../../sites/sitesSlice";
import FormContent from "src/forms/FormContent";
import { SITE_CONFIGURATION_CONSTANTS } from "src/forms/constants";
import * as SiteConfigurationForm from "../../../forms/WorkersCompensation/SiteConfigurationForm";
import {
  resetFormsState,
  saveForm,
  setValidating,
  validateAll,
} from "src/forms/formsSlice";
import { FormStateType } from "src/forms/types";
import { cloneDeep, isEqual } from "lodash";
import { USA_STATES } from "src/forms/options";
import { resetWizardState } from "src/components/wizard/wizardSlice";
import { ACTION_TYPES, Site } from "../../../sites/types";
import { initialState as initialFormsState } from "../../../forms/formsSlice";
import moment from "moment";
import { SiteTypes } from "../../../sites/constants";

const SiteConfiguration = () => {
  /**
   * Setup Redux variables and get state
   */
  const dispatch = useAppDispatch();

  const {
    sitesList,
    listingSites,
    adminPanelSite,
    gettingSite,
    creatingSite,
    updatingSite,
    siteApiError,
  } = useAppSelector((state: RootState) => state.sites);
  const initialSitesFormState =
    initialFormsState.allFormStates[SITE_CONFIGURATION_CONSTANTS.FORM_NAME];
  //Redux Forms state
  const { allFormStates, errors, validating } = useAppSelector(
    (state: RootState) => state.forms
  );
  const formState: FormStateType = cloneDeep(
    allFormStates[SITE_CONFIGURATION_CONSTANTS.FORM_NAME]
  );

  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);

  /**
   * Local state
   */
  const [site, setSite] = useState<SelectProps.Option | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>(
    ACTION_TYPES.CREATE
  );
  const [alert, setAlert] = useState<{
    display: boolean;
    type: "error" | "success" | "info";
    contents: string | React.ReactNode;
  }>({
    display: false,
    contents: "",
    type: "error",
  });
  const [submitedForm, setSubmittedForm] = useState<boolean>(false);

  /**
   * Hook to load sites list, runs on first render
   */
  useEffect(() => {
    dispatch(resetFormsState());
    dispatch(resetWizardState());
    dispatch(resetSitesState());
    dispatch(listSites({ bypassCache: true }));
    resetPageState(selectedAction);
  }, []);

  /**
   * Enable Change requested by fields
   */

  const notificationAliasList =
    allFormStates[SITE_CONFIGURATION_CONSTANTS.FORM_NAME]?.[
      SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST
    ]?.value;
  const notificationWebhook =
    allFormStates[SITE_CONFIGURATION_CONSTANTS.FORM_NAME]?.[
      SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK
    ]?.value;
  useEffect(() => {
    if (!site || !adminPanelSite || selectedAction === ACTION_TYPES.CREATE) {
      return;
    }
    const emailNotificationRequestedBy = {
      ...formState[
        SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY
      ],
    };
    const notificationWebhookRequestedBy = {
      ...formState[
        SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY
      ],
    };
    let comparableAliasList = [];
    if (Array.isArray(notificationAliasList)) {
      comparableAliasList = notificationAliasList.map((item: any) => {
        return item.value;
      });
    }
    if (
      !isEqual(comparableAliasList, adminPanelSite.notificationAliasList) &&
      emailNotificationRequestedBy.disabled
    ) {
      dispatch(
        saveForm({
          formName: SITE_CONFIGURATION_CONSTANTS.FORM_NAME,
          formState: {
            ...formState,
            emailNotificationRequestedBy: {
              ...emailNotificationRequestedBy,
              disabled: false,
              value: "",
            },
          },
        })
      );
    }
    if (
      !isEqual(notificationWebhook, adminPanelSite?.notificationWebhook) &&
      notificationWebhookRequestedBy.disabled
    ) {
      dispatch(
        saveForm({
          formName: SITE_CONFIGURATION_CONSTANTS.FORM_NAME,
          formState: {
            ...formState,
            notificationWebhookRequestedBy: {
              ...notificationWebhookRequestedBy,
              disabled: false,
              value: "",
            },
          },
        })
      );
    }
  }, [notificationAliasList, notificationWebhook]);

  /**
   * Hook to load site information when current site changes
   */
  useEffect(() => {
    if (!adminPanelSite) {
      return;
    }
    const tempFormState = { ...cloneDeep(formState) };

    for (const key in tempFormState) {
      const currenSiteValue =
        adminPanelSite[key as keyof typeof adminPanelSite];
      let value;
      let disabled = false;
      if (key === SITE_CONFIGURATION_CONSTANTS.STATE) {
        const state = USA_STATES.find((state: any) => {
          return state.value === currenSiteValue;
        });
        value = state;
      }
      if (key === SITE_CONFIGURATION_CONSTANTS.COUNTRY) {
        value = { value: "US", label: "United States" };
        disabled = true;
      }
      if (key === SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST) {
        value = [];
        for (const alias of currenSiteValue as string[]) {
          value.push({ value: alias, errors: [] });
        }
      }
      if (
        key === SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY ||
        key === SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY
      ) {
        disabled = true;
      }
      if (value === undefined) {
        value = currenSiteValue;
      }
      tempFormState[key] = {
        ...tempFormState[key],
        value,
        disabled,
      };
      dispatch(
        saveForm({
          formName: SITE_CONFIGURATION_CONSTANTS.FORM_NAME,
          formState: tempFormState,
        })
      );
    }
  }, [adminPanelSite]);

  /**
   * Hook to submit site
   */
  useEffect(() => {
    if (validating || !submitedForm) {
      return;
    }
    if (errors?.length) {
      setAlert({
        display: true,
        contents: "Please fix the errors in the form and try again",
        type: "error",
      });
      setSubmittedForm(false);
      return;
    }
    submitSite();
  }, [validating, submitedForm]);

  /**
   * Hook to show alert on success or error
   */
  useEffect(() => {
    if (!submitedForm) {
      return;
    }
    if (
      (creatingSite && selectedAction === ACTION_TYPES.CREATE) ||
      (updatingSite && selectedAction === ACTION_TYPES.EDIT)
    ) {
      return;
    }
    setSubmittedForm(false);
    if (siteApiError?.error) {
      setAlert({
        display: true,
        contents: siteApiError?.error.message,
        type: "error",
      });

      return;
    }
    setAlert({
      display: true,
      contents: (
        <span>
          {selectedAction === ACTION_TYPES.CREATE
            ? "Site created successfully!"
            : "Site updated successfully!"}
        </span>
      ),
      type: "success",
    });
    resetPageState(selectedAction);
  }, [siteApiError, creatingSite, updatingSite]);

  const onSelectSite = (selectedOption: SelectProps.Option) => {
    setSite(selectedOption);
    dispatch(
      getSite({ code: selectedOption.value!, type: SiteTypes.ADMIN_PANEL_SITE })
    );
    setAlert({ display: false, contents: "", type: "info" });
  };
  const onSiteRefresh = () => {
    dispatch(listSites({ bypassCache: true }));
    setAlert({ display: false, contents: "", type: "info" });
  };
  const onSelectFunction = (selectedId: string) => {
    setSelectedAction(selectedId);
    resetPageState(selectedId);
    setAlert({ display: false, contents: "", type: "info" });
  };

  const resetPageState = (action: string) => {
    dispatch(resetFormsState());
    if (action === ACTION_TYPES.EDIT) {
      setDisabledInForm(true, initialSitesFormState);
    } else {
      setDisabledInForm(false, {
        ...initialSitesFormState,
      });
    }
    setSite(null);
  };

  const setDisabledInForm = (
    disabled: boolean,
    formStateToUpdate: FormStateType
  ) => {
    const tempFormState = { ...cloneDeep(formStateToUpdate) };
    for (const key in tempFormState) {
      tempFormState[key].disabled = disabled;
    }
    dispatch(
      saveForm({
        formName: SITE_CONFIGURATION_CONSTANTS.FORM_NAME,
        formState: tempFormState,
      })
    );
  };

  const submitSite = () => {
    const tempFormState = { ...cloneDeep(formState) };
    const siteToSubmit: Site = {};
    for (const key in tempFormState) {
      if (
        SITE_CONFIGURATION_CONSTANTS.STATE === key ||
        SITE_CONFIGURATION_CONSTANTS.COUNTRY === key
      ) {
        siteToSubmit[key] = tempFormState[key].value.value;
        continue;
      }
      if (SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST === key) {
        siteToSubmit[key] = tempFormState[key].value.map((entry: any) => {
          return entry.value;
        });
        continue;
      }
      siteToSubmit[key as keyof Site] = tempFormState[key].value;
    }
    siteToSubmit[
      SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_UPDATED_AT_TIMESTAMP
    ] = moment().unix();

    siteToSubmit[
      SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_UPDATED_AT_TIMESTAMP
    ] = moment().unix();

    siteToSubmit[SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY] =
      tempFormState[
        SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY
      ]?.value || user.id;

    siteToSubmit[
      SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY
    ] =
      tempFormState[
        SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY
      ]?.value || user.id;
    siteToSubmit[SITE_CONFIGURATION_CONSTANTS.LAST_MODIFIED_BY] = user.id || "";
    if (selectedAction === ACTION_TYPES.CREATE) {
      dispatch(createSite({ site: siteToSubmit as Site }));
    }
    if (selectedAction === ACTION_TYPES.EDIT) {
      dispatch(updateSite({ site: siteToSubmit as Site }));
    }
  };
  const onSubmit = () => {
    setAlert({ display: false, contents: "", type: "success" });
    dispatch(setValidating({ value: true }));
    dispatch(
      validateAll({
        formName: SITE_CONFIGURATION_CONSTANTS.FORM_NAME,
        formState,
      })
    );
    setSubmittedForm(true);
  };
  const renderAlert = () => {
    return alert.display ? (
      <Box padding={{ bottom: "m" }}>
        <Alert type={alert.type}>{alert.contents}</Alert>
      </Box>
    ) : null;
  };
  const renderSiteSelect = () => {
    return selectedAction === ACTION_TYPES.EDIT ? (
      <React.Fragment>
        <Box data-testid="site-configuration-select-site-wrapper">
          <Select
            selectedOption={site}
            options={sitesList}
            filteringType="auto"
            virtualScroll={true}
            statusType={listingSites ? "loading" : "finished"}
            placeholder="Select a Site to Edit"
            onChange={({ detail }: { detail: SelectProps.ChangeDetail }) =>
              onSelectSite(detail.selectedOption)
            }
            loadingText="Loading Sites"
            key="site-configuration-site-select"
            data-testid="site-configuration-select-site"
          />
        </Box>
        <Button variant="normal" onClick={onSiteRefresh} loading={listingSites}>
          Refresh Site List
        </Button>
      </React.Fragment>
    ) : null;
  };
  const renderForm = () => {
    return gettingSite || creatingSite || updatingSite ? (
      <Box textAlign="center">
        <Spinner size="large" />{" "}
      </Box>
    ) : (
      <Form key="site-configuration-form" errorText="" errorIconAriaLabel="">
        <FormContent
          formName={SITE_CONFIGURATION_CONSTANTS.FORM_NAME}
          formConfig={SiteConfigurationForm.formConfig}
          formValidation={SiteConfigurationForm.formValidation}
          data-testid="site-configuration-form"
          key="site-configuration-form"
        />
      </Form>
    );
  };
  return (
    <ContentLayout
      header={
        <Header
          description={
            <span>
              Please refer to{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://w.amazon.com/bin/view/Treasury/TreasuryTech/ClaimIntake/ProductAdmin/SiteManagementRunbook/"
              >
                Site Management Runbook
              </Link>{" "}
              for guidance.
            </span>
          }
          actions={
            <SegmentedControl
              selectedId={selectedAction}
              onChange={({
                detail,
              }: {
                detail: SegmentedControlProps.ChangeDetail;
              }) => onSelectFunction(detail.selectedId)}
              label="Default segmented control"
              options={[
                { text: "Edit Site", id: ACTION_TYPES.EDIT },
                { text: "Add Site", id: ACTION_TYPES.CREATE },
              ]}
              key="site-configuration-create-edit"
              data-testid="site-configuration-create-edit"
            />
          }
        >
          Site Configuration Tool
        </Header>
      }
      maxContentWidth={1000}
    >
      {renderAlert()}
      <SpaceBetween direction="vertical" size="m">
        <Grid
          gridDefinition={
            selectedAction === ACTION_TYPES.EDIT
              ? [{ colspan: 5 }, { colspan: 3 }]
              : []
          }
          data-testid="site-configuration-actions-grid"
        >
          {renderSiteSelect()}
        </Grid>
        {renderForm()}

        <Box
          key="site-configuration-buttons"
          float="right"
          data-testid="site-configuration-buttons"
        >
          <SpaceBetween
            key="site-configuration-buttons-spacer"
            direction="horizontal"
            size="m"
          >
            <Button
              key="site-configuration-submit"
              variant="primary"
              disabled={
                listingSites ||
                gettingSite ||
                (!site && selectedAction === ACTION_TYPES.EDIT)
              }
              onClick={() => onSubmit()}
            >
              {selectedAction === ACTION_TYPES.EDIT
                ? "Submit Changes"
                : "Create Site"}
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </ContentLayout>
  );
};
export default SiteConfiguration;
