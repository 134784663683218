import React, { useEffect, useState } from "react";
import WorkersCompensationApp from "../app/WorkersCompensation/WorkersCompensationApp";

import {
  AppLayout,
  ContentLayout,
  Box,
  Spinner,
} from "@amzn/awsui-components-react/polaris";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { PAGE_TITLES } from "src/app/constants";
import SideMenu from "../components/sideMenu/SideMenu";

const ClaimIntakeFormPage = () => {
  const [workersCompensationAppKey, setWorkersCompensationAppKey] = useState(1);
  const [navigationOpen, setNavigationOpen] = useState(true);

  //Redux state
  const { stateIsValid, requestAppReset } = useAppSelector(
    (state: RootState) => state.app
  );
  /**
   * Changes the browser tab/menu bar title to Claim Intake Form when page loads
   */
  useEffect(() => {
    document.title = PAGE_TITLES.CLAIM_INTAKE_FORM;
  }, []);
  /**
   * useEffect to reset the WorkersCompensationApp component if an app reset was requested
   */
  useEffect(() => {
    if (requestAppReset) {
      setWorkersCompensationAppKey(workersCompensationAppKey + 1);
    }
  }, [requestAppReset]);
  const content = stateIsValid ? (
    <WorkersCompensationApp key={workersCompensationAppKey} />
  ) : (
    <div className="centered">
      <ContentLayout>
        <Box textAlign="center">
          <Spinner size="large"></Spinner>
        </Box>
      </ContentLayout>
    </div>
  );
  return (
    <AppLayout
      content={content}
      navigation={<SideMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      ariaLabels={{
        navigationClose: "Close side menu",
        navigationToggle: "Open Side menu",
      }}
      toolsHide={true}
      contentType="wizard"
      footerSelector="#app-footer"
      headerSelector="#app-header"
    />
  );
};
export default ClaimIntakeFormPage;
