import React from "react";
import {
  useRouteError,
  isRouteErrorResponse,
  Link as ReactRouterLink,
} from "react-router-dom";
import { ContentLayout, Box } from "@amzn/awsui-components-react/polaris";
const ErrorPage = () => {
  const error = useRouteError();
  let content: React.ReactNode = null;
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      content = (
        <div className="centered">
          <ContentLayout>
            <Box variant="h1" textAlign="center">
              404 Error
            </Box>
            <Box variant="h2" textAlign="center">
              Page not found
            </Box>
            <Box variant="p" textAlign="center">
              Click on the link below to go to the homepage
            </Box>
            <Box variant="p" textAlign="center">
              <ReactRouterLink to="/">Claim Intake Homepage</ReactRouterLink>
            </Box>
          </ContentLayout>
        </div>
      );
    }
  }
  return content;
};
export default ErrorPage;
