import { Role, User } from "../../auth/types/interfaces";

/**
 * Loader function for React Router that runs when the user navigates to the Claims History page.
 * This function checks if the user has the required roles to access the page.
 *
 * @param {User} user - The user object containing the user's information, including roles.
 * @returns {Promise<Response>} A Promise that resolves with a Response object.
 *   If the user has the required roles, it returns a 200 "OK" response.
 *   If the user does not have the required roles, it throws a 404 "Not Found" response.
 */
export const loader = async (user: User) => {
  if (user.roles?.includes(Role.WC_CLAIM_USER_LIMITED) || !user.roles?.length) {
    throw new Response("Not Found", { status: 404 });
  } else {
    return new Response("OK", { status: 200 });
  }
};
