import { formFieldsConfigProcessed } from "./config/SiteConfigurationFormConfig";
import { SITE_CONFIGURATION_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/SiteConfigurationFormState";
import { formFieldsValidation } from "./validations/SiteConfigurationFormValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Associate Information";
export const formName = SITE_CONFIGURATION_CONSTANTS.FORM_NAME;
