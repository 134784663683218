import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  GENERAL_FORM_CONSTANTS,
} from "../../constants";
import { FormValidationType } from "../../types";

export const formFieldsValidation: FormValidationType = {
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID]: {
    required: { value: true },
    maxCharacters: { value: 11 },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
      message: "Please enter only numbers in Associate Employee ID",
    },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS]: {
    required: { value: true },
    maxCharacters: { value: 11 },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_LETTERS_AND_NUMBERS_REGEX),
      message: "Please enter only numbers and letters in Associate Alias/login",
    },
    changeToLowerCase: {
      value: true,
    },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME]: {
    required: {
      value: true,
      message: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_DETAILS_REQUIRED_ERROR,
    },
    maxCharacters: { value: 50 },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME]: {
    required: {
      value: true,
      message: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_DETAILS_REQUIRED_ERROR,
    },
    maxCharacters: { value: 50 },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME]: {
    required: {
      value: true,
      message: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_DETAILS_REQUIRED_ERROR,
    },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE]: {
    required: { value: false },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE]: {
    required: { value: false },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS]: {
    required: { value: false },
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME]: {
    required: { value: false },
  },
};
