import { TableProps } from "@amzn/awsui-components-react";
import { ParsedClaimSummary } from "../searchResults/constants";

export function createTableSortLabelFn(
  column: TableProps.ColumnDefinition<ParsedClaimSummary>
): TableProps.ColumnDefinition<ParsedClaimSummary>["ariaLabel"] {
  if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
    return;
  }
  return ({ sorted, descending }) => {
    return `${column.header}, ${
      sorted
        ? `sorted ${descending ? "descending" : "ascending"}`
        : "not sorted"
    }.`;
  };
}

const wcClaimsTableColumns = [
  {
    id: "site",
    sortingField: "incidentSiteName",
    cell: (item: ParsedClaimSummary) => item.incidentSiteName,
    header: "Incident Site",
  },
  {
    id: "claimNumber",
    sortingField: "claimNumber",
    header: "Claim Number",
    cell: (item: ParsedClaimSummary) => item.claimNumber,
    width: 180,
  },
  {
    id: "employeeLogin",
    sortingField: "associateAlias",
    header: "Employee Login",
    cell: (item: ParsedClaimSummary) => item.associateAlias,
  },
  {
    id: "employeeID",
    sortingField: "associateEmployeeId",
    cell: (item: ParsedClaimSummary) => item.associateEmployeeId,
    header: "Employee ID",
  },
  {
    id: "incidentDate",
    sortingField: "incidentDate",
    cell: (item: ParsedClaimSummary) => item.incidentDate,

    header: "Incident Date",
  },
  {
    id: "createdAtDate",
    sortingField: "createdAtDate",
    cell: (item: ParsedClaimSummary) => item.createdAtDate,
    header: "Submitted Date",
  },
  {
    id: "submitterLogin",
    sortingField: "claimReporterAlias",
    cell: (item: ParsedClaimSummary) => item.claimReporterAlias,
    header: "Submitter Login",
  },
  {
    id: "bodyPart",
    cell: (item: ParsedClaimSummary) => generateBodyPartDescription(item),
    sortingComparator: (a: unknown, b: unknown): number => {
      return generateBodyPartDescription(a).localeCompare(
        generateBodyPartDescription(b)
      );
    },
    header: "Body Part",
  },
];

export const WC_CLAIM_COLUMN_DEFINITIONS = wcClaimsTableColumns.map(
  (column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  })
);

export const PAGINATION_LABELS = {
  nextPageLabel: "Next page",
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: "Previous page",
};
function isParsedClaimSummary(param: any): param is ParsedClaimSummary {
  return true;
}
export function generateBodyPartDescription(item: any): string {
  if (!isParsedClaimSummary(item)) {
    return "";
  }
  return (
    item.primaryInjuredBodyPartDescription +
    (item.secondaryInjuredBodyPartDescription
      ? " " + item.secondaryInjuredBodyPartDescription
      : "")
  );
}
