import { formFieldsConfigProcessed } from "./config/NotificationsConfig";
import { NOTIFICATIONS_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/NotificationsState";
import { formFieldsValidation } from "./validations/NotificationsValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Notifications";
export const formDescription = "Review Notifications setup for your site.";
export const formName = NOTIFICATIONS_CONSTANTS.FORM_NAME;
