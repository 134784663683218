import React from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react/polaris";

const TableEmptyState = ({ resourceName }: { resourceName: string }) => (
  <Box
    margin={{ vertical: "xs" }}
    textAlign="center"
    color="inherit"
    data-testid="table-empty-state"
  >
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s matched this search.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);

export default TableEmptyState;
