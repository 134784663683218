import { ReactNode } from "react";
export interface ResetFormModalContents {
  title: string;
  description: ReactNode;
  confirm: string;
  cancel: string;
}
export enum ClaimSubmittedStatus {
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}
