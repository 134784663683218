import { FormStateType } from "../../types";
import { SITE_CONFIGURATION_CONSTANTS } from "../../constants";

export const formFieldsState: FormStateType = {
  [SITE_CONFIGURATION_CONSTANTS.CODE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.UNIT_NUMBER]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_1]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_2]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.CITY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.STATE]: {
    value: null,
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.ZIP_CODE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.COUNTRY]: {
    value: { value: "US", label: "United States" },
    errors: [],
    hidden: false,
    disabled: true,
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST]: {
    value: [{ value: "", errors: [] }],
    hidden: false,
    errors: [],
    otherInformation: {
      associateAlias: "",
      submitterAlias: "",
    },
  },
  [SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [SITE_CONFIGURATION_CONSTANTS.LAST_MODIFIED_BY]: {
    value: "",
    errors: [],
    hidden: true,
  },
};
