import "./App.css";
import React, { useEffect, useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  resetFormsState,
  verifyStateIsValid as verifyFormsState,
} from "src/forms/formsSlice";
import {
  resetAppState,
  setValueInAppState,
  verifyStateIsValid as verifyAppState,
} from "./appSlice";
import {
  resetWizardState,
  verifyStateIsValid as verifyWizardState,
} from "src/components/wizard/wizardSlice";
import { RootState } from "src/redux/store";
import moment from "moment";
import { resetReviewPageState } from "src/forms/reviewPage/reviewPageSlice";
import { RouterProvider } from "react-router-dom";
import { setUserInformation } from "src/auth/setUserInformation";
import type { User } from "src/auth/types/interfaces";
import { resetSitesState } from "src/sites/sitesSlice";

interface AppProps {
  user: User;
  router: any;
}
function App({ user, router }: AppProps) {
  const dispatch = useAppDispatch();
  const resetWorkersCompensationApp = () => {
    //Reset app state and forms state to initial state every time form is reset.
    dispatch(resetAppState());
    dispatch(resetFormsState());
    dispatch(resetWizardState());
    dispatch(resetReviewPageState());
    dispatch(resetSitesState());
    dispatch(setValueInAppState({ key: "stateIsValid", value: true }));
  };
  /**
   * Get only necessary states from Redux state to prevent unnecessary re-renders
   */
  const formsState = useAppSelector((state: RootState) => state.forms);
  const appState = useAppSelector((state: RootState) => state.app);
  const wizardState = useAppSelector((state: RootState) => state.wizard);
  const { appLoaded, requestAppReset } = appState;

  /**
   * Reset the app contents if state is invalid.
   */
  useLayoutEffect(() => {
    if (
      !verifyFormsState(formsState) ||
      !verifyAppState(appState) ||
      !verifyWizardState(wizardState)
    ) {
      resetWorkersCompensationApp();
      dispatch(
        setValueInAppState({ key: "invalidStateModalOpen", value: true })
      );
    }
    dispatch(setValueInAppState({ key: "appLoaded", value: true }));
  }, []);

  /**
   * Handle page focus to reset state if needed
   */
  useEffect(() => {
    const handlePageFocus = () => {
      // If the the user has been away from the page
      // longer then the set refresh time reload the page
      const refreshTime = 60;
      const awayTimeStamp = localStorage.getItem("awayTimeStamp");
      if (awayTimeStamp !== null) {
        const diff = moment().diff(awayTimeStamp, "minutes");
        localStorage.removeItem("awayTimeStamp");
        diff > refreshTime ? location.reload() : null;
      }
    };

    const handlePageFocusOut = () => {
      // Set a current timestamp and place in local storage when user leaves page
      localStorage.setItem("awayTimeStamp", moment().format());
    };

    setUserInformation(user);

    window.addEventListener("focus", handlePageFocus);
    window.addEventListener("focusout", handlePageFocusOut);
    return () => {
      window.removeEventListener("focus", handlePageFocus);
      window.removeEventListener("focusout", handlePageFocusOut);
    };
  }, []);

  /**
   * Set state as valid after app is loaded and state has been checked
   */
  useEffect(() => {
    if (appLoaded) {
      dispatch(setValueInAppState({ key: "stateIsValid", value: true }));
    }
  }, [appLoaded]);

  /**
   * Reset the app contents if requested.
   */
  useEffect(() => {
    if (requestAppReset) {
      resetWorkersCompensationApp();
      dispatch(setValueInAppState({ key: "requestAppReset", value: false }));
    }
  }, [requestAppReset]);

  return <RouterProvider router={router} />;
}

export default App;
