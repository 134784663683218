import { FormFieldType } from "../types";
import { Multiselect, MultiselectProps } from "@amzn/awsui-components-react";
import React from "react";

const renderMultiselect = (
  field: FormFieldType,
  onChange: ({
    detail,
  }: {
    detail: MultiselectProps.MultiselectChangeDetail;
  }) => void,
  onBlur: (field: FormFieldType) => void
) => {
  const name = field.config.uniqueName || field.config.name;
  const options = field.state.options || field.config.options!;
  return (
    <Multiselect
      selectedOptions={field.state.value}
      onChange={onChange}
      placeholder={field.config.placeholderText}
      options={options}
      filteringType="auto"
      deselectAriaLabel={(e: any) => `Remove ${e?.label}`}
      selectedAriaLabel="Selected"
      onBlur={() => onBlur(field)}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-Multiselect`}
    />
  );
};

export default renderMultiselect;
