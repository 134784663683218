import { FormFieldType } from "../types";
import { TimeInput, TimeInputProps } from "@amzn/awsui-components-react";

const renderTimeInput = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: TimeInputProps.ChangeDetail }) => void,
  onBlur: (field: FormFieldType) => void
) => {
  const name = field.config.uniqueName || field.config.name;
  return (
    <TimeInput
      value={field.state.value}
      format={field.config.timeFormat}
      placeholder={field.config.placeholderText}
      onChange={onChange}
      onBlur={() => onBlur(field)}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-timeInput`}
    />
  );
};

export default renderTimeInput;
