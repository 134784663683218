import * as AssociateInformation from "./WorkersCompensation/AssociateInformation";
import * as IncidentTimeAndPlace from "./WorkersCompensation/IncidentTimeAndPlace";

import * as IncidentInformation from "./WorkersCompensation/IncidentInformation";
import * as PhysicianInformation from "./WorkersCompensation/PhysicianInformation";
import * as FileAttachments from "./WorkersCompensation/FileAttachments";
import * as Notifications from "./WorkersCompensation/Notifications";

import * as SiteConfiguration from "./WorkersCompensation/SiteConfigurationForm";

import { AllFormsType } from "./types";

export const allForms: AllFormsType = {
  [AssociateInformation.formName]: {
    formState: AssociateInformation.formState,
    formConfig: AssociateInformation.formConfig,
    formValidation: AssociateInformation.formValidation,
  },
  [IncidentTimeAndPlace.formName]: {
    formState: IncidentTimeAndPlace.formState,
    formConfig: IncidentTimeAndPlace.formConfig,
    formValidation: IncidentTimeAndPlace.formValidation,
  },
  [IncidentInformation.formName]: {
    formState: IncidentInformation.formState,
    formConfig: IncidentInformation.formConfig,
    formValidation: IncidentInformation.formValidation,
  },
  [PhysicianInformation.formName]: {
    formState: PhysicianInformation.formState,
    formConfig: PhysicianInformation.formConfig,
    formValidation: PhysicianInformation.formValidation,
  },
  [FileAttachments.formName]: {
    formState: FileAttachments.formState,
    formConfig: FileAttachments.formConfig,
    formValidation: FileAttachments.formValidation,
  },
  [Notifications.formName]: {
    formState: Notifications.formState,
    formConfig: Notifications.formConfig,
    formValidation: Notifications.formValidation,
  },
  [SiteConfiguration.formName]: {
    formState: SiteConfiguration.formState,
    formConfig: SiteConfiguration.formConfig,
    formValidation: SiteConfiguration.formValidation,
  },
};
export const workersCompensationForms = {
  [AssociateInformation.formName]: allForms[AssociateInformation.formName],
  [IncidentTimeAndPlace.formName]: allForms[AssociateInformation.formName],
  [IncidentInformation.formName]: allForms[IncidentInformation.formName],
  [PhysicianInformation.formName]: allForms[PhysicianInformation.formName],
  [FileAttachments.formName]: allForms[FileAttachments.formName],
  [Notifications.formName]: allForms[Notifications.formName],
};
