import { formFieldsConfigProcessed } from "./config/PhysicianInformationConfig";
import { PHYSICIAN_INFORMATION_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/PhysicianInformationState";
import { formFieldsValidation } from "./validations/PhysicianInformationValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Hospital/Medical Provider Information";
export const formDescription =
  "Provide details regarding Hospital and/or Medical Provider, if available.";
export const formName = PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME;
