import { BUTTON_VARIANTS, FormFieldType } from "../types";
import { Button, Link, ButtonProps } from "@amzn/awsui-components-react";
import React, { KeyboardEvent } from "react";

const renderButton = (field: FormFieldType, onClick: (arg0?: any) => void) => {
  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    /**
     * For accessibility reason we want to be able to mimic onClick behavior
     * when Space bar or Enter are pressed.
     */
    if (e.key === "Enter" || e.key === " ") {
      onClick();
    }
  };
  if (!onClick) {
    return null;
  }
  const name = field.config.uniqueName || field.config.name;
  return field.config.variant === BUTTON_VARIANTS.LINK ? (
    <div onClick={onClick} onKeyDown={onKeyDown} key={`${name}-button`}>
      <Link
        href=""
        variant="primary"
        data-testid={field.config.uniqueName || field.config.name}
      >
        {field.state.value}
      </Link>
    </div>
  ) : (
    <Button
      onClick={onClick}
      variant={field.config.variant as ButtonProps.Variant}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-button`}
      loading={field.state?.loading ? field.state.loading : false}
      disabled={field.state.disabled}
    >
      {field.state.value}
    </Button>
  );
};

export default renderButton;
