import CONSTANTS, { Region, Environments } from "./Constants";

/**
 * Function for get the current stage based on URL.
 */
export function getCurrentStage(): string {
  switch (window.location.hostname) {
    case CONSTANTS.PROD_HOSTNAME:
      return Environments.PROD;
    case CONSTANTS.PREPROD_HOSTNAME:
      return Environments.GAMMA;
    case CONSTANTS.BETA_HOSTNAME:
      return Environments.BETA;
    case CONSTANTS.ALPHA_HOSTNAME:
      return Environments.ALPHA;
    default:
      return Environments.LOCAL;
  }
}

export function getRegion(): string {
  return Region.US_WEST_2;
}

/**
 * Function that will return true if the hostname has a certain domain. Else returns false.
 *
 * @returns boolean
 */
export function isHostNativeAWSWebsite() {
  return RegExp(/(aws\.dev|a2z\.org\.cn)/).test(window.location.hostname);
}

export const getEnvironment = () => {
  const browserUrl = window.location.href;
  if (browserUrl.includes("localhost")) {
    return Environments.LOCAL;
  } else if (browserUrl.includes("alpha")) {
    return Environments.ALPHA;
  } else if (browserUrl.includes("beta")) {
    return Environments.BETA;
  } else if (browserUrl.includes("gamma")) {
    return Environments.GAMMA;
  } else if (browserUrl.includes("https://claims.risk.amazon.dev")) {
    return Environments.PROD;
  } else {
    console.log("Could not determine the endpoint from URL. Returning beta.");
    return Environments.BETA;
  }
};

export const isDevEnvironment = () => {
  return [Environments.LOCAL, Environments.ALPHA, Environments.BETA].includes(
    getEnvironment()
  );
};

export const isPreProdEnvironment = () => {
  return getEnvironment() !== Environments.PROD;
};
