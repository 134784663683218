import { formFieldsConfigProcessed } from "./config/IncidentInformationConfig";
import { INCIDENT_INFORMATION_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/IncidentInformationState";
import { formFieldsValidation } from "./validations/IncidentInformationValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Incident Details";
export const formDescription = "Provide details regarding the incident.";
export const formName = INCIDENT_INFORMATION_CONSTANTS.FORM_NAME;
