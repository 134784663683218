import {
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps,
} from "@amzn/awsui-components-react/polaris";
import * as React from "react";
export interface DataItem {
  value: string | React.ReactNode;
  label: string;
  type?: StatusIndicatorProps.Type | "custom";
}
let keyIndex = 0;
const ValueWithLabel = ({
  label,
  children,
  dataTestId,
}: {
  label: any;
  children: any;
  dataTestId: any;
}) => (
  <div data-testid={dataTestId}>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);
interface DataGridProps {
  columns: number;
  itemsPerColumn: number;
  dataItems: DataItem[];
  header: string;
  ["data-testid"]?: string;
}

const DataGrid = (props: DataGridProps) => {
  function renderColumn(column: DataItem[]) {
    const valuesWithLabel: JSX.Element[] = [];
    if (!column || !column.length) {
      return (
        <SpaceBetween
          size="l"
          key={`empty-column-${keyIndex++}`}
          data-testid="data-grid-empty-column"
        ></SpaceBetween>
      );
    }
    for (let i = 0; i < props.itemsPerColumn; i++) {
      const content =
        column[i]?.type === "error" ? (
          <StatusIndicator type={"error"}>{column[i]?.value}</StatusIndicator>
        ) : (
          column[i]?.value
        );
      valuesWithLabel.push(
        <ValueWithLabel
          key={`${column[i]?.label}-${column[i]?.value}-${keyIndex++}`}
          label={column[i]?.label}
          dataTestId={`${column[i]?.label}-${column[i]?.value}`}
        >
          {content}
        </ValueWithLabel>
      );
    }
    return (
      <SpaceBetween
        key={`${column[0]?.label}-${column[0]?.value}-${keyIndex++}`}
        size="l"
      >
        {valuesWithLabel}
      </SpaceBetween>
    );
  }
  function renderContent() {
    const columnsToRender: JSX.Element[] = [];
    const { itemsPerColumn } = props;
    for (let i = 0; i < props.columns; i++) {
      const values = props.dataItems.slice(
        itemsPerColumn * i,
        itemsPerColumn * i + itemsPerColumn
      );
      columnsToRender.push(renderColumn(values));
    }
    return columnsToRender;
  }
  return (
    <Container
      data-testid={props["data-testid"]}
      header={
        <Header variant="h2" data-testid="data-grid-header">
          {props.header}{" "}
        </Header>
      }
    >
      <ColumnLayout columns={props.columns} variant="text-grid">
        {renderContent()}
      </ColumnLayout>
    </Container>
  );
};
export default DataGrid;
