// calculate for CONSTANTS.TIMEZONE
const dateString: string = new Date().toString();
const timezoneString: string = dateString.substring(
  dateString.lastIndexOf("(")
);

export enum Region {
  US_EAST_1 = "us-east-1",
  US_WEST_2 = "us-west-2",
}

export enum Environments {
  LOCAL = "local",
  ALPHA = "alpha",
  BETA = "beta",
  GAMMA = "gamma",
  PROD = "prod",
}

const CONSTANTS = {
  ALPHA_HOSTNAME: "alpha.claim.risk.amazon.dev",
  BETA_HOSTNAME: "beta.claim.risk.amazon.dev",
  PREPROD_HOSTNAME: "gamma.claim.risk.amazon.dev",
  PROD_HOSTNAME: "claim.risk.amazon.dev",

  REALM: {
    USAmazon: "USAmazon",
  },
  APPLICATION_NAME: {
    Claims: "Claims Home",
    ClaimMetricsSiteName: "Nucleus",
    ClaimsMetricsServiceName: "WcClaimIntake",
  },
  TIMEZONE: timezoneString,
  REGION_TO_AIRPORT_CODE_MAP: {
    [Region.US_EAST_1]: "IAD",
    [Region.US_WEST_2]: "PDX",
  },
};

export default CONSTANTS;
