import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction, current } from "@reduxjs/toolkit";
import { compareKeys } from "src/forms/tools";
// Define a type for the slice state
interface ReviewPageState {
  formsStateFirstLoad: any;
  formsStateSaved: boolean;
}
export const initialState: ReviewPageState = {
  formsStateFirstLoad: null,
  formsStateSaved: false,
};
export const verifyStateIsValid = (state: ReviewPageState) => {
  return compareKeys(state, initialState);
};
export const reviewPageSlice = createSlice({
  name: "reviewPage",
  initialState,
  reducers: {
    setFormsStateFirstLoad: (
      state: ReviewPageState,
      action: PayloadAction<{
        value: any;
      }>
    ) => {
      const { value } = action.payload;
      if (!current(state).formsStateSaved) {
        state.formsStateFirstLoad = value;
        state.formsStateSaved = true;
      }
    },
    resetReviewPageState: () => {
      return initialState;
    },
  },
});

export const { setFormsStateFirstLoad, resetReviewPageState } =
  reviewPageSlice.actions;

export default reviewPageSlice.reducer;
