import { TTechClaimIntakeClientConfig } from "@amzn/ttechclaimintakeservice-client";
import { Mapper } from "./types";
import { getEnvironment } from "../environmentInfo/EnvironmentInfo";
import { Environments } from "src/environmentInfo/Constants";
import { API_ENDPOINTS, LOCALHOST_API_ENDPOINT } from "./constants";
const config: TTechClaimIntakeClientConfig = {
  endpoint: "",
  region: "us-west-2",
};
const envMapper: Mapper = {
  // developers can change to alpha for local testing if needed.
  // They must ensure that cognito pool and api gateway pointed by UI
  // are always in sync
  local: { endpoint: LOCALHOST_API_ENDPOINT },
  alpha: {
    endpoint: API_ENDPOINTS[Environments.ALPHA],
  },
  beta: {
    endpoint: API_ENDPOINTS[Environments.BETA],
  },
  gamma: {
    endpoint: API_ENDPOINTS[Environments.GAMMA],
  },
  prod: {
    endpoint: API_ENDPOINTS[Environments.PROD],
  },
};

export const buildConfigForEnvironment = () => {
  const returnConfig = config;
  const environment = getEnvironment();

  if (environment in envMapper) {
    returnConfig.endpoint = envMapper[environment].endpoint;
  }

  return returnConfig;
};
