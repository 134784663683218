import { FormFieldType } from "../types";
import { DatePicker, DatePickerProps } from "@amzn/awsui-components-react";
import React from "react";
import moment from "moment";

const renderDatePicker = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: DatePickerProps.ChangeDetail }) => void,
  onBlur: (field: FormFieldType) => void
) => {
  const name = field.config.uniqueName || field.config.name;
  return (
    <DatePicker
      onChange={onChange}
      value={field.state.value}
      placeholder={field.config.placeholderText}
      openCalendarAriaLabel={(selectedDate: null | string) =>
        `Choose ${field.config.label}` +
        (selectedDate ? `, selected date is ${selectedDate}` : "")
      }
      ariaLabel="Incident Date"
      i18nStrings={{
        currentMonthAriaLabel: "Current month",
        nextMonthAriaLabel: "Next month",
        previousMonthAriaLabel: "Previous month",
        todayAriaLabel: "Today",
        previousYearAriaLabel: "Previous year",
        nextYearAriaLabel: "Next year",
      }}
      onBlur={() => onBlur(field)}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-datePicker`}
      isDateEnabled={(date: Date) => {
        return (
          moment(date).isSameOrAfter(moment().subtract(5, "years"), "day") &&
          moment(date).isSameOrBefore(moment(), "day")
        );
      }}
    />
  );
};

export default renderDatePicker;
