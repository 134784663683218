import { Alert } from "@amzn/awsui-components-react/polaris";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import moment from "moment-timezone";

const WorkersCompensationAlert = () => {
  // Use the useAppSelector hook to retrieve the tpaMaintenance value from the Redux store
  const { tpaMaintenance } = useAppSelector((state: RootState) => state.app);

  function generateAnchorMaintenanceMessage() {
    // Get timestamp, translate it to Central time and change the time to 1:00:00 AM, then translate
    // to local time and print in Local Time format example: "11:00 PM"
    const localTime = moment()
      .utc()
      .tz("America/Chicago")
      .hour(1)
      .minute(0)
      .second(0)
      .local()
      .format("LT");
    return (
      <span>
        <span>
          The Third-Party Administrator that this claim will be routed to is
          currently undergoing routine maintenance. Claim submission will be
          re-enabled once maintenance is complete at {localTime}.
        </span>
      </span>
    );
  }

  // Create a variable tpaMaintenanceAlert that will hold the Alert component
  // If TPA is undergoing maintenance, render the Alert component with the maintenance message
  // Otherwise, set tpaMaintenanceAlert to null (don't render anything)
  const tpaMaintenanceAlert = tpaMaintenance ? (
    <Alert
      type="warning"
      header="Maintenance Notice"
      statusIconAriaLabel="Warning"
      dismissible={false}
      data-testid="workers-compensation-alert"
    >
      {generateAnchorMaintenanceMessage()}
    </Alert>
  ) : null;

  return tpaMaintenanceAlert;
};
export default WorkersCompensationAlert;
