import { Environments } from "../environmentInfo/Constants";
import { LOCALHOST_SERVICE_ENVIRONMENT } from "../app/constants";
export const COGNITO_CONFIGS = {
  [Environments.ALPHA]: {
    AppWebDomain:
      "wc-claim-user-pool-domain-alpha.auth.us-west-2.amazoncognito.com",
    ClientId: "5kvfdv0mbci50cuproie2s5nbc",
    RedirectUriSignIn: "https://alpha.claims.risk.amazon.dev/",
    RedirectUriSignOut: "https://alpha.claims.risk.amazon.dev/",
    TokenScopesArray: ["openid", "email", "profile"],
    UserPoolId: "us-west-2_Q6lEUqxEE",
  },
  [Environments.BETA]: {
    AppWebDomain:
      "wc-claim-user-pool-domain-beta.auth.us-west-2.amazoncognito.com",
    ClientId: "656dvphf0t9qrqjpa6c7nv73v6",
    RedirectUriSignIn: "https://beta.claims.risk.amazon.dev/",
    RedirectUriSignOut: "https://beta.claims.risk.amazon.dev/",
    TokenScopesArray: ["openid", "email", "profile"],
    UserPoolId: "us-west-2_Ol9CF3JDw",
  },
  [Environments.GAMMA]: {
    AppWebDomain:
      "wc-claim-user-pool-domain-gamma.auth.us-west-2.amazoncognito.com",
    ClientId: "400d5iq58ch0qbodp21925nqbs",
    RedirectUriSignIn: "https://gamma.claims.risk.amazon.dev/",
    RedirectUriSignOut: "https://gamma.claims.risk.amazon.dev/",
    TokenScopesArray: ["openid", "email", "profile"],
    UserPoolId: "us-west-2_2lzmaptyv",
  },
  [Environments.PROD]: {
    AppWebDomain:
      "wc-claim-user-pool-domain-prod.auth.us-west-2.amazoncognito.com",
    ClientId: "7404l53ml54horna87t84fm1o1",
    RedirectUriSignIn: "https://claims.risk.amazon.dev/",
    RedirectUriSignOut: "https://claims.risk.amazon.dev/",
    TokenScopesArray: ["openid", "email", "profile"],
    UserPoolId: "us-west-2_j7S0bREZ0",
  },
};
export const LOCALHOST_COGNITO_CONFIG = {
  ...COGNITO_CONFIGS[LOCALHOST_SERVICE_ENVIRONMENT],
  RedirectUriSignIn: "http://localhost:3000/",
  RedirectUriSignOut: "http://localhost:3000/",
};
