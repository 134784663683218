export interface Site {
  code?: string;
  name?: string;
  unitNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  notificationAliasList?: string[];
  notificationWebhook?: string;
  lastModifiedBy?: string;
  emailNotificationRequestedBy?: string;
  emailNotificationUpdatedAtTimestamp?: number;
  notificationWebhookRequestedBy?: string;
  notificationWebhookUpdatedAtTimestamp?: number;
}
export enum ACTION_TYPES {
  EDIT = "edit",
  CREATE = "create",
}
