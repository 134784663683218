import { ANCHOR, HELMSMAN, SEDGWICK } from "../../constants";
import { FormOverride } from "../../types";
import { anchorFormOverride } from "./AnchorFormOverride";
import { helmsmanFormOverride } from "./HelmsmanFormOverride";
import { sedgwickFormOverride } from "./SedgwickFormOverride";

export interface FormOverrides {
  [key: string]: FormOverride;
}
export const TPA_FORM_OVERRIDES: { [key: string]: FormOverride } = {
  [ANCHOR]: anchorFormOverride,
  [SEDGWICK]: sedgwickFormOverride,
  [HELMSMAN]: helmsmanFormOverride,
};
