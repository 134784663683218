export interface CognitoUserInfo {
  profile?: string;
  given_name: string;
  family_name: string;
  email: string;
  "cognito:username": string;
  "custom:location": string;
  "custom:manager": string;
  "custom:alias": string;
  "custom:jobDescription": string;
}
export interface User {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  roles: Role[];
  siteCode: string;
  managerAlias?: string;
  jobDescription?: string;
}

export enum Role {
  WC_CLAIM_USER_LIMITED = "wc-claim-user-limited",
  WC_CLAIM_USER = "wc-claim-user",
  WC_CLAIM_PRODUCT_GROUP = "wc-claim-pm",
}

export const ROLE_DISPLAY_NAME: { [key: string]: string } = {
  [Role.WC_CLAIM_USER_LIMITED]: "WHS Limited User Role",
  [Role.WC_CLAIM_USER]: "WHS User Role",
  [Role.WC_CLAIM_PRODUCT_GROUP]: "Product Group User Role",
};
