import { FormFieldType, SelectFormField } from "../types";
import Select, {
  SelectProps,
} from "@amzn/awsui-components-react/polaris/select";
import React, { RefObject } from "react";

const renderSelect = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: SelectProps.ChangeDetail }) => void,
  onBlur: (field: FormFieldType) => void,
  ref: RefObject<SelectProps.Ref> | null | undefined
) => {
  const fieldConfig = field.config as SelectFormField;
  let options = field.state.options || fieldConfig.options!;
  // Add Clear Selection option for all non-required Select fields
  options = !field.validation.required?.value
    ? [{ label: "-- Clear Selection --", value: "___NO_VALUE___" }, ...options]
    : options;
  const name = fieldConfig.uniqueName || fieldConfig.name;
  const defaultRenderHighlightedAriaLive = (option: SelectProps.Option) => {
    return option.label!;
  };

  return (
    <Select
      selectedOption={field.state.value}
      onChange={onChange}
      placeholder={fieldConfig.placeholderText}
      options={options}
      filteringType="auto"
      onBlur={() => onBlur(field)}
      data-testid={fieldConfig.uniqueName || fieldConfig.name}
      key={`${name}-select`}
      disabled={field.state.disabled}
      virtualScroll={options.length > 300}
      renderHighlightedAriaLive={
        fieldConfig.renderHighlightedAriaLive ||
        defaultRenderHighlightedAriaLive
      }
      statusType={field.state.loading ? "loading" : "finished"}
      loadingText="Loading..."
      ref={ref}
    />
  );
};

export default renderSelect;
