import { FormFieldType } from "../types";
import {
  Box,
  Checkbox,
  CheckboxProps,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import React from "react";
import { FILE_ATTACHMENTS_CONSTANTS } from "../constants";

const renderCheckboxText = (disabled?: boolean) => {
  const color = disabled ? "text-status-inactive" : "inherit";
  return (
    <Box color={color}>
      <SpaceBetween direction="vertical" size="xxs">
        <div>
          The Initial report Form (IRF) is not available at the time of claim
          submission as emergency treatment was required.
        </div>
        <div>
          <span>
            The IRF will be provided as soon as it becomes available to GRMC
            Support via{" "}
          </span>
          <Link
            target="_blank"
            ariaLabel="Press this link to open a new tab where you will be able to create a ticket to upload the IRF file."
            href="https://t.corp.amazon.com/create/templates/f36e21f0-e0ae-4dfe-826b-d91ecb49bc6a"
          >
            SIM Ticket.{" "}
          </Link>
        </div>
      </SpaceBetween>
    </Box>
  );
};
const renderCheckbox = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: CheckboxProps.ChangeDetail }) => void
) => {
  return (
    <Checkbox
      onChange={onChange}
      checked={field.state.value}
      disabled={field.state.disabled}
      data-testid={field.config.uniqueName || field.config.name}
    >
      {field.config.name === FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE
        ? renderCheckboxText(field.state.disabled)
        : field.config.checkboxText}
    </Checkbox>
  );
};

export default renderCheckbox;
