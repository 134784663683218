import { PHYSICIAN_INFORMATION_CONSTANTS } from "../../constants";
import { FormStateType } from "../../types";

export const formFieldsState: FormStateType = {
  [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
    value: "",
    errors: [],
    hidden: true,
    ignoreInFinalForm: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE]: {
    value: "false",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]: {
    hidden: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_FIRST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_LAST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_1]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_2]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_CITY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_STATE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ZIPCODE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_COUNTRY]: {
    value: { value: "US", label: "United States" },
    errors: [],
    hidden: false,
    disabled: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION]:
    {
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]: {
    hidden: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_FIRST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_LAST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_1]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_2]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_CITY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_STATE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ZIPCODE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_COUNTRY]: {
    value: { value: "US", label: "United States" },
    errors: [],
    hidden: false,
    disabled: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION]:
    {
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
  addPhysician: {
    value: PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER,
    errors: [],
    hidden: true,
    ignoreInFinalForm: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.TRANSPORTED_TO_HOSPITAL]: {
    value: null,
    hidden: false,
    errors: [],
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION]: {
    hidden: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_1]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_2]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_CITY]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_STATE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ZIPCODE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_COUNTRY]: {
    value: { value: "US", label: "United States" },
    errors: [],
    hidden: false,
    disabled: true,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION]: {
    hidden: false,
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      value: "",
      errors: [],
      hidden: false,
    },
};
