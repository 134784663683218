import { redirect } from "react-router-dom";
export const loader = async () => {
  // Get the original href that was saved before the Cognito auth began
  // and redirect to that path if it's available. Return default OK response
  // if no href is found.
  const hrefBeforeGetSession = sessionStorage.getItem("hrefBeforeGetSession");
  if (hrefBeforeGetSession) {
    sessionStorage.removeItem("hrefBeforeGetSession");
    return redirect(hrefBeforeGetSession);
  }
  return new Response("OK", { status: 200 });
};
