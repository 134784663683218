import { LOCALHOST_SERVICE_ENVIRONMENT } from "../app/constants";
import { Environments } from "../environmentInfo/Constants";

export const API_ENDPOINTS = {
  [Environments.ALPHA]:
    "https://apigw.alpha.claims.risk.amazon.dev",
  [Environments.BETA]:
    "https://apigw.beta.claims.risk.amazon.dev",
  [Environments.GAMMA]:
    "https://apigw.gamma.claims.risk.amazon.dev",
  [Environments.PROD]:
    "https://apigw.claims.risk.amazon.dev",
};

export const LOCALHOST_API_ENDPOINT =
  API_ENDPOINTS[LOCALHOST_SERVICE_ENVIRONMENT];
