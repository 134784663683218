import * as React from "react";
import {
  Modal,
  Box,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { requestAppReset, setValueInAppState } from "src/app/appSlice";
import { RootState } from "src/redux/store";

const InvalidStateModal = () => {
  const dispatch = useAppDispatch();
  const { invalidStateModalOpen } = useAppSelector(
    (state: RootState) => state.app
  );

  const dismissModal = () => {
    dispatch(requestAppReset());
    dispatch(
      setValueInAppState({ key: "invalidStateModalOpen", value: false })
    );
  };
  return (
    <Modal
      onDismiss={() => dismissModal()}
      visible={invalidStateModalOpen}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => dismissModal()}
              data-testid="modal-ok-button"
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Invalid Form Format"
    >
      Due to a recent update, we have to reset the website and you will lose the
      progress you have made so far. Please restart the claim submission
      process. We apologize for the inconvenience.
    </Modal>
  );
};
export default InvalidStateModal;
