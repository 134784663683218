import React from "react";
import SearchResults from "../../components/searchResults/SearchResults";
import MySubmittedClaims from "../../components/mySubmittedClaims/MySubmittedClaims";
import {
  Container,
  ContentLayout,
  Header,
  Tabs,
} from "@amzn/awsui-components-react";
/**
 * View for the Claims History Page.
 * @returns ClaimsHistory table component
 */
const ClaimsHistory = () => {
  return (
    <ContentLayout
      disableOverlap={false}
      header={<Header variant="h1">Claims History</Header>}
    >
      <Container fitHeight>
        <Tabs
          fitHeight
          data-testid="claims-history-tabs"
          tabs={[
            {
              label: "Search for Claims",
              id: "searchResults",
              content: <SearchResults />,
            },
            {
              label: "My Submitted Claims",
              id: "mySubmittedClaims",
              content: <MySubmittedClaims />,
            },
          ]}
        />
      </Container>
    </ContentLayout>
  );
};

export default ClaimsHistory;
