import * as React from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ensureAuthenticated } from "./auth/authenticate";
import { parseAuthInformation } from "./auth/setUserInformation";
import { MetricsPublisher } from "./metrics";
import { I18nProvider } from "@amzn/awsui-components-react/polaris/i18n";
import messages from "@amzn/awsui-components-react/polaris/i18n/messages/all.all";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { createAppRouter } from "./utils";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function startApp() {
  const metricsPublisher = new MetricsPublisher("api.authenticate");
  const metricsPublisherError = new MetricsPublisher("api.authenticate.error");
  const metricsVisitedPublisher = new MetricsPublisher("portalVisited");
  metricsVisitedPublisher.publishVisited();
  ensureAuthenticated()
    .then((auth: CognitoAuth) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishAuthenticationSuccessMetrics();
      /**
       * React Router definition, this needs to be defined outside the context of the React flow
       * for the React Router data API to work properly.
       * (https://reactrouter.com/en/main/routers/create-browser-router)
       */
      const user = parseAuthInformation(auth);
      const router = createAppRouter(user);
      root.render(
        <React.StrictMode>
          <Provider store={store}>
            <I18nProvider messages={[messages]}>
              <div className="my-app">
                <App user={user} router={router} />
              </div>
            </I18nProvider>
          </Provider>
        </React.StrictMode>
      );
    })
    .catch((err: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishAuthenticationFailureMetrics();
      metricsPublisherError.publishAuthenticationError(err);
      console.log("Authentication Error", err);
    });
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
