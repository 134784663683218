import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { compareKeys } from "../../forms/tools";
import { listMedicalPanelDataFilesService } from "../../api/medicalPanelData/listMedicalPanelDataFilesService";
import { getMedicalPanelFileService } from "../../api/medicalPanelData/getMedicalPanelFileService";

import { ListMedicalPanelFilesCommandInput } from "@amzn/ttechclaimintakeservice-client";

export interface MedicalPanelFile {
  fileName: string;
  lastModifiedTimestamp: number;
}
// Define a type for the slice state
export interface MedicalPanelDataState {
  [index: string]: any;
  files: MedicalPanelFile[];
  fileUrl: string;
  filesRetrieved: boolean;
  gettingFiles: boolean;
  fileUrlRetrieved: boolean;
  gettingFileUrl: boolean;
  listFilesError: string;
  getFileUrlError: string;
}
export const initialState: MedicalPanelDataState = {
  files: [],
  fileUrl: "",
  filesRetrieved: false,
  gettingFiles: false,
  fileUrlRetrieved: false,
  gettingFileUrl: false,
  listFilesError: "",
  getFileUrlError: "",
};

export const verifyStateIsValid = (state: MedicalPanelDataState) => {
  return compareKeys(state, initialState);
};
/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listMedicalPanelDataFiles = createAsyncThunk(
  "medicalPanelData/listMedicalDataPanelFiles",
  async (input: ListMedicalPanelFilesCommandInput, thunkAPI: any) => {
    const response = await listMedicalPanelDataFilesService(input);
    return response;
  }
);

export const getMedicalPanelFile = createAsyncThunk(
  "medicalPanelData/getMedicalPanelFile",
  async ({ fileName }: { fileName: string }, thunkAPI: any) => {
    const response = await getMedicalPanelFileService({ fileName });
    return { ...response };
  }
);
/*eslint-enable @typescript-eslint/no-unused-vars*/

export const medicalPanelDataSlice = createSlice({
  name: "medicalPanelData",
  initialState,
  reducers: {
    resetMedicalPanelDataState: () => {
      return initialState;
    },
    setNewMedicalPanelDataState: (
      state: MedicalPanelDataState,
      action: PayloadAction<{
        newState: MedicalPanelDataState;
      }>
    ) => {
      const { newState } = action.payload;

      return newState;
    },
    setValueInMedicalPanelDataState: (
      state: MedicalPanelDataState,
      action: PayloadAction<{
        key: string;
        value: string | boolean | MedicalPanelFile[];
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        listMedicalPanelDataFiles.fulfilled,
        (state: MedicalPanelDataState, action: PayloadAction<any>) => {
          const { files } = action.payload;
          state.files = files;
          state.gettingFiles = false;
          state.filesRetrieved = true;
        }
      )
      .addCase(
        listMedicalPanelDataFiles.pending,
        (state: MedicalPanelDataState) => {
          state.gettingFiles = true;
          state.listFilesError = "";
        }
      )
      .addCase(
        listMedicalPanelDataFiles.rejected,
        (state: MedicalPanelDataState, action: any) => {
          state.gettingFiles = false;
          state.filesRetrieved = false;
          state.listFilesError = action.error.message;
        }
      )

      .addCase(
        getMedicalPanelFile.fulfilled,
        (state: MedicalPanelDataState, action: PayloadAction<any>) => {
          const { downloadUrl } = action.payload;
          state.fileUrl = downloadUrl;
          state.fileUrlRetrieved = true;
          state.gettingFileUrl = false;
          state.getFileUrlError = "";
        }
      )
      .addCase(getMedicalPanelFile.pending, (state: MedicalPanelDataState) => {
        state.gettingFileUrl = true;
        state.getFileUrlError = "";
      })
      .addCase(
        getMedicalPanelFile.rejected,
        (state: MedicalPanelDataState, action: any) => {
          state.fileUrlRetrieved = false;
          state.gettingFileUrl = false;
          state.getFileUrlError = action.error.message;
        }
      );
  },
});

export const {
  resetMedicalPanelDataState,
  setValueInMedicalPanelDataState,
  setNewMedicalPanelDataState,
} = medicalPanelDataSlice.actions;

export default medicalPanelDataSlice.reducer;
