import { ensureAuthenticated } from "./authenticate";

export const getAuthenticationToken = async () => {
  const auth: any = await ensureAuthenticated();
  const token = auth.getSignInUserSession().getIdToken().getJwtToken();
  if (token) {
    return token;
  }
  return null;
};
