import { FILE_ATTACHMENTS_CONSTANTS } from "../../constants";
import { FormStateType } from "../../types";

export const formFieldsState: FormStateType = {
  [FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM]: {
    value: [],
    hidden: false,
    errors: [],
  },

  [FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE]: {
    value: false,
    hidden: false,
    errors: [],
  },

  [FILE_ATTACHMENTS_CONSTANTS.AUSTIN_NOTES]: {
    value: [],
    hidden: false,
    errors: [],
  },
  [FILE_ATTACHMENTS_CONSTANTS.AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION]: {
    value: [],
    hidden: false,
    errors: [],
  },
  [FILE_ATTACHMENTS_CONSTANTS.STATE_FORMS]: {
    value: [],
    hidden: false,
    errors: [],
  },
  [FILE_ATTACHMENTS_CONSTANTS.WITNESS_STATEMENTS]: {
    value: [],
    hidden: false,
    errors: [],
  },
  [FILE_ATTACHMENTS_CONSTANTS.PHOTOS]: {
    value: [],
    hidden: false,
    errors: [],
  },
  [FILE_ATTACHMENTS_CONSTANTS.OTHER_RELEVANT]: {
    value: [],
    hidden: false,
    errors: [],
  },
};
