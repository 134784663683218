import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { Role, User } from "./types/interfaces";

// Define a type for the slice state
export interface AuthState {
  authenticating: boolean;
  user: User;
}
export const initialState: AuthState = {
  authenticating: false,
  user: {
    id: "",
    givenName: "",
    familyName: "",
    email: "",
    roles: [],
    siteCode: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state: AuthState,
      action: PayloadAction<{
        user: {
          id: string;
          givenName: string;
          familyName: string;
          email: string;
          roles: Role[];
          siteCode: string;
        };
      }>
    ) => {
      const { user } = action.payload;
      state.user = user;
    },
    setAuthenticating: (
      state: AuthState,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      const { value } = action.payload;
      state.authenticating = value;
    },
  },
});

export const { setUser, setAuthenticating } = authSlice.actions;

export default authSlice.reducer;
