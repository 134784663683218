export interface SerializedFile {
  s3Key: string;
  name: string;
  type: string;
  lastModified: number;
  size: number;
  webkitRelativePath?: string;
}
export interface S3StagedFile extends File {
  s3Key?: string;
}

export enum FileSize {
  BYTES = "bytes",
  KB = "KB",
  KIB = "KiB",
  MB = "MB",
  MIB = "MiB",
  GB = "GB",
  GIB = "GiB",
}
