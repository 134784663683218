import { cloneDeep } from "lodash";
import {
  FormFieldConfigType,
  INPUT_TYPES,
  FormFieldStateType,
  FormStateType,
} from "./types";
import { SITE_ADDRESSES } from "./addresses";
import { AMAZON_CLAIM_REFERENCE_ID_MAX_SIZE } from "src/app/constants";
import short from "short-uuid";
const uuidGenerator = short();

/**
 * Helper function for processing form fields. Adds an index and ancestor list to
 * help retrieve config information when fields are nested within other group fields.
 *
 * @param formFieldsList - The list of form field configurations to populate.
 * @param fieldsToProcess - The list of form fields to process.
 * @param ancestors - The list of ancestor fields.
 * @param index - The index of the current field.
 */
function innerFieldsProcessHelper(
  formFieldsList: FormFieldConfigType[],
  fieldsToProcess: FormFieldConfigType[],
  ancestors: { name: string; index: number }[],
  index: number
) {
  for (const field of fieldsToProcess) {
    if (!field) continue;

    // Set the ancestors for the current field
    field.ancestors = ancestors;

    if (field?.type === INPUT_TYPES.FIELD_GROUP) {
      // Recursively process the fields within the field group
      innerFieldsProcessHelper(
        formFieldsList,
        field.fields,
        [...ancestors, { name: field.uniqueName || field.name, index: index! }],
        0
      );
    }
    if (ancestors?.length) {
      // Set the index and ancestors for the field
      field.index = index;
      field.ancestors = ancestors;
    } else {
      // Add the field to the formFieldsList
      formFieldsList.push({ ...field, index });
    }

    // Increment the index for each level children
    index += 1;
  }
}
/**
 * Pre-processes a raw list of form fields and generates a configuration array for the form fields.
 *
 * @param rawFieldsList - The raw list of form fields to be processed.
 * @returns An array of form field configurations.
 */
export function preProcessFormFields(rawFieldsList: any) {
  const formFieldsConfig: FormFieldConfigType[] = [];

  innerFieldsProcessHelper(formFieldsConfig, cloneDeep(rawFieldsList), [], 0);
  return formFieldsConfig;
}

/**
 * Checks if a form field is empty.
 *
 * @param field - The form field state object to check.
 * @returns True if the field is empty, false otherwise.
 */
export function checkIfFieldIsEmpty(field: FormFieldStateType) {
  return !field.value || (field.value instanceof Array && !field.value?.length);
}

/**
 * Checks if an array of fields are empty or hidden
 *
 * @param formConfig - The config of the current form being validated
 * @param formState - The state of the current form being validated
 * @param fieldNames[] - Array of the names of all the fields to check
 * @returns True if the any of the fields are empty or hidden, false otherwise.
 */
export function checkIfFieldsAreEmptyOrHidden(
  formConfig: FormFieldConfigType[],
  formState: FormStateType,
  fieldNames: string[]
) {
  return fieldNames.some((fieldName) => {
    const fieldConfig = getFieldConfig(fieldName, formConfig);
    const fieldState = formState[fieldName];
    if (!fieldConfig) return true;
    return (
      !fieldState.value ||
      (fieldState.value instanceof Array && !fieldState.value?.length) ||
      fieldState.hidden ||
      checkIfAncestorsAreHidden(formState, fieldConfig)
    );
  });
}

/**
 * Adds an error message to an array of errors. Used to add errors to the
 * field state.
 *
 * @param error - The error message to add.
 * @param errors - The array of existing error messages.
 */
export function addError(error?: string, errors?: string[]) {
  if (!error || !errors) return;
  if (!errors?.includes(error)) {
    errors.push(error);
  }
}
/**
 * Removes an error message from an array of errors. Used to remove errors
 * from the field state.
 *
 * @param error - The error message to remove.
 * @param errors - The array of existing error messages.
 */
export function removeError(error?: string, errors?: string[]) {
  if (!error || !errors?.length) return;
  const index = errors.indexOf(error);
  if (index === -1) return;
  errors = [...errors.splice(index, 1)];
}

/**
 * Loops through the formConfig definition to find the config for a specific field using
 * the field name in case we need to retrieve it.
 *
 * @param fieldName - The name or unique name of the field to retrieve the configuration for.
 * @param fieldConfigArray - The array of field configurations to search through.
 * @returns The configuration object for the specified field, or null if not found.
 */
export function getFieldConfig(
  fieldName: string,
  fieldConfigArray: FormFieldConfigType[]
): FormFieldConfigType | null {
  let fieldConfigResult = null;
  for (const fieldConfig of fieldConfigArray) {
    if (
      fieldConfig.name === fieldName ||
      fieldConfig.uniqueName === fieldName
    ) {
      return fieldConfig;
    }
    if (fieldConfig.type === INPUT_TYPES.FIELD_GROUP) {
      fieldConfigResult = getFieldConfig(fieldName, fieldConfig.fields);
      if (fieldConfigResult) {
        return fieldConfigResult;
      }
    }
  }
  return null;
}

/**
 * Removes a site from the list of Amazon sites
 *
 * @param {string} siteToRemove Site code of the Amazon site that should be removed.
 * @param {{value: string, label: string}[]} sitesList Site List to filter
 * @return list of Amazon sites without the site specified.
 */
export function filterAmazonSites(
  siteToRemove: string,
  sitesList: { value: string; label: string }[]
) {
  const sites = [...sitesList];
  const optionIndex = sitesList.findIndex(
    (site: { value: string; label: string }) => {
      return site.value === siteToRemove;
    }
  );

  sites.splice(optionIndex, 1);
  return sites;
}

/**
 * Checks if any ancestors of given field are hidden.
 *
 * @param {FormStateType} formState Current state of the form.
 * @param {FormFieldConfigType} fieldConfig Config of the field to check.
 * @return boolean
 */
export function checkIfAncestorsAreHidden(
  formState: FormStateType,
  fieldConfig: FormFieldConfigType
) {
  for (const ancestor of fieldConfig.ancestors!) {
    if (formState[ancestor.name]?.hidden) {
      return true;
    }
  }
  return false;
}

/**
 * Checks if site provided is valid
 *
 * @param {string} siteCode Amazon Site to check if valid
 * @return boolean
 */
export function isSiteValid(siteCode: string) {
  if (!siteCode) return false;
  return !!SITE_ADDRESSES[siteCode as keyof typeof SITE_ADDRESSES];
}

/**
 * @description Checks if both objects provided have the same keys
 */
export function compareKeys(
  a: { [key: string]: any },
  b: { [key: string]: any }
) {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

/**
 * Generates a new amazonClaimReferenceId with max
 * size == AMAZON_CLAIM_REFERENCE_ID_MAX_SIZE
 */
export const generateAmazonClaimReferenceId = () => {
  return uuidGenerator.new().slice(0, AMAZON_CLAIM_REFERENCE_ID_MAX_SIZE);
};

/**
 * Transforms "true" into true and "false" into false
 * if param is a different string returns param
 *
 * @param {string} toTransform String to transform
 */
export const stringToBoolean = (toTransform: string): string | boolean => {
  if (toTransform === "true") return true;
  else if (toTransform === "false") return false;
  else return toTransform;
};
