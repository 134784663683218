import { CustomField, FormFieldType } from "../types";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  NOTIFICATIONS_CONSTANTS,
} from "../constants";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import React from "react";

const renderCustomField = (field: FormFieldType) => {
  const dispatch = useAppDispatch();
  // Redux Sites state
  const { allFormStates } = useAppSelector((state: RootState) => state.forms);
  const { associateHomeSite, gettingSite } = useAppSelector(
    (state: RootState) => state.sites
  );

  const fieldConfig = field.config as CustomField;
  let customFieldParams = {};
  // Add the parameters that will be needed by the generator function in the case of
  // the READ_ONLY_SITE_NOTIFICATIONS custom field.
  if (
    field.config.name === NOTIFICATIONS_CONSTANTS.READ_ONLY_SITE_NOTIFICATIONS
  ) {
    field.state.value = associateHomeSite;
    customFieldParams = {
      associateHomeSite,
      dispatch,
      associateHomeSiteCode:
        allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME
        ]?.value,
      gettingSite,
    };
  }
  return (
    <React.Fragment>
      {fieldConfig.generatorFunction(customFieldParams)}
    </React.Fragment>
  );
};

export default renderCustomField;
