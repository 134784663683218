import { LINK_LIST, LinkConfig } from "./Footer_config";
import { useEffect, useState } from "react";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import { ROUTES } from "src/app/constants";
import SimLinkGenerator from "../simLinkGenerator/SimLinkGenerator";

const Footer = () => {
  const location = useLocation();
  const [showMedicalPanelDataLink, setShowMedicalPanelDataLink] =
    useState(false);
  useEffect(() => {
    setShowMedicalPanelDataLink(
      location.pathname === ROUTES.MEDICAL_PANEL_DATA
    );
  }, [location]);
  const medicalPanelDataIgnoreList = ["Report Missing Site"];
  const renderLinkList = (linkList: LinkConfig[]) => {
    return linkList.map((link, index) => {
      if (
        showMedicalPanelDataLink &&
        medicalPanelDataIgnoreList.includes(link.label)
      ) {
        return null;
      }
      if (link.type === "navigation" && link.to) {
        return (
          <ReactRouterLink key={index} to={link.to} target="_blank">
            <span className="footer-link">{link.label}</span>
          </ReactRouterLink>
        );
      }
      if (link.type === "sim") {
        return (
          <SimLinkGenerator
            color="inverted"
            variant="primary"
            target="_blank"
            key={index}
            simType={link.simType!}
            reactRouterLink={true}
          >
            <span className="footer-link">{link.label}</span>
          </SimLinkGenerator>
        );
      }
      return (
        <ReactRouterLink to={`${link.to}`} target="_blank" key={index}>
          <span className="footer-link">{link.label}</span>
        </ReactRouterLink>
      );
    });
  };

  return (
    <footer className="app-footer" id="app-footer">
      <div>{renderLinkList(LINK_LIST)}</div>
    </footer>
  );
};
export default Footer;
