import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction, current } from "@reduxjs/toolkit";
import { compareKeys } from "src/forms/tools";
// Define a type for the slice state
interface WizardState {
  goingBack: boolean;
  currentIndex: number;
  farthestStepIndex: number;
  skipToActive: number;
  skipToStep: number;
}
export const initialState: WizardState = {
  goingBack: false,
  currentIndex: 0,
  farthestStepIndex: 0,
  skipToActive: 0,
  skipToStep: -1,
};
export const verifyStateIsValid = (state: WizardState) => {
  return compareKeys(state, initialState);
};
export const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    setGoingBack: (
      state: WizardState,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      const { value } = action.payload;
      state.goingBack = value;
    },
    setSkipToStep: (
      state: WizardState,
      action: PayloadAction<{
        value: number;
      }>
    ) => {
      const { value } = action.payload;
      state.skipToStep = value;
    },
    copyLocalState: (
      state: WizardState,
      action: PayloadAction<{
        localState: any;
      }>
    ) => {
      const { localState } = action.payload;
      return { ...current(state), ...localState };
    },
    resetWizardState: () => {
      return initialState;
    },
  },
});

export const { setGoingBack, copyLocalState, resetWizardState, setSkipToStep } =
  wizardSlice.actions;

export default wizardSlice.reducer;
