import React, { useEffect, useState } from "react";
import { PAGE_TITLES } from "src/app/constants";
import SideMenu from "src/components/sideMenu/SideMenu";
import { AppLayout } from "@amzn/awsui-components-react";
import MySubmittedClaims from "src/components/mySubmittedClaims/MySubmittedClaims";

const MySubmittedClaimsPage = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  /**
   * Changes the browser tab/menu bar title to My Submitted Claims when page loads
   */
  useEffect(() => {
    document.title = PAGE_TITLES.MY_SUBMITTED_CLAIMS;
  }, []);

  return (
    <AppLayout
      content={<MySubmittedClaims />}
      navigation={<SideMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      toolsHide={true}
      ariaLabels={{
        navigationClose: "Close side menu",
        navigationToggle: "Open Side menu",
      }}
      contentType="table"
      footerSelector="#app-footer"
      headerSelector="#app-header"
    />
  );
};
export default MySubmittedClaimsPage;
