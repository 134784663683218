import {
  FormFieldConfigType,
  UploadFormField,
  INPUT_TYPES,
  CheckboxField,
} from "../../types";
import { preProcessFormFields } from "../../tools";
import { FILE_ATTACHMENTS_CONSTANTS } from "../../constants";

export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: "allFileAttachmentsFields",
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    label: "File Attachments",
    fields: [
      {
        name: FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM,
        label: "Initial Report Form (IRF)",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
        customAlert: {
          display: true,
          dismissible: false,
          type: "info",
          header: "",
          value: "",
          statusIconAriaLabel: "Info",
          dismissAriaLabel: "Dismiss",
        },
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE,
        label: "",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.CHECKBOX,
        constraintText: "",
        multiple: false,
        checkboxText: "",
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field
        ) => {
          const initialReportForm =
            formState[FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM];
          const validationOverride = detail.checked
            ? {
                required: {
                  value: false,
                },
              }
            : {
                required: {
                  value: true,
                  message: FILE_ATTACHMENTS_CONSTANTS.IRF_REQUIRED_ERROR,
                },
              };
          updateForm({
            [FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM]: {
              ...initialReportForm,
              errors: [],
              validationOverride,
            },
            [field.config.name]: { ...field.state, value: detail.checked },
          });
        },
      } as CheckboxField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.AUSTIN_NOTES,
        label: "Austin Notes",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION,
        label: "Authorization for Initial Medical Evaluation",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: false,
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.STATE_FORMS,
        label: "State Form(s)",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.WITNESS_STATEMENTS,
        label: "Witness Statement(s)",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.PHOTOS,
        label: "Photo(s)",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
      } as UploadFormField,
      {
        name: FILE_ATTACHMENTS_CONSTANTS.OTHER_RELEVANT,
        label: "Other Relevant Documents",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.UPLOAD,
        constraintText: "",
        multiple: true,
      } as UploadFormField,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
