import {
  RADIO_YES_NO,
  FormFieldConfigType,
  SelectFormField,
  TextInputFormField,
  RadioFormField,
  PhoneFormField,
  FormActionButton,
} from "../../types";
import { INPUT_TYPES, TEXT_INPUT_TYPES } from "../../types";
import { INITIAL_MEDICAL_TREATMENT_OPTIONS, USA_STATES } from "../../options";
import { preProcessFormFields } from "../../tools";
import { PHYSICIAN_INFORMATION_CONSTANTS } from "../../constants";

const PHYSICIAN_INFORMATION_DESCRIPTION =
  "Enter medical provider information if available. Please note that multiple medical providers' information can be added.";

const PHYSICIAN_INFORMATION_LABEL =
  "Did the associate seek medical treatment with primary care physician, ER or urgent care prior (bypass) to claim initiation?";

const PHONE_DESCRIPTION_TEXT =
  "Please enter a valid US phone number without country code";

export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: "allPhysicianInformationFields",
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    header: "",
    label: "Medical Provider Information",
    fields: [
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT,
        label:
          "What initial medical treatment or first aid, if any, was provided?",
        description: "",
        placeholderText: "Please select the type of initial treatment",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: INITIAL_MEDICAL_TREATMENT_OPTIONS,
      } as SelectFormField,
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.TRANSPORTED_TO_HOSPITAL,
        label: "Was the associate transported via ambulance to the hospital?",
        description: "Enter hospital information if available.",
        placeholderText: "",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.RADIO,
        options: RADIO_YES_NO,
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field
        ) => {
          const hospitalInformationHidden =
            detail.value === "true" ? false : true;
          const physicianInformationDisabled =
            detail.value === "true" ? true : false;
          const hospitalInformation =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION];
          const physicianInformationAvailable =
            formState[
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE
            ];
          const admittedToHospital = { ...field.state };

          const physicianInformation_0 =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0];
          const physicianInformation_1 =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1];
          const addPhysician =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN];
          const hidePhysicianInformation1 =
            detail.value === "true" ? true : physicianInformation_1.hidden;
          const addPhysicianValue = hidePhysicianInformation1
            ? PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER
            : PHYSICIAN_INFORMATION_CONSTANTS.REMOVE_MEDICAL_PROVIDER;
          updateForm({
            [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION]: {
              ...hospitalInformation,
              hidden: hospitalInformationHidden,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE]: {
              ...physicianInformationAvailable,
              disabled: physicianInformationDisabled,
              value: physicianInformationDisabled
                ? "false"
                : physicianInformationAvailable.value,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]: {
              ...physicianInformation_0,
              hidden: physicianInformationDisabled
                ? true
                : physicianInformation_0.hidden,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]: {
              ...physicianInformation_1,
              hidden: hidePhysicianInformation1,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN]: {
              ...addPhysician,
              hidden: physicianInformationDisabled ? true : addPhysician.hidden,
              value: addPhysicianValue,
            },
            [field.config.name]: {
              ...admittedToHospital,
              value: detail.value,
              errors: [],
            },
          });
        },
      } as RadioFormField,
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION,
        label: "Hospital Information",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: "name",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_NAME,
            label: "Hospital Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine1",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_1,
            label: "Address Line 1",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine2",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_2,
            label: "Address Line 2",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "city",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_CITY,
            label: "City",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "state",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_STATE,
            label: "State",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: USA_STATES,
          } as SelectFormField,
          {
            name: "zipCode",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ZIPCODE,
            label: "Postal Code",
            description: "",
            placeholderText: "",
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "country",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_COUNTRY,
            label: "Country",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: [{ value: "US", label: "United States" }],
          } as SelectFormField,
          {
            name: "phoneWithExtension",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION,
            label: "",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.FIELD_GROUP,
            contained: false,
            columns: 2,
            fields: [
              {
                name: "phoneNumber",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_PHONE_NUMBER,
                label: "Phone",
                description: PHONE_DESCRIPTION_TEXT,
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.PHONE,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
                constraintText: "",
              } as PhoneFormField,
              {
                name: "extension",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_EXTENSION,
                label: "Extension",
                description: " ",
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.TEXT,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
              } as TextInputFormField,
            ],
          },
        ],
      },
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE,
        label: PHYSICIAN_INFORMATION_LABEL,
        description: PHYSICIAN_INFORMATION_DESCRIPTION,
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.RADIO,
        options: RADIO_YES_NO,
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field
        ) => {
          const hidden = detail.value === "true" ? false : true;

          const physicianInformation_0 =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0];
          const physicianInformation_1 =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1];
          const addPhysician =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN];
          const physicianInformationAvailable = { ...field.state };
          const groupHide =
            detail.value === "false" ? true : physicianInformation_1.hidden;
          const addPhysicianValue = groupHide
            ? PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER
            : PHYSICIAN_INFORMATION_CONSTANTS.REMOVE_MEDICAL_PROVIDER;
          updateForm({
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]: {
              ...physicianInformation_0,
              hidden,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]: {
              ...physicianInformation_1,
              hidden: groupHide,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN]: {
              ...addPhysician,
              hidden,
              value: addPhysicianValue,
            },
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE]: {
              ...physicianInformationAvailable,
              value: detail.value,
            },
          });
        },
      } as RadioFormField,
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0,
        label: "",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: "firstName",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_FIRST_NAME,
            label: "First Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "lastName",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_LAST_NAME,
            label: "Last Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine1",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_1,
            label: "Address Line 1",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine2",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_2,
            label: "Address Line 2",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "city",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_CITY,
            label: "City",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "state",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_STATE,
            label: "State",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: USA_STATES,
          } as SelectFormField,
          {
            name: "zipCode",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ZIPCODE,
            label: "Postal Code",
            description: "",
            placeholderText: "",
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "country",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_COUNTRY,
            label: "Country",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: [{ value: "US", label: "United States" }],
          } as SelectFormField,
          {
            name: "phoneWithExtension",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION,
            label: "",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.FIELD_GROUP,
            contained: false,
            columns: 2,
            fields: [
              {
                name: "phoneNumber",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_PHONE_NUMBER,
                label: "Phone",
                description: PHONE_DESCRIPTION_TEXT,
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.PHONE,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
                constraintText: "",
              } as PhoneFormField,
              {
                name: "extension",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_EXTENSION,
                label: "Extension",
                description: " ",
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.TEXT,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
              } as TextInputFormField,
            ],
          },
        ],
      },
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1,
        label: "Additional Physician",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: "firstName",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_FIRST_NAME,
            label: "First Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "lastName",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_LAST_NAME,
            label: "Last Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine1",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_1,
            label: "Address Line 1",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "addressLine2",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_2,
            label: "Address Line 2",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "city",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_CITY,
            label: "City",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "state",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_STATE,
            label: "State",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: USA_STATES,
          } as SelectFormField,
          {
            name: "zipCode",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ZIPCODE,
            label: "Postal Code",
            description: "",
            placeholderText: "",
            errors: [],
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "country",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_COUNTRY,
            label: "Country",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: [{ value: "US", label: "United States" }],
          } as SelectFormField,
          {
            name: "phoneWithExtension",
            uniqueName:
              PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION,
            label: "",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.FIELD_GROUP,
            contained: false,
            columns: 2,
            fields: [
              {
                name: "phoneNumber",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_PHONE_NUMBER,
                label: "Phone",
                description: PHONE_DESCRIPTION_TEXT,
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.PHONE,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
                constraintText: "",
              } as PhoneFormField,
              {
                name: "extension",
                uniqueName:
                  PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_EXTENSION,
                label: "Extension",
                description: " ",
                placeholderText: "",
                hasPopover: false,
                type: INPUT_TYPES.TEXT,
                textInputType: TEXT_INPUT_TYPES.DEFAULT,
              } as TextInputFormField,
            ],
          },
        ],
      },
      {
        name: PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN,
        label: "",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.BUTTON,
        variant: "link",
        onClick: (formState, updateForm, field, setFocus) => {
          const addPhysician = { ...field!.state };
          const value =
            addPhysician.value ===
            PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER
              ? PHYSICIAN_INFORMATION_CONSTANTS.REMOVE_MEDICAL_PROVIDER
              : PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER;
          const hidden =
            addPhysician.value ===
            PHYSICIAN_INFORMATION_CONSTANTS.ADD_MEDICAL_PROVIDER
              ? false
              : true;

          const physicianInformation_1 =
            formState[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1];

          updateForm!({
            [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]: {
              ...physicianInformation_1,
              hidden,
            },
            [field!.config.name]: { ...addPhysician, value },
          });

          const focusOn = hidden
            ? PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN
            : "physicianInformation_1_firstName";
          setFocus(focusOn);
        },
        placeholderText: "",
      } as FormActionButton,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
