export const DIFFERENT_COMBINED_VALUE_PRIMARY_ERROR =
  "The combined values of Injured Body Part and Nature of Injury cannot be the same as Secondary Injured Body Part and Secondary Nature of Injury";

export const DIFFERENT_COMBINED_VALUE_SECONDARY_ERROR =
  "The combined values of Secondary Injured Body Part and Secondary Nature of Injury cannot be the same as Injured Body Part and Nature of Injury";

export const COMBINATION_NOT_SUPPORTED_ERROR =
  "This combination of Body Part and Nature of Injury is not supported, please select different values.";

export const INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS = [
  "6559",
  "1004",
  "1007",
  "1010",
  "1013",
  "1019",
  "1022",
  "1025",
  "1028",
  "1030",
  "1031",
  "1032",
  "1036",
  "1037",
  "1040",
  "1043",
  "1047",
  "1049",
  "1052",
  "1053",
  "1054",
  "1055",
  "1058",
  "1059",
  "1060",
  "1061",
  "1062",
  "1063",
  "1064",
  "1065",
  "1066",
  "1067",
  "1068",
  "1069",
  "1070",
  "1071",
  "1072",
  "1073",
  "1074",
  "1076",
  "1077",
  "1080",
  "1102",
  "1104",
  "1107",
  "1110",
  "1113",
  "1119",
  "1122",
  "1125",
  "1128",
  "1130",
  "1136",
  "1137",
  "1140",
  "1143",
  "1146",
  "1155",
  "1159",
  "1166",
  "1167",
  "1168",
  "1170",
  "1171",
  "1174",
  "1180",
  "1207",
  "1210",
  "1213",
  "1219",
  "1222",
  "1225",
  "1232",
  "1236",
  "1237",
  "1240",
  "1243",
  "1246",
  "1253",
  "1254",
  "1255",
  "1258",
  "1259",
  "1265",
  "1266",
  "1267",
  "1269",
  "1270",
  "1271",
  "1272",
  "1273",
  "1274",
  "1277",
  "1280",
  "1302",
  "1304",
  "1310",
  "1313",
  "1319",
  "1322",
  "1325",
  "1330",
  "1331",
  "1336",
  "1337",
  "1340",
  "1343",
  "1346",
  "1347",
  "1349",
  "1352",
  "1355",
  "1359",
  "1366",
  "1367",
  "1368",
  "1370",
  "1371",
  "1372",
  "1373",
  "1374",
  "1380",
  "1404",
  "1410",
  "1413",
  "1419",
  "1422",
  "1425",
  "1430",
  "1436",
  "1437",
  "1440",
  "1443",
  "1446",
  "1452",
  "1455",
  "1458",
  "1459",
  "1466",
  "1467",
  "1470",
  "1471",
  "1473",
  "1474",
  "1476",
  "1480",
  "1502",
  "1504",
  "1510",
  "1513",
  "1516",
  "1519",
  "1522",
  "1525",
  "1528",
  "1530",
  "1536",
  "1537",
  "1540",
  "1543",
  "1546",
  "1547",
  "1552",
  "1554",
  "1555",
  "1559",
  "1565",
  "1566",
  "1567",
  "1568",
  "1570",
  "1571",
  "1573",
  "1574",
  "1580",
  "1610",
  "1613",
  "1616",
  "1619",
  "1622",
  "1625",
  "1628",
  "1630",
  "1636",
  "1637",
  "1640",
  "1643",
  "1655",
  "1659",
  "1666",
  "1667",
  "1670",
  "1671",
  "1680",
  "1704",
  "1710",
  "1713",
  "1719",
  "1722",
  "1725",
  "1730",
  "1736",
  "1737",
  "1740",
  "1743",
  "1746",
  "1752",
  "1754",
  "1755",
  "1759",
  "1765",
  "1766",
  "1767",
  "1768",
  "1770",
  "1771",
  "1773",
  "1774",
  "1780",
  "1804",
  "1810",
  "1813",
  "1819",
  "1822",
  "1825",
  "1830",
  "1836",
  "1837",
  "1840",
  "1843",
  "1846",
  "1855",
  "1859",
  "1866",
  "1867",
  "1868",
  "1870",
  "1871",
  "1873",
  "1874",
  "1880",
  "1904",
  "1907",
  "1910",
  "1913",
  "1916",
  "1922",
  "1925",
  "1928",
  "1936",
  "1937",
  "1940",
  "1943",
  "1949",
  "1952",
  "1954",
  "1955",
  "1959",
  "1966",
  "1967",
  "1970",
  "1971",
  "1974",
  "1980",
  "2004",
  "2010",
  "2013",
  "2016",
  "2019",
  "2022",
  "2025",
  "2028",
  "2030",
  "2031",
  "2032",
  "2036",
  "2037",
  "2040",
  "2043",
  "2046",
  "2049",
  "2052",
  "2054",
  "2055",
  "2059",
  "2060",
  "2061",
  "2062",
  "2063",
  "2064",
  "2065",
  "2066",
  "2067",
  "2068",
  "2069",
  "2070",
  "2071",
  "2073",
  "2074",
  "2076",
  "2080",
  "2110",
  "2113",
  "2116",
  "2122",
  "2125",
  "2128",
  "2134",
  "2136",
  "2137",
  "2140",
  "2143",
  "2146",
  "2149",
  "2152",
  "2155",
  "2159",
  "2166",
  "2167",
  "2170",
  "2171",
  "2173",
  "2174",
  "2180",
  "2210",
  "2213",
  "2216",
  "2222",
  "2225",
  "2228",
  "2234",
  "2236",
  "2237",
  "2240",
  "2243",
  "2246",
  "2255",
  "2259",
  "2266",
  "2267",
  "2270",
  "2271",
  "2273",
  "2274",
  "2280",
  "2302",
  "2310",
  "2313",
  "2316",
  "2319",
  "2322",
  "2325",
  "2328",
  "2334",
  "2336",
  "2337",
  "2340",
  "2343",
  "2346",
  "2347",
  "2349",
  "2352",
  "2355",
  "2359",
  "2365",
  "2366",
  "2367",
  "2369",
  "2370",
  "2371",
  "2373",
  "2374",
  "2380",
  "2402",
  "2404",
  "2410",
  "2413",
  "2422",
  "2425",
  "2428",
  "2434",
  "2436",
  "2437",
  "2440",
  "2443",
  "2446",
  "2452",
  "2454",
  "2455",
  "2459",
  "2460",
  "2461",
  "2462",
  "2463",
  "2464",
  "2465",
  "2466",
  "2467",
  "2470",
  "2471",
  "2473",
  "2474",
  "2480",
  "2502",
  "2504",
  "2510",
  "2513",
  "2519",
  "2522",
  "2525",
  "2530",
  "2534",
  "2536",
  "2537",
  "2540",
  "2543",
  "2546",
  "2547",
  "2549",
  "2552",
  "2555",
  "2559",
  "2566",
  "2567",
  "2568",
  "2570",
  "2571",
  "2573",
  "2574",
  "2580",
  "2602",
  "2604",
  "2610",
  "2613",
  "2622",
  "2625",
  "2628",
  "2634",
  "2636",
  "2637",
  "2640",
  "2643",
  "2646",
  "2652",
  "2654",
  "2655",
  "2659",
  "2665",
  "2666",
  "2667",
  "2670",
  "2671",
  "2673",
  "2674",
  "2680",
  "3002",
  "3004",
  "3010",
  "3013",
  "3016",
  "3019",
  "3022",
  "3025",
  "3028",
  "3030",
  "3032",
  "3036",
  "3037",
  "3040",
  "3043",
  "3047",
  "3049",
  "3052",
  "3055",
  "3059",
  "3060",
  "3061",
  "3062",
  "3063",
  "3064",
  "3065",
  "3066",
  "3067",
  "3068",
  "3069",
  "3070",
  "3071",
  "3073",
  "3074",
  "3076",
  "3080",
  "3102",
  "3104",
  "3110",
  "3113",
  "3116",
  "3119",
  "3122",
  "3125",
  "3128",
  "3130",
  "3136",
  "3137",
  "3140",
  "3143",
  "3147",
  "3149",
  "3152",
  "3155",
  "3159",
  "3166",
  "3167",
  "3168",
  "3170",
  "3171",
  "3173",
  "3174",
  "3180",
  "3202",
  "3204",
  "3210",
  "3213",
  "3216",
  "3222",
  "3225",
  "3228",
  "3236",
  "3237",
  "3240",
  "3243",
  "3247",
  "3249",
  "3252",
  "3255",
  "3259",
  "3266",
  "3267",
  "3268",
  "3270",
  "3271",
  "3273",
  "3274",
  "3280",
  "3302",
  "3304",
  "3310",
  "3313",
  "3322",
  "3325",
  "3328",
  "3330",
  "3336",
  "3337",
  "3340",
  "3343",
  "3347",
  "3349",
  "3352",
  "3355",
  "3359",
  "3366",
  "3367",
  "3368",
  "3370",
  "3371",
  "3373",
  "3374",
  "3380",
  "3402",
  "3404",
  "3410",
  "3413",
  "3416",
  "3422",
  "3425",
  "3428",
  "3436",
  "3437",
  "3440",
  "3443",
  "3447",
  "3449",
  "3452",
  "3455",
  "3459",
  "3466",
  "3467",
  "3468",
  "3470",
  "3471",
  "3473",
  "3474",
  "3478",
  "3480",
  "3502",
  "3504",
  "3510",
  "3513",
  "3519",
  "3522",
  "3525",
  "3528",
  "3530",
  "3536",
  "3537",
  "3540",
  "3543",
  "3547",
  "3549",
  "3552",
  "3555",
  "3559",
  "3566",
  "3567",
  "3568",
  "3570",
  "3571",
  "3573",
  "3574",
  "3580",
  "3602",
  "3604",
  "3610",
  "3613",
  "3616",
  "3619",
  "3622",
  "3625",
  "3628",
  "3630",
  "3636",
  "3637",
  "3640",
  "3643",
  "3647",
  "3649",
  "3652",
  "3655",
  "3659",
  "3666",
  "3667",
  "3668",
  "3670",
  "3671",
  "3673",
  "3674",
  "3680",
  "3702",
  "3704",
  "3710",
  "3713",
  "3716",
  "3719",
  "3722",
  "3725",
  "3728",
  "3730",
  "3736",
  "3737",
  "3740",
  "3743",
  "3747",
  "3749",
  "3752",
  "3755",
  "3759",
  "3766",
  "3767",
  "3768",
  "3770",
  "3771",
  "3773",
  "3774",
  "3780",
  "3804",
  "3810",
  "3813",
  "3816",
  "3822",
  "3825",
  "3828",
  "3836",
  "3837",
  "3840",
  "3843",
  "3846",
  "3849",
  "3852",
  "3855",
  "3859",
  "3868",
  "3880",
  "3904",
  "3910",
  "3913",
  "3916",
  "3922",
  "3925",
  "3928",
  "3936",
  "3937",
  "3940",
  "3943",
  "3949",
  "3952",
  "3955",
  "3959",
  "3968",
  "3978",
  "3980",
  "4002",
  "4004",
  "4010",
  "4013",
  "4019",
  "4022",
  "4025",
  "4028",
  "4030",
  "4032",
  "4036",
  "4037",
  "4040",
  "4043",
  "4047",
  "4049",
  "4052",
  "4055",
  "4059",
  "4060",
  "4061",
  "4062",
  "4063",
  "4064",
  "4065",
  "4066",
  "4067",
  "4068",
  "4069",
  "4070",
  "4071",
  "4073",
  "4074",
  "4076",
  "4080",
  "4104",
  "4110",
  "4113",
  "4116",
  "4122",
  "4125",
  "4128",
  "4130",
  "4136",
  "4137",
  "4140",
  "4143",
  "4149",
  "4152",
  "4155",
  "4159",
  "4166",
  "4167",
  "4168",
  "4170",
  "4171",
  "4173",
  "4174",
  "4180",
  "4204",
  "4210",
  "4213",
  "4216",
  "4222",
  "4225",
  "4228",
  "4230",
  "4236",
  "4237",
  "4240",
  "4243",
  "4249",
  "4252",
  "4255",
  "4259",
  "4266",
  "4267",
  "4268",
  "4270",
  "4271",
  "4273",
  "4274",
  "4280",
  "4310",
  "4313",
  "4316",
  "4322",
  "4325",
  "4328",
  "4334",
  "4336",
  "4337",
  "4340",
  "4343",
  "4346",
  "4355",
  "4359",
  "4366",
  "4367",
  "4370",
  "4371",
  "4373",
  "4374",
  "4380",
  "4403",
  "4404",
  "4410",
  "4413",
  "4422",
  "4425",
  "4428",
  "4430",
  "4434",
  "4436",
  "4437",
  "4440",
  "4443",
  "4446",
  "4449",
  "4452",
  "4454",
  "4455",
  "4459",
  "4460",
  "4461",
  "4462",
  "4463",
  "4464",
  "4465",
  "4466",
  "4467",
  "4468",
  "4470",
  "4471",
  "4473",
  "4474",
  "4480",
  "4510",
  "4513",
  "4516",
  "4522",
  "4525",
  "4528",
  "4536",
  "4537",
  "4540",
  "4543",
  "4546",
  "4547",
  "4549",
  "4552",
  "4555",
  "4559",
  "4566",
  "4567",
  "4570",
  "4571",
  "4573",
  "4574",
  "4580",
  "4604",
  "4610",
  "4613",
  "4616",
  "4622",
  "4625",
  "4628",
  "4634",
  "4636",
  "4637",
  "4640",
  "4643",
  "4646",
  "4649",
  "4652",
  "4655",
  "4659",
  "4666",
  "4667",
  "4670",
  "4671",
  "4673",
  "4674",
  "4680",
  "4702",
  "4710",
  "4713",
  "4716",
  "4719",
  "4722",
  "4725",
  "4728",
  "4734",
  "4736",
  "4737",
  "4740",
  "4743",
  "4746",
  "4747",
  "4749",
  "4752",
  "4755",
  "4759",
  "4765",
  "4766",
  "4767",
  "4770",
  "4771",
  "4773",
  "4774",
  "4780",
  "4802",
  "4810",
  "4813",
  "4816",
  "4822",
  "4825",
  "4834",
  "4836",
  "4837",
  "4840",
  "4842",
  "4843",
  "4846",
  "4855",
  "4859",
  "4860",
  "4862",
  "4865",
  "4866",
  "4867",
  "4870",
  "4871",
  "4873",
  "4874",
  "4880",
  "4890",
  "4903",
  "4919",
  "4922",
  "4925",
  "4936",
  "4937",
  "4940",
  "4941",
  "4943",
  "4946",
  "4952",
  "4953",
  "4955",
  "4959",
  "4966",
  "4967",
  "4970",
  "4971",
  "4973",
  "4974",
  "4980",
  "5002",
  "5004",
  "5010",
  "5013",
  "5016",
  "5019",
  "5022",
  "5025",
  "5028",
  "5030",
  "5032",
  "5036",
  "5037",
  "5040",
  "5043",
  "5047",
  "5049",
  "5052",
  "5055",
  "5059",
  "5060",
  "5061",
  "5062",
  "5063",
  "5064",
  "5065",
  "5066",
  "5067",
  "5068",
  "5069",
  "5070",
  "5071",
  "5073",
  "5074",
  "5076",
  "5080",
  "5102",
  "5104",
  "5110",
  "5113",
  "5116",
  "5122",
  "5125",
  "5128",
  "5136",
  "5137",
  "5140",
  "5143",
  "5149",
  "5152",
  "5155",
  "5159",
  "5166",
  "5167",
  "5168",
  "5170",
  "5171",
  "5173",
  "5174",
  "5180",
  "5202",
  "5204",
  "5210",
  "5213",
  "5222",
  "5225",
  "5228",
  "5236",
  "5237",
  "5240",
  "5243",
  "5249",
  "5252",
  "5255",
  "5259",
  "5266",
  "5267",
  "5268",
  "5270",
  "5271",
  "5273",
  "5274",
  "5280",
  "5302",
  "5304",
  "5310",
  "5313",
  "5316",
  "5322",
  "5325",
  "5328",
  "5336",
  "5337",
  "5340",
  "5343",
  "5347",
  "5349",
  "5352",
  "5355",
  "5359",
  "5366",
  "5367",
  "5368",
  "5370",
  "5371",
  "5373",
  "5374",
  "5380",
  "5402",
  "5404",
  "5410",
  "5413",
  "5419",
  "5422",
  "5425",
  "5428",
  "5430",
  "5436",
  "5437",
  "5440",
  "5443",
  "5447",
  "5449",
  "5452",
  "5455",
  "5459",
  "5466",
  "5467",
  "5468",
  "5470",
  "5471",
  "5473",
  "5474",
  "5480",
  "5502",
  "5504",
  "5510",
  "5513",
  "5516",
  "5522",
  "5525",
  "5528",
  "5536",
  "5537",
  "5540",
  "5543",
  "5547",
  "5549",
  "5552",
  "5555",
  "5559",
  "5566",
  "5567",
  "5568",
  "5570",
  "5571",
  "5573",
  "5574",
  "5580",
  "5602",
  "5604",
  "5610",
  "5613",
  "5619",
  "5622",
  "5625",
  "5628",
  "5630",
  "5636",
  "5637",
  "5640",
  "5643",
  "5647",
  "5649",
  "5652",
  "5655",
  "5659",
  "5666",
  "5667",
  "5668",
  "5670",
  "5671",
  "5673",
  "5674",
  "5680",
  "5702",
  "5704",
  "5710",
  "5713",
  "5716",
  "5719",
  "5722",
  "5725",
  "5728",
  "5730",
  "5736",
  "5737",
  "5740",
  "5743",
  "5747",
  "5749",
  "5752",
  "5755",
  "5759",
  "5766",
  "5767",
  "5768",
  "5770",
  "5771",
  "5773",
  "5774",
  "5780",
  "5802",
  "5804",
  "5810",
  "5813",
  "5816",
  "5822",
  "5825",
  "5828",
  "5830",
  "5836",
  "5837",
  "5840",
  "5843",
  "5847",
  "5849",
  "5852",
  "5859",
  "5868",
  "6022",
  "6036",
  "6037",
  "6042",
  "6043",
  "6053",
  "6054",
  "6059",
  "6060",
  "6061",
  "6062",
  "6063",
  "6064",
  "6065",
  "6066",
  "6067",
  "6070",
  "6071",
  "6074",
  "6083",
  "6104",
  "6110",
  "6113",
  "6122",
  "6125",
  "6134",
  "6136",
  "6137",
  "6140",
  "6143",
  "6146",
  "6152",
  "6159",
  "6168",
  "6204",
  "6210",
  "6222",
  "6225",
  "6236",
  "6237",
  "6240",
  "6243",
  "6249",
  "6252",
  "6259",
  "6268",
  "6310",
  "6313",
  "6316",
  "6328",
  "6359",
  "6401",
  "6653",
  "6677",
  "9002",
  "9004",
  "9010",
  "9013",
  "9019",
  "9022",
  "9025",
  "9028",
  "9030",
  "9032",
  "9036",
  "9037",
  "9040",
  "9042",
  "9043",
  "9047",
  "9049",
  "9052",
  "9053",
  "9055",
  "9059",
  "9060",
  "9061",
  "9062",
  "9063",
  "9064",
  "9065",
  "9066",
  "9067",
  "9068",
  "9069",
  "9070",
  "9071",
  "9073",
  "9074",
  "9075",
  "9076",
  "9080",
  "9090",
  "9091",
  "9119",
  "9138",
  "9142",
  "9153",
  "9155",
  "9159",
  "9166",
  "9167",
  "9170",
  "9171",
  "9173",
  "9174",
  "9175",
  "9183",
  "9190",
  "9191",
];
