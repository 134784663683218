import { Role, User, CognitoUserInfo } from "./types/interfaces";
import { store } from "src/redux/store";
import { setUser } from "./authSlice";
import { MetricsPublisher } from "../metrics/metrics";
import { CognitoAuth } from "amazon-cognito-auth-js";

/**
 * Takes an authentication object (auth) as input and returns a User object with the user's information
 * @param {CognitoAuth} auth - CognitoAuth object received after login is successful
 * @returns User object
 */

export const parseAuthInformation = (auth: CognitoAuth) => {
  // Get the user's sign-in session from the Cognito authentication object
  // and decode the payload of the ID token
  const userInfo: CognitoUserInfo = auth
    .getSignInUserSession()
    .getIdToken()
    .decodePayload() as CognitoUserInfo;

  let roles: Role[] = [];
  if (userInfo.profile) {
    // Parse the 'profile' property as an array of Role objects
    // or assign an empty array if parsing fails
    roles = (JSON.parse(userInfo.profile) as Role[]) || [];
  }

  // Regular expression to extract the alias from a string in the format '(alias)'
  const isolateAliasRegExp = /\(([^)]+)\)/;

  const managerAlias =
    isolateAliasRegExp.exec(userInfo["custom:manager"])?.[1] || "";
  const id =
    userInfo["custom:alias"] || userInfo["cognito:username"].split("_")[1];
  const siteCode = userInfo["custom:location"]
    ? userInfo["custom:location"].split(/[\s-]+/g)[0]
    : "";

  // Build user object to return
  const user: User = {
    id,
    givenName: userInfo.given_name,
    familyName: userInfo.family_name,
    email: userInfo.email,
    roles,
    siteCode,
    managerAlias,
    jobDescription: userInfo["custom:jobDescription"] || "",
  };
  return user;
};

/**
 * Sets the user information in the redux store and publishes the user's name for metrics.
 *
 * @param {User} user - The user object containing the user's information.
 */
export const setUserInformation = (user: User) => {
  const metricsPublisher = new MetricsPublisher("userName");
  store.dispatch(setUser({ user }));
  metricsPublisher.publishUserName(user.id);
};
