import {
  BUTTON_VARIANTS,
  FormActionButton,
  FormFieldConfigType,
  INPUT_TYPES,
  TEXT_INPUT_TYPES,
  TextInputFormField,
} from "../../types";
import React from "react";
import { ASSOCIATE_INFORMATION_CONSTANTS } from "../../constants";
import { preProcessFormFields } from "../../tools";
export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: "allAssociateInformationFields",
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    label: "Associate Information",
    fields: [
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID,
        label: "Associate Employee ID",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS,
        label: "Associate Alias/Login",
        description: (
          <span>
            <span key="1">Please enter the alias/login of the associate </span>
            <b key="2">without </b>
            <span key="3">&quot;@amazon.com&quot;</span>
          </span>
        ),
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        label: "",
        hasPopover: false,
        popoverText: "",
        name: "submitAssociate",
        type: INPUT_TYPES.BUTTON,
        variant: BUTTON_VARIANTS.NORMAL,
        placeholderText: "",
      } as FormActionButton,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME,
        label: "Associate First Name",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME,
        label: "Associate Last Name",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME,
        label: "Associate Home Site",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE,
        label: "Worker Sub Type",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS,
        label: "Supervisor Alias",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
      } as TextInputFormField,
      {
        name: ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME,
        label: "Supervisor Name",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
      } as TextInputFormField,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
