import React, { useRef } from "react";
import { useState, useEffect } from "react";

import {
  SpaceBetween,
  Header,
  ContentLayout,
  Container,
  Box,
  Icon,
  FormField,
  Select,
  SelectProps,
  Button,
  Grid,
  Link,
  Alert,
  Flashbar,
  FlashbarProps,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import type { RootState } from "../../redux/store";
import {
  MedicalPanelFile,
  getMedicalPanelFile,
  listMedicalPanelDataFiles,
  setValueInMedicalPanelDataState,
} from "./medicalPanelDataSlice";
import moment from "moment";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { MetricsPublisher } from "src/metrics";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";
interface FileOption {
  value: string;
  label: string;
  lastModifiedTimestamp: number;
}
enum FILE_ORIGIN {
  MATCHING_SITE = "matchingSite",
  SELECTED_SITE = "selectedSite",
}
//Declare metricsPublishers, they will be initialized on component mount
let metricsPublisher: MetricsPublisher;
let metricsPublisherVisitedBySite: MetricsPublisher;
let metricsPublisherNoMatchFound: MetricsPublisher;
let metricsPublisherDownloadsBySite: MetricsPublisher;
let metricsPublisherNonMatchingDownload: MetricsPublisher;
const MedicalPanelData = () => {
  /**
   * Setup Redux variables and get state
   */

  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);

  //Redux MedicalPanelData state
  const {
    files,
    fileUrl,
    gettingFiles,
    gettingFileUrl,
    listFilesError,
    getFileUrlError,
  } = useAppSelector((state: RootState) => state.medicalPanelData);

  const dispatch = useAppDispatch();
  const [fileOptions, setFileOptions] = useState<FileOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FileOption | null>(null);
  const [matchingFile, setMatchingFile] = useState<MedicalPanelFile | null>(
    null
  );
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const [fileUrlOrigin, setFileUrlOrigin] = useState<FILE_ORIGIN | null>(null);

  const userLoaded = useRef(false);
  /**
   * Get file list on load
   */
  useEffect(() => {
    metricsPublisher = new MetricsPublisher("medicalPanelData");
    metricsPublisherVisitedBySite = new MetricsPublisher(
      "medicalPanelDataVisitedBySite"
    );
    metricsPublisherNoMatchFound = new MetricsPublisher(
      "medicalPanelDataNoMatchFound"
    );
    metricsPublisherDownloadsBySite = new MetricsPublisher(
      "medicalPanelDataDownloadsBySite"
    );
    metricsPublisherNonMatchingDownload = new MetricsPublisher(
      "medicalPanelDataNonMatchingDownload"
    );
    dispatch(listMedicalPanelDataFiles({}));

    metricsPublisher.publishMedicalPanelDataVisited();
  }, []);
  /**
   * Publish metric medicalPanelDataVisitedBySite when user is loaded
   */
  useEffect(() => {
    if (user?.siteCode && !userLoaded.current) {
      userLoaded.current = true;
      metricsPublisherVisitedBySite.publishMedicalPanelDataVisitedBySite(
        user.siteCode
      );
    }
  }, [user.siteCode]);
  /**
   * Search files for matching site
   */
  useEffect(() => {
    const filtered = user?.siteCode
      ? files.filter((file: MedicalPanelFile) => {
          // Splitting fileName to isolate site code and avoid getting multiple matches
          const splitFileName = file.fileName.split(/[-._\s/:,#]+/);
          return splitFileName.includes(user.siteCode);
        })
      : [];

    const options = files.reduce(
      (result: FileOption[], file: MedicalPanelFile) => {
        const tokenizedFileName = file?.fileName.split("/");
        const standaloneFileName =
          tokenizedFileName[tokenizedFileName.length - 1];
        if (!standaloneFileName) return result;
        result.push({
          value: file.fileName,
          label: standaloneFileName,
          lastModifiedTimestamp: file.lastModifiedTimestamp,
        });
        return result;
      },
      []
    );
    /**
     * Sort options alphabetically based on label.
     * File names sorting is being handled in the Front-end and not in the Back-end
     * because file names are already being processed here and it will keep the
     * data processing step consistent.
     */
    options.sort((a: FileOption, b: FileOption) => {
      return a.label.localeCompare(b.label);
    });
    setFileOptions(options);
    if (filtered?.length) {
      metricsPublisher.publishMedicalPanelDataMatchSuccess();
      setMatchingFile(filtered[0]);
    } else if (user?.siteCode) {
      metricsPublisherNoMatchFound.publishMedicalPanelDataMatchNotFound(
        user.siteCode
      );
    }
    metricsPublisher.publishLoadMedicalPanelDataPageLatency();
  }, [files]);

  /**
   * Trigger file download when url is received
   */
  useEffect(() => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
      dispatch(setValueInMedicalPanelDataState({ key: "fileUrl", value: "" }));
      metricsPublisher.publishMedicalPanelDataDownloads();

      if (user?.siteCode) {
        metricsPublisherDownloadsBySite.publishMedicalPanelDataDownloadsBySite(
          user.siteCode
        );
        // Check if downloaded file does not match user's site and publish metric: ${siteName}-${fileName}: count
        if (
          fileUrlOrigin === FILE_ORIGIN.SELECTED_SITE &&
          !selectedOption?.label.includes(user.siteCode) &&
          matchingFile
        ) {
          const fileNameWithNoExtension =
            selectedOption?.label?.split(".")[0]?.replace(/\s+/g, "_") || "";
          const siteNameAndFileName = `${user.siteCode}-${fileNameWithNoExtension}`;
          metricsPublisherNonMatchingDownload.publishMedicalPanelDataNonMatchingDownload(
            siteNameAndFileName
          );
        }
      }
    }
  }, [fileUrl]);

  /**
   * Set alert if there was an error getting the fileUrl
   */
  useEffect(() => {
    if (getFileUrlError) {
      setAlert(
        <Box data-testid="getFileUrlErrorAlert">
          There was a problem retrieving the download link for this file, please
          try again later, reload the page or search the documents below for
          another file, if the problem persists please open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to assist you with this issue.
        </Box>
      );
    } else {
      setAlert(null);
    }
  }, [getFileUrlError]);

  const getFileUrl = (fileName?: string) => {
    if (!fileName) return;
    setAlert(null);
    dispatch(getMedicalPanelFile({ fileName }));
  };
  const renderMatchFoundMessage = () => {
    let result = (
      <Box
        variant="p"
        color="text-status-warning"
        data-testid="noMatchingFileWarning"
      >
        <Icon name="status-warning"></Icon> There was no medical provider
        information found for your site, please submit a{" "}
        <Link
          href="https://t.corp.amazon.com/create/templates/86a35e0b-0b3c-4860-822f-5ec0a3f5a316"
          target="_blank"
          variant="primary"
          ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket to help you with this issue."
        >
          ticket
        </Link>
        . If you want to search medical provider information for another site,
        please use the dropdown menu below.
      </Box>
    );
    if (matchingFile) {
      const tokenizedFileName = matchingFile.fileName.split("/");
      const standaloneFileName =
        tokenizedFileName[tokenizedFileName.length - 1];
      result = (
        <SpaceBetween size="xs" direction="vertical">
          <Box
            variant="p"
            color="text-status-success"
            data-testid="matchingFileSuccess"
          >
            <Icon name="status-positive"></Icon> Click on the download link
            below to get the medical provider information for your site or
            search for another site&apos;s medical provider information in the
            dropdown menu.
          </Box>
          <Box variant="p">
            <b>File: </b>
            <span
              data-testid="matchingFileDownloadLink"
              onClick={() => {
                setFileUrlOrigin(FILE_ORIGIN.MATCHING_SITE);
                getFileUrl(matchingFile.fileName);
              }}
            >
              <Link variant="primary">
                {`${standaloneFileName} `} <Icon name="download"></Icon>
              </Link>
            </span>
            {gettingFileUrl && fileUrlOrigin === FILE_ORIGIN.MATCHING_SITE ? (
              <Spinner />
            ) : null}
            <br />
            <span>
              <b>Last Modified: </b>
              {`${moment(matchingFile.lastModifiedTimestamp).format("LLLL")}`}
            </span>
          </Box>
        </SpaceBetween>
      );
    }
    return result;
  };

  const renderErrorListingFiles = () => {
    return (
      <SpaceBetween
        direction="vertical"
        size="xs"
        data-testid="listingFilesErrorAlertContainer"
      >
        <Alert type="error" data-testid="listingFilesErrorAlert">
          There was an error retrieving the files. Please reload the page or
          click on the button below to try again. If the error persists please
          open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to get help with this issue.
        </Alert>
        <Button
          variant="normal"
          onClick={() => {
            dispatch(listMedicalPanelDataFiles({}));
          }}
        >
          Reload File List
        </Button>
      </SpaceBetween>
    );
  };

  const renderErrorAlert = () => {
    if (alert) {
      return (
        <Alert type="error" data-testid="medicalPanelDataAlert">
          {alert}
        </Alert>
      );
    }
  };
  const flashbarItems: FlashbarProps.MessageDefinition[] = [
    {
      type: "info",
      dismissible: false,
      dismissLabel: "Dismiss message",
      content: (
        <Box>
          Note:
          <br />
          <SpaceBetween direction="vertical" size="xs">
            <span>
              Medical provider information is available for most sites and
              jurisdictions to assist associates in seeking medical care, even
              if not required.
            </span>
            <span>
              The following states are{" "}
              <span className="text-underline">required</span> by state law to
              display medical panel in the common areas within the building: CO,
              GA, PA, TN, TX, VA.
            </span>
          </SpaceBetween>
        </Box>
      ),
      id: "message_1",
    },
  ];

  return (
    <ContentLayout
      header={
        <Header variant="h2">
          Download Preferred Provider List/Medical Provider Panel
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="s">
        <Flashbar items={flashbarItems} />
        <Container>
          {listFilesError ? (
            renderErrorListingFiles()
          ) : gettingFiles ? (
            <SpaceBetween direction="horizontal" size="xs">
              <Box
                variant="awsui-key-label"
                data-testid="retrievingFilesMessage"
              >
                Retrieving file list, please wait...
              </Box>
              <Spinner></Spinner>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="s" direction="vertical">
              {renderErrorAlert()}
              {renderMatchFoundMessage()}

              <FormField
                description="Search from the dropdown menu using keywords related to the site for which you want to download the medical provider data file from"
                label="Amazon sites medical provider search"
                data-testid="medicalPanelFilesDropdownFormField"
              >
                <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                  <Select
                    options={fileOptions}
                    selectedOption={selectedOption}
                    onChange={({
                      detail,
                    }: {
                      detail: SelectProps.ChangeDetail;
                    }) => {
                      setSelectedOption(detail.selectedOption as FileOption);
                    }}
                    filteringType="auto"
                    placeholder="Select a file to download"
                    virtualScroll={true}
                  ></Select>
                  <Button
                    disabled={!selectedOption || gettingFileUrl}
                    variant="primary"
                    onClick={() => {
                      setFileUrlOrigin(FILE_ORIGIN.SELECTED_SITE);
                      getFileUrl(selectedOption?.value);
                    }}
                    loading={
                      gettingFileUrl &&
                      fileUrlOrigin === FILE_ORIGIN.SELECTED_SITE
                    }
                  >
                    Download
                  </Button>
                </Grid>
                {selectedOption ? (
                  <div>
                    <span>
                      <b>Last Modified: </b>
                      {`${moment(selectedOption.lastModifiedTimestamp).format(
                        "LLLL"
                      )}`}
                      {}
                    </span>
                  </div>
                ) : null}
              </FormField>
              <Alert type="info">
                If you are unable to find the medical provider information for
                the site you were searching for or have any questions related to
                medical provider information, please submit a{" "}
                <Link
                  href="https://t.corp.amazon.com/create/templates/86a35e0b-0b3c-4860-822f-5ec0a3f5a316"
                  target="_blank"
                  variant="primary"
                  ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket to help you with this issue."
                >
                  ticket
                </Link>
                .
              </Alert>
            </SpaceBetween>
          )}
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};
export default MedicalPanelData;
