import * as React from "react";
import {
  Modal,
  Box,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react/polaris";
import { DUPLICATE_MODAL_TITLE } from "../../app/constants";
import { MetricsPublisher } from "../../metrics";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "../../redux/store";

interface setModalProps {
  setModalOpen: any;
  onResetForm: any;
  isModalOpen: boolean;
  modalTitle: string;
  modalDescription: React.ReactNode;
  cancelButtonText: string;
  confirmButtonText: string;
}

const ResetFormModal = ({
  setModalOpen,
  isModalOpen,
  modalTitle,
  onResetForm,
  modalDescription,
  cancelButtonText,
  confirmButtonText,
}: setModalProps) => {
  const { user } = useAppSelector((state: RootState) => state.auth);
  const resetForm = () => {
    if (modalTitle === DUPLICATE_MODAL_TITLE) {
      const metricsPublisher = new MetricsPublisher("duplicateClaimReset");
      metricsPublisher.publishDuplicateClaimWarningResultsMetrics(user.id);
    }
    onResetForm();
    setModalOpen(false);
  };
  const cancelModal = () => {
    if (modalTitle === DUPLICATE_MODAL_TITLE) {
      const metricsPublisher = new MetricsPublisher("duplicateClaimIgnored");
      metricsPublisher.publishDuplicateClaimWarningResultsMetrics(user.id);
    }
    setModalOpen(false);
  };

  return (
    <Modal
      onDismiss={() => setModalOpen(false)}
      visible={isModalOpen}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => cancelModal()}
              data-testid="modal-cancel-button"
            >
              {cancelButtonText}
            </Button>
            <Button
              variant="primary"
              onClick={resetForm}
              data-testid="modal-confirmation-button"
            >
              {confirmButtonText}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={modalTitle}
    >
      {modalDescription}
    </Modal>
  );
};
export default ResetFormModal;
