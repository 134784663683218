import React from "react";
import { ParsedClaimSummary } from "./constants";
import { generateBodyPartDescription } from "../table/tableUtils";
import { CSVLink } from "react-csv";
import { Button } from "@amzn/awsui-components-react";
import moment from "moment-timezone";

interface ExportToCSVButtonProps {
  data: any[];
  headers: any[];
  fileNamePrefix: string;
}
function ExportToCSVButton({
  data,
  headers,
  fileNamePrefix,
}: ExportToCSVButtonProps) {
  const filteredClaims = data.map(
    //eslint-disable-next-line
    ({ incidentTimestamp, createdAtTimestamp, ...rest }) => {
      const bodyPart = generateBodyPartDescription({
        ...rest,
      } as ParsedClaimSummary);
      return { bodyPart, ...rest };
    }
  );
  const timestamp = moment().toISOString();
  const csvFileName = `${fileNamePrefix}_${timestamp}.csv`;
  return data?.length ? (
    <CSVLink data={filteredClaims} headers={headers} filename={csvFileName}>
      <Button variant="normal">Export to CSV</Button>
    </CSVLink>
  ) : null;
}

export default ExportToCSVButton;
