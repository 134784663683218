import { formFieldsConfigProcessed } from "./config/FileAttachmentsConfig";
import { FILE_ATTACHMENTS_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/FileAttachmentsState";
import { formFieldsValidation } from "./validations/FileAttachmentsValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "File Attachments";
export const formDescription =
  "Include any attachments that are relevant to the claim.";
export const formName = FILE_ATTACHMENTS_CONSTANTS.FORM_NAME;
