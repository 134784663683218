import queryString from "query-string";
import { User } from "src/auth/types/interfaces";
import { SIM_TAGS, SIM_TYPES } from "./types";

const TREASURY_TECHNOLOGY_CTI = {
  category: "Treasury Technology",
  type: "ClaimIntake",
  item: "General",
};
const RISK_MANAGEMENT_CT = {
  category: "Risk Management",
  type: "Workers Compensation",
};
const TREASURY_TECHNOLOGY_PRODUCT_CTI = {
  category: "Treasury Technology",
  type: "ClaimIntake",
  item: "General",
  group: "NucleusProductGroup",
};

const SIM_TICKETS_URL = "https://t.corp.amazon.com/create/options";
/**
 * Method to return a UTL for SIM TT creation with prefilled options like CTI, etc in form or URL params.
 * See here for full list of params that can used in SIM TT creation URL
 * https://code.amazon.com/packages/SimGraphQL/blobs/b0fc2da9a6f8c488f4bd3be4f0529afc72c8eda8/--/src/services/wfa/create-from-url-params.ts#L4-L19
 * @param claimModel ClaimForm Model to extract needed info.
 *             This can be a different type based on form calling this function. Please use proper checks in usage.
 * @param simType Enum vlaue for known SIM links used in the app.
 * @param claimNumber (Optional) Claim number returned by Service. Needed for ussues related to claim filing.
 * @returns SIM TT creation URL with options populated.
 */
/* eslint-disable complexity */
export const generateSimLinkFromClaim = (
  claimModel: any,
  simType: SIM_TYPES,
  claimNumber?: string,
  user?: User
) => {
  const managerAlias = user?.managerAlias;
  let query: any = {};
  const associateEmployeeIdString = `**Associate's Employee ID:** ${
    claimModel?.associateEmployeeId || ""
  }  \n`;
  const associateNameString = `**Associate's Name:** ${
    claimModel?.associateFirstName || ""
  } ${claimModel?.associateLastName || ""}  \n`;
  const incidentSiteString = `**Site:** ${
    claimModel?.incidentSiteName || ""
  }  \n`;
  const claimNumberString = `**Claim Number (If available):** ${
    claimNumber || ""
  }  \n`;
  const associateEmployeeLoginString = `**Associate's Employee login:** ${
    claimModel?.associateAlias || ""
  }  \n`;
  const incidentDateString = `**Date of Injury:** ${
    claimModel?.incidentDate || ""
  }  \n`;
  const amazonClaimReferenceIdString = `**Amazon Claim Reference ID:** ${
    claimModel?.amazonClaimReferenceId || ""
  }  \n`;

  let emailListResult = "";
  if (claimModel?.notificationAliasList) {
    emailListResult = claimModel?.notificationAliasList.reduce(
      (acc: string, curr: string, i: number) => {
        acc += `${i + 1}:  ${curr}\n\t`;
        return acc;
      },
      ""
    );
  }
  const emailAliasListString = `Current Email Alias List: \n \t${
    emailListResult || ""
  }  \n`;
  switch (simType) {
    case SIM_TYPES.CLAIM_SUBMITTED:
      query = {
        description:
          `${associateNameString}${associateEmployeeIdString}${associateEmployeeLoginString}${incidentSiteString}${incidentDateString}${claimNumberString}${amazonClaimReferenceIdString}` +
          "  \n" +
          `**Has NOD been contacted?** *Yes/No*  \n` +
          "  \n" +
          `**Yes - What date was NOD contacted?**  \n` +
          "  \n" +
          `**Are witness statements or video of incident available?** *Yes/No*  \n` +
          "  \n" +
          `**Yes - Please upload all available to information tab.**  \n` +
          "  \n" +
          `**Provide Claim investigation details:**  \n`,
        title: `Internal Stakeholder Request –Claim Investigation Detail (${
          claimModel?.associateFirstName || "Enter Associate's First Name"
        }  ${
          claimModel?.associateLastName || "Enter Associate's Last Name"
        }) - (${claimModel?.incidentSiteName || "Enter Site Name"} )`,
        severity: "FIVE",
        ...RISK_MANAGEMENT_CT,
        item: "Internal Stakeholder -Claim Investigation Detail",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.REQUEST}`,
      };
      break;
    case SIM_TYPES.FILE_ATTACHMENT_ISSUE:
      query = {
        description:
          `${associateNameString}${associateEmployeeIdString}${associateEmployeeLoginString}${incidentSiteString}${claimNumberString}${amazonClaimReferenceIdString}` +
          "  \n" +
          `**Most claim initiation exceptions can be corrected following the steps in the self service claims exceptions page.**  \n` +
          `*Please utilize this link to find most common exceptions and resolutions.*  \n` +
          `https://w.amazon.com/bin/view/Main/Risk_Management/Workers_Compensation/ClaimInitiation  \n` +
          "  \n" +
          `**Is this regarding a claim initiation referral with exception?** *Yes/No*  \n` +
          "  \n" +
          `**Yes - Please attach exception and documents.**  \n` +
          "  \n" +
          `**Exception Details:**  \n`,
        title: `Internal Stakeholder Request– Claim Initiation Exceptions (${
          claimModel?.incidentSiteName || "Enter Site Name"
        })`,
        severity: "FIVE",
        ...RISK_MANAGEMENT_CT,
        item: "Internal Stakeholder Request- Claim Initiation Exceptions",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.REQUEST}`,
      };
      break;
    case SIM_TYPES.UNKNOWN_ERROR:
      query = {
        description:
          `${associateEmployeeIdString}${associateEmployeeLoginString}${amazonClaimReferenceIdString}${incidentSiteString}${incidentDateString}` +
          "  \n" +
          `**Please provide the time range when you tried to submit the claim**  \n` +
          `*Please provide the time range. This will help us to look into the service logs*  \n` +
          "  \n" +
          `**Please provide any additional details**  \n`,
        title: "Unexpected error when submitting the claim",
        severity: "THREE",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.ISSUE}`,
        ...TREASURY_TECHNOLOGY_CTI,
      };
      break;
    case SIM_TYPES.REPORT_A_BUG:
      query = {
        description:
          `**What is the issue that you have encountered?**  \n` +
          `*Enter bug summary here.*  \n` +
          "  \n" +
          `**What is the impact?**  \n` +
          `*Add the impact here.*  \n` +
          "  \n" +
          `**How can it be reproduced?**  \n` +
          `*Add steps to reproduce the bug.*  \n` +
          "  \n" +
          `**Please add screenshots of the bug found in the attachments section**  \n`,
        title: "[Bug]: <Insert short description here>",
        severity: "FIVE",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.ISSUE}`,
        ...TREASURY_TECHNOLOGY_CTI,
      };
      break;
    case SIM_TYPES.SUGGEST_A_FEATURE:
      query = {
        description:
          `**Describe your feature request:**  \n` +
          `*Add summary for new feature request.*  \n` +
          "  \n" +
          `**What is the benefit/impact?**  \n` +
          `*Please provide details as to how you see it will benefit the users and quantify the impact that you see this feature can have.*  \n` +
          "  \n" +
          `**When is it needed by?**  \n` +
          `*By what time you would expect it be be release in prod. Please provide a business justification.*  \n`,
        title: "[Feature Request]: <Insert short description here>",
        severity: "FIVE",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.REQUEST}`,
        ...TREASURY_TECHNOLOGY_PRODUCT_CTI,
      };
      break;
    case SIM_TYPES.TIMEOUT_ERROR:
      query = {
        description:
          `${associateEmployeeIdString}${associateEmployeeLoginString}${amazonClaimReferenceIdString}` +
          "  \n" +
          `**Please provide the time range when you tried to submit the claim**  \n` +
          `*Please provide the time range. This will help us to look into the service logs*  \n` +
          "  \n" +
          `**Please provide any additional details**  \n`,
        title: "Timeout error when submitting the claim",
        severity: "THREE",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.ISSUE}`,
        ...TREASURY_TECHNOLOGY_CTI,
      };
      break;
    case SIM_TYPES.REPORT_MISSING_SITE:
      query = {
        description:
          `**Note: Manager Approval is required. Please have your manager comment "Approved" in the SIM.** \n` +
          "  \n" +
          `**Please provide the details of the site that you are requesting to add to the “Home Site” and “Incident Site” dropdown menu.**  \n` +
          "  \n" +
          `1. **Is the site part of Air, GCF or Sort business lines?**  \n` +
          ` * If Yes, Proceed to step 2.  \n` +
          "  \n" +
          `* No. Nucleus is not the appropriate process for claim reporting for your business line.  \n` +
          `Please use existing process for your business line. If you are unsure of the correct process, navigate to [WC Wiki Claim Reporting](https://w.amazon.com/bin/view/Main/Risk_Management/US_Workers_Compensation/ReportingClaim/) for guidance.  \n` +
          "  \n" +
          `2. **Please provide the Site name (Ex: AUS2, ABQ1):**  \n` +
          `*Enter Site name here*  \n` +
          "  \n" +
          `3. **Please provide the Site address**  \n` +
          `*Enter Site address here*  \n` +
          "  \n" +
          `4. **Is this a new site that will be launched or existing site?**   \n` +
          `a. [ ] This is a new site.  \n` +
          `b. [ ] This is an existing site that is missing from the current list  \n` +
          `5. **Additional Email list such as las2-amcare@amazon.com, las2-safety@amazon.com. DO NOT use workers' compensation owned email alias such as workcomp-las2@amazon.com**  \n` +
          `*Enter Email list here*  \n` +
          `6. **Add a Slack or Chime Webhook if you are interested to receive the Slack or Chime notifications. Follow the instructions on the [wiki](https://w.amazon.com/bin/view/Treasury/TreasuryTech/ClaimIntake/ClientDocumentation/GettingStarted/SetupClaimNotificationChannels/) to generate a webhook**  \n` +
          `*Enter Slack/Chime Webhook here*  \n` +
          `7. Please get approval from your **manager** on this ticket as a comment.  \n`,
        title:
          "[Missing Site]: Add <Site Name> to Home Site and Incident Site dropdown",
        severity: "THREE",
        ticket_type: "Secure Ticket",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.REQUEST}`,
        ...TREASURY_TECHNOLOGY_PRODUCT_CTI,
      };
      break;
    case SIM_TYPES.REQUEST_SITE_MODIFICATION:
      query = {
        description:
          `**TIP:** *Please enable markdown for better formatting* \n` +
          "\n\n----------- \n" +
          `**Note:** *Manager Approval is required. Please have your manager comment "Approved" in the SIM.* \n` +
          `*Please use this template for updates to notification settings of an existing site only*  \n` +
          "----------- \n" +
          `1. **Please provide the details of the site that you are requesting modifications for**  \n` +
          "  \n" +
          `   * **Site Airport Code:**  ${claimModel?.code} \n` +
          `   * **Current Site Name:**  ${claimModel?.name} \n` +
          "  \n" +
          `   1. **Is there any issue with Site Name?:**   [*Yes/No*]  \n` +
          `      1. **If Yes, Please provide new name:** *New SiteName here* \n` +
          "  \n" +
          `1. **Do you want Email addresses to be updated for claim notification?:**    [*Yes/No*] \n` +
          `   1. **If Yes, please provide the complete Email list of stakeholders groups *(not individuals)* that should receive notifications such as las2-amcare@amazon.com, las2-safety@amazon.com. ` +
          `DO NOT use workers' compensation owned email alias such as workcomp-las2@amazon.com. A maximum of 5 email addresses are supported**  \n` +
          `      1. ${emailAliasListString}` +
          "      1. New Email Alias List: \n         1: *email-alias-1*\n         2: *email-alias-2*\n         3: *email-alias-3*\n         4: *email-alias-4*\n         5: *email-alias-5* \n" +
          "  \n" +
          `1. **Do you want Slack/Chime webhook to be updated for claim notification?:**   [*Yes/No*] \n` +
          `   1. **If Yes, add a Slack or Chime Webhook if you are interested to receive the Slack or Chime notifications. Follow the instructions on the [wiki](https://w.amazon.com/bin/view/Treasury/TreasuryTech/ClaimIntake/ClientDocumentation/GettingStarted/SetupClaimNotificationChannels/) to generate a webhook**  \n` +
          `      1. Current Slack/Chime Webhook: ${
            claimModel?.notificationWebhook || ""
          } \n` +
          `      1. New Slack/Chime Webhook URL: *Enter Slack/Chime Webhook here*  \n` +
          `1. Please get approval from your **manager** on this ticket as a comment.  \n`,
        title:
          "[Site Changes]: Request <Site Name Here> Notification settings changes ",
        severity: "THREE",
        ticket_type: "Secure Ticket",
        tags: `${SIM_TAGS.CUSTOMER_REPORTED},${SIM_TAGS.REQUEST}`,
        ...TREASURY_TECHNOLOGY_PRODUCT_CTI,
      };
      break;
    default:
      break;
  }
  query = { ...query, watchers: `${managerAlias}` };
  return queryString.stringifyUrl({ url: SIM_TICKETS_URL, query });
};
/* eslint-enable complexity */
