import { FormFieldType, TEXT_INPUT_TYPES } from "../types";
import { Input, InputProps } from "@amzn/awsui-components-react";
import React, { RefObject } from "react";

const renderInput = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: InputProps.ChangeDetail }) => void,
  onBlur: (field: FormFieldType) => void,
  ref: RefObject<InputProps.Ref> | null | undefined
) => {
  const type = field.config.textInputType
    ? field.config.textInputType
    : TEXT_INPUT_TYPES.DEFAULT;
  return (
    <Input
      value={field.state.value}
      onChange={onChange}
      placeholder={field.config.placeholderText}
      name={field.config.name}
      onBlur={() => onBlur(field)}
      type={type as InputProps.Type}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${field.config.uniqueName || field.config.name}-input`}
      disabled={field.state.disabled}
      ref={ref}
    />
  );
};

export default renderInput;
