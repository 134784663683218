import React, { useEffect, useState } from "react";
import { PAGE_TITLES } from "src/app/constants";
import SideMenu from "src/components/sideMenu/SideMenu";
import { AppLayout, Flashbar } from "@amzn/awsui-components-react";
import ClaimsHistory from "src/views/claimsHistory/ClaimsHistory";

const ClaimsHistoryPage = () => {
  const [navigationOpen, setNavigationOpen] = useState(true);

  /**
   * Changes the browser tab/menu bar title to Search results when page loads
   */
  useEffect(() => {
    document.title = PAGE_TITLES.CLAIMS_HISTORY;
  }, []);

  type Type = "success" | "warning" | "info" | "error";

  const [flashbarItems] = React.useState([
    {
      type: "info" as Type,
      dismissible: false,
      dismissLabel: "Dismiss message",
      content: "Search results are limited to claims submitted through Nucleus",
      id: "message_1",
      statusIconAriaLabel: "Important Information",
    },
  ]);
  return (
    <AppLayout
      content={<ClaimsHistory />}
      navigation={<SideMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      toolsHide={true}
      ariaLabels={{
        navigationClose: "Close side menu",
        navigationToggle: "Open Side menu",
      }}
      notifications={
        <Flashbar items={flashbarItems} data-testid="claims-history-flashbar" />
      }
      contentType="table"
      footerSelector="#app-footer"
      headerSelector="#app-header"
    />
  );
};
export default ClaimsHistoryPage;
