import React from "react";
import TopNavigationBar from "../components/header";
import Footer from "../components/footer";
import InvalidStateModal from "../components/invalid-state-modal/InvalidStateModal";
import { Outlet } from "react-router-dom";

const HomePage = () => {
  return (
    <React.Fragment>
      <TopNavigationBar />
      <InvalidStateModal />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
export default HomePage;
