import React from "react";
import {
  SideNavigation,
  SideNavigationProps,
} from "@amzn/awsui-components-react/polaris";
import { ROUTES, USER_GUIDE_URL } from "src/app/constants";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "src/redux/store";
import { useAppSelector } from "src/redux/hooks";
import { Role } from "src/auth/types/interfaces";

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeHref, setActiveHref] = React.useState(location.pathname || "/");

  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);

  const CLAIMS_HISTORY: SideNavigationProps.Item = {
    type: "link",
    text: "Claims History",
    href: ROUTES.CLAIMS_HISTORY,
    external: true,
  };

  const CLAIM_INTAKE_FORM: SideNavigationProps.Item = {
    type: "link",
    text: "Claim Intake Form",
    href: ROUTES.CLAIM_INTAKE_FORM,
  };

  const ADMIN_TOOLS: SideNavigationProps.Item = {
    type: "section",
    text: "Admin Tools",
    items: [
      {
        type: "link",
        text: "Site Configuration",
        href: ROUTES.SITE_CONFIGURATION,
      },
    ],
  };
  const DIVIDER: SideNavigationProps.Item = { type: "divider" };

  const RESOURCES: SideNavigationProps.Item = {
    type: "section",
    text: "Resources",
    items: [
      {
        type: "link",
        text: "Preferred Provider List",
        href: ROUTES.MEDICAL_PANEL_DATA,
        external: true,
      },
      {
        type: "link",
        text: "User Guide",
        href: USER_GUIDE_URL,
        external: true,
      },
    ],
  };
  let navItems: SideNavigationProps.Item[] = [CLAIMS_HISTORY, RESOURCES];
  if (user.roles.includes(Role.WC_CLAIM_USER)) {
    navItems = [CLAIM_INTAKE_FORM, CLAIMS_HISTORY, RESOURCES];
  }
  if (user.roles.includes(Role.WC_CLAIM_USER_LIMITED)) {
    navItems = [CLAIM_INTAKE_FORM, RESOURCES];
  }
  if (user.roles.includes(Role.WC_CLAIM_PRODUCT_GROUP)) {
    navItems = [...navItems, DIVIDER, ADMIN_TOOLS];
  }
  return (
    <div className="side-menu-wrapper">
      <SideNavigation
        activeHref={activeHref}
        header={{ href: "/", text: "Workers' Compensation" }}
        onFollow={(event) => {
          // Prevent from following the link if user is at that page
          if (activeHref === event.detail.href) {
            event.preventDefault();
            return;
          }
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
            navigate(event.detail.href);
          }
        }}
        items={navItems}
      />
    </div>
  );
};
export default SideMenu;
