import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
  combineReducers,
} from "@reduxjs/toolkit";
import { rememberReducer, rememberEnhancer } from "redux-remember";
import formsReducer from "src/forms/formsSlice";
import appReducer from "src/app/appSlice";
import authReducer from "src/auth/authSlice";
import wizardReducer from "src/components/wizard/wizardSlice";
import reviewPageReducer from "src/forms/reviewPage/reviewPageSlice";
import sitesReducer from "src/sites/sitesSlice";
import medicalPanelDataReducer from "src/views/medicalPanelData/medicalPanelDataSlice";
import mySubmittedClaimsReducer from "src/components/mySubmittedClaims/mySubmittedClaimsSlice";
import searchResultsReducer from "src/components/searchResults/searchResultsSlice";

export const rootReducer = combineReducers({
  forms: formsReducer,
  app: appReducer,
  auth: authReducer,
  wizard: wizardReducer,
  reviewPage: reviewPageReducer,
  sites: sitesReducer,
  medicalPanelData: medicalPanelDataReducer,
  mySubmittedClaims: mySubmittedClaimsReducer,
  searchResults: searchResultsReducer,
});

// Don't remember the auth state to force new authentication on every login
const rememberedKeys = ["forms", "wizard", "reviewPage", "sites"];
const persistedReducer = rememberReducer(rootReducer);
const enhancers = [
  rememberEnhancer(window.localStorage, rememberedKeys, {
    persistWholeStore: true,
  }),
];
export function setupStore(
  preloadedState?: PreloadedState<RootState>,
  enhancers?: any[]
) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      /**
       * Disable middleware causing slowdowns and warnings in dev environment, this does
       * not affect production as these middlewares are disabled by default in production.
       * https://redux-toolkit.js.org/api/getDefaultMiddleware
       */
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    enhancers,
  });
}
export const store = setupStore({}, enhancers);
export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
