import React from "react";
import { generateSimLinkFromClaim } from "./generateSimLinkFromClaim";
import { useAppSelector } from "src/redux/hooks";
import type { RootState } from "src/redux/store";
import { Link, LinkProps } from "@amzn/awsui-components-react";
import { Link as ReactRouterLink } from "react-router-dom";
import { SIM_TYPES } from "./types";

interface SimLinkGeneratorProps {
  children: React.ReactNode;
  simType: SIM_TYPES;
  claimNumber?: string;
  claimModel?: any;
  color?: "normal" | "inverted";
  variant?: "primary" | "secondary" | "info" | "awsui-value-large";
  target?: "_self" | "_blank" | "_parent" | "_top";
  external?: boolean;
  fontSize?: LinkProps.FontSize;
  ariaLabel?: string;
  reactRouterLink?: boolean;
}
const SimLinkGenerator = ({
  simType,
  claimNumber,
  claimModel,
  color,
  variant,
  target,
  external,
  fontSize,
  children,
  ariaLabel,
  reactRouterLink,
}: SimLinkGeneratorProps) => {
  //Redux state
  const { user } = useAppSelector((state: RootState) => state.auth);
  if (reactRouterLink) {
    return (
      <ReactRouterLink
        to={generateSimLinkFromClaim(claimModel, simType, claimNumber, user)}
        target={target}
      >
        {children}
      </ReactRouterLink>
    );
  }
  return (
    <Link
      href={generateSimLinkFromClaim(claimModel, simType, claimNumber, user)}
      color={color}
      variant={variant}
      target={target}
      external={external}
      fontSize={fontSize}
      ariaLabel={ariaLabel}
    >
      {children}
    </Link>
  );
};
export default SimLinkGenerator;
