import React from "react";
import {
  Box,
  Button,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";

const TableNoMatchState = ({
  onClearFilter,
}: {
  onClearFilter: () => void;
}) => (
  <Box
    margin={{ vertical: "xs" }}
    textAlign="center"
    color="inherit"
    data-testid="table-no-match-state"
  >
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          No match found.
        </Box>
      </div>
      <Button data-testid="no-match-clear-filter" onClick={onClearFilter}>
        Clear filter
      </Button>
    </SpaceBetween>
  </Box>
);

export default TableNoMatchState;
