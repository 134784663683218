import {
  AttributeEditorField,
  FormFieldConfigType,
  INPUT_TYPES,
  SelectFormField,
  TEXT_INPUT_TYPES,
  TextInputFormField,
} from "../../types";
import React from "react";
import { SITE_CONFIGURATION_CONSTANTS } from "../../constants";
import { preProcessFormFields } from "../../tools";
import { USA_STATES } from "../../options";

export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: SITE_CONFIGURATION_CONSTANTS.SITE_ADDRESS_FIELDS,
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    header: "Site Address",
    label: "Site Address",
    fields: [
      {
        name: SITE_CONFIGURATION_CONSTANTS.CODE,
        label: "Airport Code",
        description: (
          <span>Navigate to Origami and locate &quot;Airport code&quot;.</span>
        ),
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.NAME,
        label: "Site Name",
        description:
          "This is the name that will be displayed to users in the site selection drop down. Example - Site name, building type, city/state. (LAS2 - FDFC (Las Vegas, NV)",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.UNIT_NUMBER,
        label: "HR Code for Site",
        description:
          "This field is a 1-6 character alphanumeric value different from the Airport Code",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_1,
        label: "Address Line 1",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.ADDRESS_LINE_2,
        label: "Address Line 2",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.CITY,
        label: "City",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.STATE,
        label: "State",
        description: "",
        placeholderText: "Select a State",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: USA_STATES,
      } as SelectFormField,

      {
        name: SITE_CONFIGURATION_CONSTANTS.ZIP_CODE,
        label: "Zip Code",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.COUNTRY,
        label: "Country",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: [{ value: "US", label: "United States" }],
      } as SelectFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.LAST_MODIFIED_BY,
        label: "",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
    ],
  },
  {
    name: SITE_CONFIGURATION_CONSTANTS.SITE_EMAIL_NOTIFICATION_FIELDS,
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    header: "Email Notifications",
    label: "Email Notifications",
    fields: [
      {
        name: SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST,
        label: "Email Alias Notifications List",
        description: (
          <span>
            Please enter the email aliases (without &quot;@amazon.com&quot;).
            Only Amazon email aliases are supported.
          </span>
        ),
        placeholderText: `Enter email alias without “@amazon.com”`,
        hasPopover: false,
        type: INPUT_TYPES.ATTRIBUTE_EDITOR,
        constraintText: "",
        emptyAttributeListText: "No items",
        addAttributeText: "Add another email alias",
        removeAttributeText: "Remove",
        removeButtonAriaLabel: "Remove email alias",
        itemRemovedAriaLive: "Email alias has been removed",
      } as AttributeEditorField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.EMAIL_NOTIFICATION_REQUESTED_BY,
        label:
          "Alias/Login of user that requested the Email Notifications change",
        description:
          "If this field is shown as disabled and it needs to be edited, make any change to the Email Alias Notifications List to enable it",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
    ],
  },
  {
    name: SITE_CONFIGURATION_CONSTANTS.SITE_NOTIFICATION_WEBHOOK_FIELDS,
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    header: "Slack/Chime Notifications",
    label: "Slack/Chime Notifications",
    fields: [
      {
        name: SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK,
        label: "Slack/Chime Webhook",
        description: "",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.URL,
        constraintText: "",
      } as TextInputFormField,
      {
        name: SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK_REQUESTED_BY,
        label:
          "Alias/Login of user that requested the Slack/Chime Webhook change",
        description:
          "If this field is shown as disabled and it needs to be edited, make any change to the Slack/Chime Webhook List to enable it",
        placeholderText: "",
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
        constraintText: "",
      } as TextInputFormField,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
