import { formFieldsConfigProcessed } from "./config/AssociateInformationConfig";
import { ASSOCIATE_INFORMATION_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/AssociateInformationState";
import { formFieldsValidation } from "./validations/AssociateInformationValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Associate Information";
export const formDescription =
  'Enter Employee ID & Alias/Login, then click "Search Associate" to auto populate associate details.';
export const formName = ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME;
