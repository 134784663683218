import {
  Input,
  InputProps,
  DateRangePickerProps,
  Icon,
  ButtonDropdown,
  ButtonDropdownProps,
  DateRangePicker,
  Popover,
  StatusIndicator,
  SpaceBetween,
  Alert,
  TextContent,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import React, { useEffect, useMemo, useState } from "react";
import { ROUTES } from "src/app/constants";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { setValueInAppState } from "src/app/appSlice";
import { MetricsPublisher } from "src/metrics";
import {
  RELATIVE_OPTIONS,
  RELATIVE_RANGE_STRINGS,
  SEARCH_PARAMETERS,
} from "../searchResults/constants";
import { isEqual } from "lodash";
import { setSearchParameters } from "../searchResults/searchResultsSlice";
import moment from "moment-timezone";
import { GENERAL_FORM_CONSTANTS } from "src/forms/constants";
import SimLinkGenerator from "../simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "../simLinkGenerator/types";
//Declare metricsPublishers, they will be initialized on first render
let metricsPublisher: MetricsPublisher;
let metricsPublisherSearchBarBySite: MetricsPublisher;
let metricsPublisherSearchByEmployeeId: MetricsPublisher;
let metricsPublisherSearchByAlias: MetricsPublisher;
let metricsPublisherSearchBySite: MetricsPublisher;
interface ClaimsSearchBarProps {
  topNavigation: boolean;
}

const MAX_SEARCH_TERM_LENGTH = 11;
const ClaimsSearchBar = ({ topNavigation }: ClaimsSearchBarProps) => {
  const SEARCH_BY_LABELS: { [key: string]: any } = {
    [SEARCH_PARAMETERS.ALIAS]: {
      buttonText: "Search by Alias/Login",
      buttonTextSmall: "By Alias",
      placeholder: "Search for claims using the injured employee's alias/login",
      placeholderSmall: "Search using alias/login",
    },
    [SEARCH_PARAMETERS.EMPLOYEE_ID]: {
      buttonText: "Search by Employee ID",
      buttonTextSmall: "By EID",
      placeholder: "Search for claims using the injured employee's ID",
      placeholderSmall: "Search using Employee ID",
    },
    [SEARCH_PARAMETERS.SITE]: {
      buttonText: "Search by Incident Site",
      buttonTextSmall: "By Site",
      placeholder: "Search for claims using the Incident Site",
      placeholderSmall: "Search using Site",
    },
  };

  const DEFAULT_BUTTON_DROPDOWN_ITEMS: ButtonDropdownProps.Item[] = [
    {
      id: SEARCH_PARAMETERS.ALIAS,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.ALIAS].buttonText,
    },
    {
      id: SEARCH_PARAMETERS.EMPLOYEE_ID,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.EMPLOYEE_ID].buttonText,
    },
    {
      id: SEARCH_PARAMETERS.SITE,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.SITE].buttonText,
    },
  ];

  const SMALL_BUTTON_DROPDOWN_ITEMS: ButtonDropdownProps.Item[] = [
    {
      id: SEARCH_PARAMETERS.ALIAS,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.ALIAS].buttonTextSmall,
    },
    {
      id: SEARCH_PARAMETERS.EMPLOYEE_ID,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.EMPLOYEE_ID].buttonTextSmall,
    },
    {
      id: SEARCH_PARAMETERS.SITE,
      text: SEARCH_BY_LABELS[SEARCH_PARAMETERS.SITE].buttonTextSmall,
    },
  ];

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { showSmallScreenSearchBar } = useAppSelector(
    (state: RootState) => state.app
  );
  const { listWcClaimsError, listWcClaimsErrorList } = useAppSelector(
    (state: RootState) => state.searchResults
  );
  const [searchParams] = useSearchParams();

  const [dateRange, setDateRange] =
    React.useState<DateRangePickerProps.Value | null>(null);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  React.useState(true);

  const [searchByItemKey, setSearchByItemKey] =
    React.useState<SEARCH_PARAMETERS>(SEARCH_PARAMETERS.ALIAS);

  const [buttonDropDownItems, setButtonDropDownItems] = React.useState(
    DEFAULT_BUTTON_DROPDOWN_ITEMS
  );
  const [datePickerChanged, setDatePickerChanged] = useState(false);

  const [alertText, setAlertText] = useState<string | React.ReactNode>("");

  const navigate = useNavigate();
  /**
   * Hook to create the event listener for matchMedia which allows us to
   * get the media match for a given query, in our case we're interested in
   * min-width media queries to respond to different screen sizes.
   */
  const useMediaQuery = (query: string) => {
    const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = useState(mediaQuery.matches);
    useEffect(() => {
      const onChange = () => setMatch(mediaQuery.matches);
      mediaQuery.addEventListener("change", onChange);

      return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);
    return match;
  };

  /**
   * Helper function to return 4 media query breakpoints for different screen sizes.
   */
  const useMediaQueries = () => {
    const xs = useMediaQuery("(min-width: 320px)");
    const sm = useMediaQuery("(min-width: 480px)");
    const md = useMediaQuery("(min-width: 690px)");
    const lg = useMediaQuery("(min-width: 1000px)");

    return { xs, sm, md, lg };
  };
  const screenSizes = useMediaQueries();

  /**
   * Initializes metrics and gets values based on searchParams to populate
   * information in the fields by default on first render.
   */
  useEffect(() => {
    const employeeIdParam = searchParams.get(SEARCH_PARAMETERS.EMPLOYEE_ID);
    const aliasParam = searchParams.get(SEARCH_PARAMETERS.ALIAS)?.toLowerCase();

    const siteParam = searchParams.get(SEARCH_PARAMETERS.SITE);
    const startDateParam = searchParams.get(SEARCH_PARAMETERS.START_DATE);
    const endDateParam = searchParams.get(SEARCH_PARAMETERS.END_DATE);

    //Initialize metrics on first component render to be used later
    metricsPublisher = new MetricsPublisher("claimsSearch");
    metricsPublisherSearchBarBySite = new MetricsPublisher(
      "claimsSearchBarUsedBySite"
    );
    metricsPublisherSearchByEmployeeId = new MetricsPublisher(
      "claimsSearchByEmployeeId"
    );
    metricsPublisherSearchByAlias = new MetricsPublisher("claimsSearchByAlias");
    metricsPublisherSearchBySite = new MetricsPublisher("claimsSearchBySite");
    setSearchTerm(employeeIdParam || aliasParam || siteParam || "");
    setSearchByItemKey(
      employeeIdParam
        ? SEARCH_PARAMETERS.EMPLOYEE_ID
        : siteParam
        ? SEARCH_PARAMETERS.SITE
        : SEARCH_PARAMETERS.ALIAS
    );
    filterButtonDropdownItems(SEARCH_PARAMETERS.ALIAS, screenSizes);
    if (startDateParam != null && endDateParam != null) {
      /**
       * Date().getTimezoneOffset() * 60000 is subtracted to account for the users timezone
       * and converting it to milliseconds
       */
      setDateRange({
        startDate: new Date(
          Number.parseInt(startDateParam) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        endDate: new Date(
          Number.parseInt(endDateParam) - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        type: "absolute",
      });
    } else {
      // If no date search params already present, default date params to previous 1 year
      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 1);
      setDateRange({
        startDate: new Date(
          startDate.getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        endDate: new Date(
          endDate.getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        type: "absolute",
      });
    }
  }, []);

  /**
   * Normalizes the date range based on the provided DateRangePickerProps.Value.
   *
   * @param {DateRangePickerProps.Value | null} dateRange - The date range to be normalized.
   * @returns {object} - An object containing the normalized start and end dates.
   */
  const normalizeDateRange = (dateRange: DateRangePickerProps.Value | null) => {
    let normalizedStartDate = 0;
    let normalizedEndDate = 0;
    // If datepicker search value is absolute calculate start and end dates with specific values
    if (
      dateRange?.type === "absolute" &&
      dateRange?.startDate &&
      dateRange?.endDate
    ) {
      /**
       * Date().getTimezoneOffset() * 60000 is added to match UTC timezone
       * when requesting the data from the API
       */
      normalizedStartDate =
        Date.parse(dateRange.startDate) +
        new Date().getTimezoneOffset() * 60000;

      normalizedEndDate =
        Date.parse(dateRange.endDate) + new Date().getTimezoneOffset() * 60000;

      // Get the timestamp at endDate midnight matching user's timezone
      const endDateMidnight = new Date(normalizedEndDate);
      endDateMidnight.setDate(endDateMidnight.getDate() + 1);
      // Subtract 1 ms from 0:00:00 timestamp to get 23:59:59:999 timestamp
      const midnightTimestamp = endDateMidnight.getTime() - 1;
      // Make sure endDate does not exceed present time
      normalizedEndDate =
        endDateMidnight.getTime() < Date.now() ? midnightTimestamp : Date.now();

      // Setting date on date picker to normalized dates
      setDateRange({
        startDate: new Date(
          normalizedStartDate - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        endDate: new Date(
          normalizedEndDate - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10),
        type: "absolute",
      });
    }
    // If datepicker search value is relative calculate start and end dates with amount and time units
    else if (
      dateRange?.type === "relative" &&
      dateRange?.unit &&
      dateRange?.amount
    ) {
      normalizedStartDate = moment()
        .subtract(dateRange.amount, dateRange.unit)
        .valueOf();

      normalizedEndDate = moment.now();
    }
    return { normalizedStartDate, normalizedEndDate };
  };

  /**
   * Publishes the search bar metrics based on the current search parameters.
   *
   * @param {string} searchTerm - The search term to be published as a metric.
   */
  const publishSearchMetrics = (searchTerm: string) => {
    if (searchByItemKey === SEARCH_PARAMETERS.ALIAS) {
      metricsPublisherSearchByAlias.publishClaimsSearchByAlias(searchTerm);
    }
    if (searchByItemKey === SEARCH_PARAMETERS.SITE) {
      metricsPublisherSearchBySite.publishClaimsSearchBySite(searchTerm);
    }
    if (searchByItemKey === SEARCH_PARAMETERS.EMPLOYEE_ID) {
      metricsPublisherSearchByEmployeeId.publishClaimsSearchByEmployeeId(
        searchTerm
      );
    }
    metricsPublisher.publishClaimsSearchBarUsed();
    metricsPublisherSearchBarBySite.publishClaimsSearchBarUsedBySite(
      user.siteCode
    );
  };

  const validateSearchTerm = (searchTerm: string) => {
    if (topNavigation) {
      return true;
    }
    if (!searchTerm) {
      setAlertText("Search term cannot be empty. Please verify and try again.");
      return false;
    }
    if (searchTerm?.length > MAX_SEARCH_TERM_LENGTH) {
      setAlertText(
        "Search term cannot be greater than 11 characters. Please verify and try again."
      );
      return false;
    }
    const numberRegex = new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX);
    if (
      searchByItemKey === SEARCH_PARAMETERS.EMPLOYEE_ID &&
      numberRegex.test(searchTerm)
    ) {
      setAlertText("Employee ID must be only numbers.");
      return false;
    }
    setAlertText("");
    return true;
  };
  /**
   * Handles the search functionality when the user performs a search.
   */
  const handleOnSearch = () => {
    let startDateText = "";
    let endDateText = "";
    let processedSearchTerm = searchTerm;
    const { normalizedStartDate, normalizedEndDate } =
      normalizeDateRange(dateRange);

    // If the search is by alias, convert the search term to lowercase
    if (searchByItemKey === SEARCH_PARAMETERS.ALIAS) {
      processedSearchTerm = processedSearchTerm.toLowerCase();
      setSearchTerm(processedSearchTerm);
    }
    if (!validateSearchTerm(processedSearchTerm)) {
      return;
    }
    let processedSearchParameters: any = {
      searchBy: searchByItemKey,
      searchTerm: processedSearchTerm,
    };
    // If the search is by site, set the start and end date text and add them to the processed search parameters
    if (searchByItemKey === SEARCH_PARAMETERS.SITE) {
      startDateText = `&${SEARCH_PARAMETERS.START_DATE}=` + normalizedStartDate;
      endDateText = `&${SEARCH_PARAMETERS.END_DATE}=` + normalizedEndDate;
      processedSearchParameters = {
        ...processedSearchParameters,
        startDate: normalizedStartDate,
        endDate: normalizedEndDate,
      };
    }

    // Construct the search route based on the processed search parameters
    const searchRoute =
      ROUTES.CLAIMS_HISTORY +
      `?${searchByItemKey}=` +
      processedSearchTerm +
      startDateText +
      endDateText;

    publishSearchMetrics(processedSearchTerm);

    // If the search is not from the top navigation
    if (!topNavigation) {
      // Dispatch the setSearchParameters action with the processed search parameters
      dispatch(setSearchParameters(processedSearchParameters));

      // Navigate to the search route, replacing the current URL so that the search can be saved when the user reloads the search component
      navigate(searchRoute);
    } else {
      // If the search is from the top navigation, open the search route in a new tab
      window.open(searchRoute, "_blank", "noreferrer");
    }
  };

  const onKeyDown = (e: string) => {
    if (e === "Enter" && !searchDisabled) {
      handleOnSearch();
    }
  };

  useEffect(() => {
    if (datePickerChanged) {
      handleOnSearch();
      setDatePickerChanged(false);
      metricsPublisher.publishClaimsSearchDateRangeUsed();
    }
  }, [datePickerChanged]);

  useEffect(() => {
    setSearchDisabled(!searchTerm);
  }, [dateRange, searchByItemKey, searchTerm]);

  useEffect(() => {
    if (listWcClaimsError) {
      if (listWcClaimsErrorList?.length) {
        const parsedErrors: React.ReactNode = listWcClaimsErrorList.map(
          (item: any) => {
            return (
              <li key={`fieldKey-${item.fieldName}`}>
                <div>
                  {item.fieldName ? <b>{`${item.fieldName}: `} </b> : ""}
                  {item.errorMessage}
                </div>
              </li>
            );
          }
        );
        const errorMessage: React.ReactNode = (
          <TextContent>
            <div>
              There was a problem retrieving your search results. Please verify
              the following and try again:
            </div>
            <ul>{parsedErrors}</ul>
          </TextContent>
        );
        setAlertText(errorMessage);
      } else {
        setAlertText(
          <span>
            There was an error loading your search results. Please verify and
            try again. If the error persists please open a{" "}
            <SimLinkGenerator
              simType={SIM_TYPES.REPORT_A_BUG}
              target="_blank"
              ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
            >
              support ticket
            </SimLinkGenerator>{" "}
            to get help with this issue.
          </span>
        );
      }
    }
  }, [listWcClaimsError, listWcClaimsErrorList]);
  const toggleSmallSearchBar = (value: boolean) => {
    dispatch(setValueInAppState({ key: "showSmallScreenSearchBar", value }));
  };

  /**
   * Conditions the search term based on the current search parameters.
   *
   * @param {string} searchTerm - The search term to be conditioned.
   * @returns {string} - The conditioned search term.
   */
  const conditionSearchTerm = (searchTerm: string) => {
    // If the search is by site, convert the search term to uppercase
    if (searchByItemKey === SEARCH_PARAMETERS.SITE) {
      return searchTerm.toUpperCase();
    }
    // If the search is by alias, convert the search term to lowercase
    else if (searchByItemKey === SEARCH_PARAMETERS.ALIAS) {
      return searchTerm.toLowerCase();
    }
    // If the search is not by site or alias, return the search term as is
    return searchTerm;
  };

  const renderBarInput = (screenSizes: { [key: string]: boolean }) => {
    const placeholder = !screenSizes?.lg
      ? SEARCH_BY_LABELS[searchByItemKey].placeholderSmall
      : SEARCH_BY_LABELS[searchByItemKey].placeholder;
    return (
      <div className="input-filter" id="input-filter">
        <Input
          data-testid="search-bar"
          clearAriaLabel="clear search bar"
          onKeyDown={({ detail }: { detail: InputProps.KeyDetail }) =>
            onKeyDown(detail.key)
          }
          value={searchTerm}
          placeholder={placeholder}
          type="search"
          onChange={({ detail }: { detail: InputProps.ChangeDetail }) =>
            setSearchTerm(conditionSearchTerm(detail.value))
          }
        />
      </div>
    );
  };
  const filterButtonDropdownItems = (
    filterId: string,
    screenSizes: { [key: string]: boolean }
  ) => {
    const { sm } = screenSizes;
    const items = !sm
      ? SMALL_BUTTON_DROPDOWN_ITEMS
      : DEFAULT_BUTTON_DROPDOWN_ITEMS;
    const filteredItems = items.filter(
      (item: ButtonDropdownProps.Item) => item.id !== filterId
    );
    if (!isEqual(filteredItems, buttonDropDownItems)) {
      setButtonDropDownItems(filteredItems);
    }
  };
  /**
   * Renders the bar in 3 different states:
   *   - Large Screen size: Normal bar and advanced button
   *   - Medium Screen size:
   *     - Hidden search bar with only search button.
   *     - Normal size search bar with reduced size advanced button.
   */
  const responsiveRenderBar = () => {
    const screenSizes = useMediaQueries();
    filterButtonDropdownItems(searchByItemKey, screenSizes);
    const buttonText =
      !screenSizes.sm && topNavigation
        ? SEARCH_BY_LABELS[searchByItemKey].buttonTextSmall
        : SEARCH_BY_LABELS[searchByItemKey].buttonText;
    if (topNavigation && !screenSizes?.md) {
      if (showSmallScreenSearchBar) {
        return (
          <React.Fragment>
            {renderBarInput(screenSizes)}
            <div className="search-by-button">
              <ButtonDropdown
                data-testid="search-by-button"
                items={buttonDropDownItems}
                expandableGroups
                onItemClick={({
                  detail,
                }: {
                  detail: ButtonDropdownProps.ItemClickDetails;
                }) => {
                  setSearchByItemKey(detail.id as SEARCH_PARAMETERS);
                  filterButtonDropdownItems(detail.id, screenSizes);
                }}
                mainAction={{
                  text: buttonText,
                  onClick: handleOnSearch,
                }}
              ></ButtonDropdown>
            </div>

            <button
              data-testid="hide-search-bar"
              onClick={() => {
                toggleSmallSearchBar(false);
              }}
              className="transparent-button close-search-button"
              aria-label="Hide Claims Search Bar"
            >
              <Icon
                name="close"
                size="medium"
                variant={topNavigation ? "disabled" : "normal"}
              ></Icon>
            </button>
          </React.Fragment>
        );
      }
      const borderClass = !topNavigation ? "no-border" : "";
      return (
        <div className={`search-icon ${borderClass}`}>
          <button
            className="transparent-button"
            onClick={() => toggleSmallSearchBar(true)}
            aria-label="Show Claims Search Bar"
            data-testid="show-search-bar"
          >
            <Icon
              name="search"
              size="medium"
              variant={topNavigation ? "disabled" : "normal"}
            ></Icon>
          </button>
        </div>
      );
    }
    if (showSmallScreenSearchBar) {
      toggleSmallSearchBar(false);
    }

    return (
      <React.Fragment>
        {renderBarInput(screenSizes)}
        <div className="search-by-button">
          <ButtonDropdown
            data-testid="search-by-button"
            items={buttonDropDownItems}
            expandableGroups
            onItemClick={({
              detail,
            }: {
              detail: ButtonDropdownProps.ItemClickDetails;
            }) => {
              setSearchByItemKey(detail.id as SEARCH_PARAMETERS);
              filterButtonDropdownItems(detail.id, screenSizes);
              setSearchTerm("");
            }}
            mainAction={{
              text: buttonText,
              onClick: handleOnSearch,
            }}
          ></ButtonDropdown>
        </div>
      </React.Fragment>
    );
  };
  const renderDateRangePicker = () => {
    if (topNavigation || searchByItemKey !== SEARCH_PARAMETERS.SITE) {
      return null;
    }
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <DateRangePicker
          data-testid="search-bar-date-picker"
          onChange={({ detail }) => {
            setDateRange(detail.value);
            setDatePickerChanged(true);
          }}
          dateOnly={true}
          value={dateRange as DateRangePickerProps.Value}
          relativeOptions={RELATIVE_OPTIONS}
          i18nStrings={{
            formatRelativeRange: (
              value: DateRangePickerProps.RelativeValue
            ) => {
              const preloadedRelativeString = value.key
                ? RELATIVE_RANGE_STRINGS[value.key]
                : "";
              if (preloadedRelativeString) {
                return preloadedRelativeString;
              }
              return `Past ${value.amount} ${value.unit}${
                value.amount > 1 ? "s" : ""
              }`;
            },
            relativeModeTitle: "Relative Date Ranges",
            absoluteModeTitle: "Specific Date Range",
          }}
          isValidRange={(range) => {
            if (!range) {
              return {
                valid: false,
                errorMessage:
                  "Invalid selection. Please select a relative or specific date range.",
              };
            }
            if (range?.type === "relative") {
              if (!range.amount) {
                return {
                  valid: false,
                  errorMessage:
                    "Invalid selection. Please select an amount for your custom range.",
                };
              }
            }
            if (range?.type === "absolute") {
              const [startDateWithoutTime] = range.startDate.split("T");
              const [endDateWithoutTime] = range.endDate.split("T");
              if (!startDateWithoutTime || !endDateWithoutTime) {
                return {
                  valid: false,
                  errorMessage:
                    "The selected date range is incomplete. Select a start and end date for the date range.",
                };
              }
              if (Date.parse(range.startDate) > Date.parse(range.endDate)) {
                return {
                  valid: false,
                  errorMessage:
                    "The selected date range is invalid. The start date must be before the end date.",
                };
              }
              const nextMidnight = new Date();
              nextMidnight.setHours(24, 0, 0, 0);
              const nextMidnightUTC = nextMidnight.getTime();
              if (
                Date.parse(range.startDate) > nextMidnightUTC ||
                Date.parse(range.endDate) > nextMidnightUTC
              ) {
                return {
                  valid: false,
                  errorMessage:
                    "The selected date range is invalid. Dates cannot be in the future.",
                };
              }
            }
            return { valid: true };
          }}
          placeholder="Filter by a date range"
        />
        <Popover
          header="Filter by submitted date"
          content="Filter the search results by selecting a relative date (past month, past year, etc.) or a specific date range."
          triggerType="custom"
          data-testid="claims-search-bar-date-range-popover"
        >
          <StatusIndicator type="info" />
        </Popover>
      </SpaceBetween>
    );
  };

  const renderAlert = () => {
    if (!alertText || topNavigation) return null;
    return (
      <div className="search-bar-alert">
        <Alert
          dismissible={true}
          type="error"
          statusIconAriaLabel={"Error"}
          dismissAriaLabel={"Close Alert"}
          key="search-bar-alert"
          onDismiss={() => setAlertText("")}
        >
          {alertText}
        </Alert>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className={`claims-search-bar ${!topNavigation ? "flex-wrap" : ""}`}>
        {responsiveRenderBar()}
        {renderDateRangePicker()}
      </div>
      {renderAlert()}
    </React.Fragment>
  );
};
export default ClaimsSearchBar;
