import { TextContent } from "@amzn/awsui-components-react/polaris";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import React from "react";

const NavigationBarActions = () => {
  const { user } = useAppSelector((state: RootState) => state.auth);
  return (
    <div className="actions" data-testid="navigation-bar-details">
      {user.id && user.givenName ? (
        <div className="actions" data-testid="navigation-bar-details">
          <TextContent>
            <h3 className="header-text profile-name">{`Welcome back, ${
              user.givenName.split(" ")[0]
            }!`}</h3>
          </TextContent>
          <img
            className="profile-picture"
            src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${user.id}`}
            alt="Profile"
            data-testid="profile-picture"
          />
        </div>
      ) : (
        <div className="actions" data-testid="navigation-bar-details"></div>
      )}
    </div>
  );
};
export default NavigationBarActions;
