import { FormFieldType } from "../types";
import { RadioGroup, RadioGroupProps } from "@amzn/awsui-components-react";
import React from "react";

const renderRadioGroup = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: RadioGroupProps.ChangeDetail }) => void
) => {
  const options = field.state.options || field.config.options!;
  const name = field.config.uniqueName || field.config.name;
  return (
    <RadioGroup
      onChange={onChange}
      items={
        field.state.disabled
          ? options.map((i) => {
              return { ...i, disabled: true };
            })
          : options
      }
      value={field.state.value}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-radioGroup`}
    />
  );
};

export default renderRadioGroup;
