import { AttributeEditorField, FormFieldType } from "../types";
import { AttributeEditor, Input } from "@amzn/awsui-components-react";
import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { ASSOCIATE_INFORMATION_CONSTANTS } from "../constants";

const renderAttributeEditor = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: any }, field: FormFieldType) => void,
  onBlur: (field: FormFieldType) => void
) => {
  //Redux state
  const { allFormStates } = useAppSelector((state: RootState) => state.forms);
  const { user } = useAppSelector((state: RootState) => state.auth);
  if (
    !field.state.otherInformation?.submitterAlias ||
    !field.state.otherInformation?.associateAlias
  ) {
    // Add associateAlias and submitterAlias for validation purposes if they have not been added
    const { associateAlias } =
      allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME];
    field.state = {
      ...field.state,
      otherInformation: {
        associateAlias: associateAlias.value,
        submitterAlias: user.id,
      },
    };
  }
  const name = field.config.uniqueName || field.config.name;
  const fieldConfig = field.config as AttributeEditorField;
  return (
    <AttributeEditor
      key={name}
      onAddButtonClick={() => {
        onChange(
          {
            detail: {
              value: [...field.state.value, { value: "", errors: [] }],
            },
          },
          field
        );
        setTimeout(() => {
          const dataId = `${field.config.name}-${field.state.value.length - 1}`;
          const element = document.querySelector<HTMLInputElement>(
            `[data-id="${dataId}"]`
          );
          if (element?.childNodes[0]) {
            (element.childNodes[0] as HTMLElement).focus();
          }
        }, 50);
      }}
      onRemoveButtonClick={({ detail: { itemIndex } }: any) => {
        const tmpItems = [...field.state.value];
        tmpItems.splice(itemIndex, 1);
        onChange({ detail: { value: [...tmpItems] } }, field);
      }}
      items={field.state.value}
      addButtonText={fieldConfig.addAttributeText}
      isItemRemovable={() => {
        return field.state.value?.length > 1;
      }}
      disableAddButton={field.state.value?.length >= 5 || field.state.disabled}
      definition={[
        {
          control: (item: any, itemIndex: number) => (
            <Input
              key={`${field.config.name}-${itemIndex}`}
              value={item.value}
              placeholder={field.config.placeholderText}
              onChange={({ detail: { value } }: any) => {
                const newValues = [...field.state.value];
                newValues[itemIndex] = { value };
                onChange({ detail: { value: newValues } }, field);
              }}
              onBlur={() => onBlur(field)}
              data-testid={`${field.config.name}-${itemIndex}`}
              data-id={`${field.config.name}-${itemIndex}`}
              disabled={item.disabled || field.state.disabled}
            />
          ),
          errorText: (item: any) => item.errors?.join(", "),
        },
      ]}
      empty={fieldConfig.emptyAttributeListText}
      removeButtonText={fieldConfig.removeAttributeText}
      i18nStrings={{
        errorIconAriaLabel: "Error",
        itemRemovedAriaLive: fieldConfig.itemRemovedAriaLive,
        removeButtonAriaLabel: (item: any) => {
          return item.value
            ? `${field.config.removeButtonAriaLabel}: ${item.value}`
            : `${field.config.removeButtonAriaLabel}, field is currently empty`;
        },
      }}
      data-testid={fieldConfig.uniqueName || fieldConfig.name}
    />
  );
};

export default renderAttributeEditor;
