import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { CreateWcClaimCommandInput } from "@amzn/ttechclaimintakeservice-client";
import { SendCreateWcClaimCommand } from "../api/claims/createWcClaim";
import { compareKeys, generateAmazonClaimReferenceId } from "src/forms/tools";
import { ClaimSubmittedStatus, type ResetFormModalContents } from "./types";
import {
  ProcessedApiResponse,
  ResponseField,
  SERVER_ERROR_RESPONSES,
} from "src/api/types";
import { SEDGWICK } from "src/forms/constants";
import { getWcClaimService } from "src/api/claims/getWcClaimService";
// Define a type for the slice state
interface AppState {
  [index: string]: any;

  amazonClaimReferenceId: string;
  duplicateClaim: { [key: string]: any } | null;
  duplicateClaimChecked?: boolean;
  createWcClaimResponse?: ProcessedApiResponse;
  submitScreenStatus?: string;
  triggerOpenResetFormModal?: boolean;
  remoteResetFormModalContents: ResetFormModalContents;
  requestAppReset: boolean;
  appLoaded?: boolean;
  invalidStateModalOpen: boolean;
  stateIsValid: boolean;
  workersCompensationTpa: string;
  tpaMaintenance?: boolean;
}
export const initialState: AppState = {
  duplicateClaim: null,
  duplicateClaimChecked: false,
  submitScreenStatus: ClaimSubmittedStatus.LOADING,
  amazonClaimReferenceId: generateAmazonClaimReferenceId(),
  requestAppReset: false,
  triggerOpenResetFormModal: false,
  remoteResetFormModalContents: {
    title: "Reset Form",
    description: "The form will be reset, are you sure you want to continue?",
    cancel: "No",
    confirm: "Yes, Reset Form",
  },
  appLoaded: false,
  invalidStateModalOpen: false,
  stateIsValid: false,
  workersCompensationTpa: SEDGWICK,
  tpaMaintenance: false,
};

export const verifyStateIsValid = (state: AppState) => {
  return compareKeys(state, initialState);
};

export const getWcClaim = createAsyncThunk(
  "app/getWcClaim",
  async (
    params: { associateEmployeeId: string; incidentDate: string },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkAPI: any
  ) => {
    const { associateEmployeeId, incidentDate } = params;
    try {
      const getClaimResponse = await getWcClaimService({
        associateEmployeeId,
        incidentDate,
      });
      return getClaimResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createWcClaim = createAsyncThunk(
  "app/createWcClaim",
  async (
    params: { createWcClaimInput: CreateWcClaimCommandInput },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkAPI: any
  ) => {
    const { createWcClaimInput } = params;
    try {
      const response = await SendCreateWcClaimCommand(createWcClaimInput);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setValueInAppState: (
      state: AppState,
      action: PayloadAction<{
        key: string;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;
      state[key as keyof AppState] = value;
    },
    resetAppState: () => {
      return {
        ...initialState,
        amazonClaimReferenceId: generateAmazonClaimReferenceId(),
      };
    },
    requestAppReset: (state: AppState) => {
      state.requestAppReset = true;
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        getWcClaim.fulfilled,
        (state: AppState, action: PayloadAction<any>) => {
          state.duplicateClaimChecked = true;
          if (action.payload.claimNumber) {
            state.duplicateClaim = action.payload;
          }
        }
      )
      .addCase(
        createWcClaim.fulfilled,
        (state: AppState, action: PayloadAction<any>) => {
          state.createWcClaimResponse = action.payload;
          state.submitScreenStatus = ClaimSubmittedStatus.SUCCESS;
        }
      )
      .addCase(createWcClaim.pending, (state: AppState) => {
        state.submitScreenStatus = ClaimSubmittedStatus.LOADING;
      })
      .addCase(
        createWcClaim.rejected,
        (state: AppState, action: PayloadAction<any>) => {
          state.submitScreenStatus = ClaimSubmittedStatus.ERROR;
          const data: ResponseField[] = [];
          const { message, errors, $metadata, warningList } = action.payload;
          const statusCode = $metadata?.httpStatusCode?.toString();
          if (SERVER_ERROR_RESPONSES.includes(statusCode)) {
            data.push({
              fieldName: "Amazon Claim Reference ID",
              fieldValue: current(state).amazonClaimReferenceId,
            });
          }

          if (errors?.length) {
            data.push(...errors);
          }
          const processedResponse: ProcessedApiResponse = {
            data,
            statusCode,
            warningList,
            message,
          };
          state.createWcClaimResponse = processedResponse;
        }
      );
  },
});

export const { setValueInAppState, resetAppState, requestAppReset } =
  appSlice.actions;

export default appSlice.reducer;
