import {
  GENERAL_FORM_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
} from "../../constants";
export const formFieldsValidation = {
  [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
    required: { value: true },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_FIRST_NAME]: {
    required: { value: false },
    maxCharacters: { value: 20 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_LAST_NAME]: {
    required: { value: false },
    maxCharacters: { value: 25 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_1]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ADDRESS_LINE_2]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_CITY]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_STATE]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_ZIPCODE]: {
    required: { value: false },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ZIP_CODE_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_MESSAGE,
    },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_CHARACTERS_MESSAGE,
    },
    maxCharacters: {
      value: 5,
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_MAX_CHARACTERS_MESSAGE,
    },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_COUNTRY]: {
    required: { value: false },
    disabled: { value: true },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION]:
    {
      required: { value: false },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      required: { value: false },
      matchRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.PHONE_REGEX),
        message: GENERAL_FORM_CONSTANTS.PHONE_INVALID_MESSAGE,
      },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      required: { value: false },
      maxCharacters: { value: 6 },
      allowedCharactersRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
        message: GENERAL_FORM_CONSTANTS.EXTENSION_INVALID_CHARACTERS_MESSAGE,
      },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_FIRST_NAME]: {
    required: { value: false },
    maxCharacters: { value: 20 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_LAST_NAME]: {
    required: { value: false },
    maxCharacters: { value: 25 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_1]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ADDRESS_LINE_2]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_CITY]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_STATE]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_ZIPCODE]: {
    required: { value: false },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ZIP_CODE_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_MESSAGE,
    },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_CHARACTERS_MESSAGE,
    },
    maxCharacters: {
      value: 5,
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_MAX_CHARACTERS_MESSAGE,
    },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_COUNTRY]: {
    required: { value: false },
    disabled: { value: true },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION]:
    {
      required: { value: false },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      required: { value: false },
      matchRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.PHONE_REGEX),
        message: GENERAL_FORM_CONSTANTS.PHONE_INVALID_MESSAGE,
      },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      required: { value: false },
      maxCharacters: { value: 6 },
      allowedCharactersRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
        message: GENERAL_FORM_CONSTANTS.EXTENSION_INVALID_CHARACTERS_MESSAGE,
      },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.TRANSPORTED_TO_HOSPITAL]: {
    required: {
      value: true,
      message: "Please answer this question before proceeding.",
    },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_NAME]: {
    required: { value: false },
    maxCharacters: { value: 255 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_1]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ADDRESS_LINE_2]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_CITY]: {
    required: { value: false },
    maxCharacters: { value: 128 },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_STATE]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_ZIPCODE]: {
    required: { value: false },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ZIP_CODE_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_MESSAGE,
    },
    allowedCharactersRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_INVALID_CHARACTERS_MESSAGE,
    },
    maxCharacters: {
      value: 5,
      message: GENERAL_FORM_CONSTANTS.ZIP_CODE_MAX_CHARACTERS_MESSAGE,
    },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_COUNTRY]: {
    required: { value: false },
    disabled: { value: true },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION]: {
    required: { value: false },
  },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_PHONE_NUMBER]:
    {
      required: { value: false },
      matchRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.PHONE_REGEX),
        message: GENERAL_FORM_CONSTANTS.PHONE_INVALID_MESSAGE,
      },
    },
  [PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_EXTENSION]:
    {
      required: { value: false },
      maxCharacters: { value: 6 },
      allowedCharactersRegExp: {
        value: new RegExp(GENERAL_FORM_CONSTANTS.ONLY_NUMBERS_REGEX),
        message: GENERAL_FORM_CONSTANTS.EXTENSION_INVALID_CHARACTERS_MESSAGE,
      },
    },
};
