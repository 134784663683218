import React, { useEffect } from "react";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table from "@amzn/awsui-components-react/polaris/table";
import { Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  PAGINATION_LABELS,
  WC_CLAIM_COLUMN_DEFINITIONS,
} from "../table/tableUtils";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { listWcClaims } from "./searchResultsSlice";
import TableEmptyState from "../table/TableEmptyState";
import TableNoMatchState from "../table/TableNoMatchState";
import { CSV_CUSTOM_HEADERS, SEARCH_PARAMETERS } from "./constants";
import ClaimsSearchBar from "../header/ClaimsSearchBar";
import { MetricsPublisher } from "src/metrics";
import ExportToCSVButton from "./ExportToCSVButton";

//Declare metricsPublishers, they will be initialized on first render
let metricsPublisher: MetricsPublisher;
let metricsPublisherVisitedBySite: MetricsPublisher;

interface SearchParameters {
  [key: string]: any;
  [SEARCH_PARAMETERS.EMPLOYEE_ID]?: string | null;
  [SEARCH_PARAMETERS.ALIAS]?: string | null;
  [SEARCH_PARAMETERS.SITE]?: string | null;
  [SEARCH_PARAMETERS.START_DATE]?: number | null;
  [SEARCH_PARAMETERS.END_DATE]?: number | null;
}
/**
 * Uses the query parameters to load claims that match into a table
 * @returns Search Results table which loads claims using the query parameters
 */
function SearchResults() {
  //Redux Auth state
  const dispatch = useAppDispatch();

  //Redux SearchResults state
  const {
    resultClaims,
    gettingClaims,
    isMoreResults,
    searchBy,
    searchTerm,
    startDate,
    endDate,
  } = useAppSelector((state: RootState) => state.searchResults);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [urlSearchParams] = useSearchParams();

  const triggerSearch = (searchParameters: SearchParameters) => {
    const { alias, employeeId, site, startDate, endDate } = searchParameters;
    if (employeeId) {
      dispatch(
        listWcClaims({
          associateEmployeeID: employeeId,
        })
      );
      return;
    }
    if (alias) {
      dispatch(
        listWcClaims({
          associateAlias: alias,
        })
      );
      return;
    }

    if (site && startDate && endDate) {
      dispatch(
        listWcClaims({
          incidentSite: site ? site : undefined,
          // epoch timestamps are expected to be recieved in seconds by the backend. Date.parse returns them in milliseconds
          submittedStartDate: startDate! / 1000,
          submittedEndDate: endDate! / 1000,
        })
      );
      return;
    }
    if (site) {
      dispatch(
        listWcClaims({
          incidentSite: site ? site : undefined,
        })
      );
      return;
    }
  };
  useEffect(() => {
    const searchParameters = {
      [SEARCH_PARAMETERS.EMPLOYEE_ID]: urlSearchParams.get(
        SEARCH_PARAMETERS.EMPLOYEE_ID
      ),
      [SEARCH_PARAMETERS.ALIAS]: urlSearchParams
        .get(SEARCH_PARAMETERS.ALIAS)
        ?.toLowerCase(),
      [SEARCH_PARAMETERS.SITE]: urlSearchParams.get(SEARCH_PARAMETERS.SITE),
      [SEARCH_PARAMETERS.START_DATE]: parseInt(
        urlSearchParams.get(SEARCH_PARAMETERS.START_DATE) || "0"
      ),
      [SEARCH_PARAMETERS.END_DATE]: parseInt(
        urlSearchParams.get(SEARCH_PARAMETERS.END_DATE) || "0"
      ),
    };
    triggerSearch(searchParameters);

    metricsPublisher = new MetricsPublisher("searchResultsVisited");
    metricsPublisherVisitedBySite = new MetricsPublisher(
      "searchResultsVisitedBySite"
    );
  }, []);

  // Make sure user is loaded before logging metrics
  useEffect(() => {
    if (user.siteCode) {
      metricsPublisher.publishSearchResultsVisited();
      metricsPublisherVisitedBySite.publishSearchResultsVisitedBySite(
        user.siteCode
      );
    }
  }, [user]);

  // Trigger a search when any of the search parameters change
  useEffect(() => {
    const searchParameters = {
      [searchBy]: searchTerm,
      [SEARCH_PARAMETERS.START_DATE]: startDate,
      [SEARCH_PARAMETERS.END_DATE]: endDate,
    };
    triggerSearch(searchParameters);
  }, [searchBy, searchTerm, startDate, endDate]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    allPageItems,
  } = useCollection(resultClaims, {
    filtering: {
      empty: <TableEmptyState resourceName="Claim" />,
      noMatch: (
        <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
      ),
    },
    pagination: { pageSize: 10 },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "createdAtDate" },
        isDescending: true,
      },
    },
    selection: {},
  });

  return (
    <SpaceBetween direction="vertical" size="s" data-testid="search-results">
      <ClaimsSearchBar topNavigation={false} />
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={WC_CLAIM_COLUMN_DEFINITIONS}
        variant="borderless"
        resizableColumns={false}
        wrapLines={true}
        stripedRows={true}
        header={
          <Header
            actions={
              <ExportToCSVButton
                data={allPageItems as any[]}
                headers={CSV_CUSTOM_HEADERS}
                fileNamePrefix="claims_search_results"
              ></ExportToCSVButton>
            }
          >
            Search Results{" "}
            {isMoreResults && searchBy === SEARCH_PARAMETERS.SITE
              ? "(More claims available, narrow down date range to load all claims)"
              : ""}
          </Header>
        }
        loading={gettingClaims}
        loadingText="Loading Claims"
        filter={
          resultClaims && resultClaims.length >= 5 ? (
            <TextFilter
              {...filterProps}
              data-testid="text-filter"
              filteringAriaLabel="Filter claims"
              filteringPlaceholder="Filter claims"
              filteringClearAriaLabel="Clear"
              countText={
                filteredItemsCount
                  ? `${filteredItemsCount} ${
                      filteredItemsCount === 1 ? "match" : "matches"
                    }`
                  : ""
              }
            />
          ) : null
        }
        pagination={
          resultClaims && resultClaims.length >= 5 ? (
            <Pagination
              {...paginationProps}
              data-testid="pagination"
              ariaLabels={PAGINATION_LABELS}
            />
          ) : null
        }
      />
    </SpaceBetween>
  );
}

export default SearchResults;
