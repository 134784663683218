import {
  Alert,
  FileUploadProps,
  FormField,
  Popover,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { CUSTOM_VALIDATION_TYPES, FormFieldType, INPUT_TYPES } from "../types";
import React, { RefObject } from "react";
import {
  renderSelect,
  renderTextArea,
  renderInput,
  renderDatePicker,
  renderTimeInput,
  renderRadioGroup,
  renderButton,
  renderMultiselect,
  renderFileUpload,
  renderAttributeEditor,
  renderCustomField,
  renderCheckbox,
} from "./";

interface FormControlRenderer {
  [key: string]: () => React.ReactNode;
}
const renderFormField = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: any }) => void,
  onBlur: (field: FormFieldType) => void,
  onFileUpload: (
    { detail }: { detail: FileUploadProps.ChangeDetail },
    field: FormFieldType
  ) => void,
  onClick: (field: FormFieldType) => void,
  ref: RefObject<any> | null | undefined
) => {
  const formControlRenderer: FormControlRenderer = {
    [INPUT_TYPES.PHONE]: () => renderInput(field, onChange, onBlur, ref),
    [INPUT_TYPES.TEXT]: () => renderInput(field, onChange, onBlur, ref),
    [INPUT_TYPES.TEXTAREA]: () => renderTextArea(field, onChange, onBlur),
    [INPUT_TYPES.SELECT]: () => renderSelect(field, onChange, onBlur, ref),
    [INPUT_TYPES.DATE_PICKER]: () => renderDatePicker(field, onChange, onBlur),
    [INPUT_TYPES.TIME]: () => renderTimeInput(field, onChange, onBlur),
    [INPUT_TYPES.RADIO]: () => renderRadioGroup(field, onChange),
    [INPUT_TYPES.BUTTON]: () => renderButton(field, onClick),
    [INPUT_TYPES.MULTISELECT]: () => renderMultiselect(field, onChange, onBlur),
    [INPUT_TYPES.UPLOAD]: () => renderFileUpload(field, onFileUpload),
    [INPUT_TYPES.ATTRIBUTE_EDITOR]: () =>
      renderAttributeEditor(field, onChange, onBlur),
    [INPUT_TYPES.CUSTOM_FIELD]: () => renderCustomField(field),
    [INPUT_TYPES.CHECKBOX]: () => renderCheckbox(field, onChange),
  };
  const formFieldControl = formControlRenderer[field.config.type];
  const name = field.config.uniqueName || field.config.name;
  return (
    <React.Fragment key={`${name}-fragment`}>
      {field.config.customAlert?.display ? (
        <Alert
          dismissible={field.config.customAlert.dismissible}
          type={field.config.customAlert.type}
          header={field.config.customAlert.header}
          statusIconAriaLabel={field.config.customAlert.statusIconAriaLabel}
          dismissAriaLabel={field.config.customAlert.dismissAriaLabel}
          key={`custom-alert-${field.config.label}`}
        >
          {field.config.customAlert.value}
        </Alert>
      ) : null}
      <FormField
        stretch
        description={
          field.config.description === " " ? <wbr /> : field.config.description
        }
        constraintText={
          field.state.constraintText || field.config.constraintText
        }
        label={
          <React.Fragment>
            {`${field.config.label} `}
            {field.validation.required.value ? (
              <span
                aria-label="Required field"
                className="required-asterisk"
                key={`required-asterisk-${name}`}
                data-testid={`required-asterisk-${name}`}
              >
                *
              </span>
            ) : null}
            {field.config.hasPopover ? (
              <Popover
                dismissAriaLabel="Close Tooltip"
                triggerType="custom"
                content={field.config.popoverText}
                header=""
                dismissButton={false}
              >
                <button aria-label="Tooltip" className="info-icon-popover">
                  <StatusIndicator type="info"></StatusIndicator>
                </button>
              </Popover>
            ) : null}
          </React.Fragment>
        }
        errorText={field.state?.errors?.join(", ")}
        i18nStrings={{
          errorIconAriaLabel: "This field has the following error.",
        }}
        key={`field-${name}`}
        data-testid={`${name}FormField`}
      >
        {formFieldControl ? formFieldControl() : null}
      </FormField>
      {field.state.showCustomError
        ? field.validation[CUSTOM_VALIDATION_TYPES.AT_LEAST_ONE_REQUIRED]
            ?.customError
        : null}
    </React.Fragment>
  );
};

export default renderFormField;
