import { FormStateType } from "../../types";
import { ASSOCIATE_INFORMATION_CONSTANTS } from "../../constants";

export const formFieldsState: FormStateType = {
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
    disabled: true,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
    disabled: true,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME]: {
    value: "",
    errors: [],
    hidden: false,
    disabled: true,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE]: {
    value: "Search Associate",
    hidden: false,
    ignoreInFinalForm: true,
    loading: false,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE]: {
    value: "",
    errors: [],
    hidden: true,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS]: {
    value: "",
    errors: [],
    hidden: true,
  },
  [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME]: {
    value: "",
    errors: [],
    hidden: true,
  },
};
