import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getSiteService } from "../api/sites/getSiteService";
import { listSitesService } from "../api/sites/listSitesService";
import { Site } from "./types";
import { updateSiteService } from "../api/sites/updateSiteService";
import {
  CreateSiteCommandInput,
  UpdateSiteCommandInput,
} from "@amzn/ttechclaimintakeservice-client";
import { compareKeys } from "../forms/tools";
import { createSiteService } from "../api/sites/createSiteService";
import { SiteTypes } from "./constants";
// Define a type for the slice state
interface SitesState {
  [index: string]: any;
  [SiteTypes.ADMIN_PANEL_SITE]: Site | null;
  [SiteTypes.INCIDENT_SITE]: Site | null;
  [SiteTypes.ASSOCIATE_HOME_SITE]: Site | null;
  sitesList: { label: string; value: string }[];
  listingSites: boolean;
  gettingSite: boolean;
  updatingSite: boolean;
  creatingSite: boolean;
  siteApiError: any;
  formattedSiteAddress: string[];
  emailNotificationList: string[];
}
export const initialState: SitesState = {
  [SiteTypes.ADMIN_PANEL_SITE]: null,
  [SiteTypes.INCIDENT_SITE]: null,
  [SiteTypes.ASSOCIATE_HOME_SITE]: null,
  sitesList: [],
  listingSites: false,
  gettingSite: false,
  updatingSite: false,
  creatingSite: false,
  siteApiError: null,
  formattedSiteAddress: [],
  emailNotificationList: [],
};

export const verifyStateIsValid = (state: SitesState) => {
  return compareKeys(state, initialState);
};
/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listSites = createAsyncThunk(
  "sites/listSites",
  async (params: { bypassCache: boolean }, thunkAPI: any) => {
    const { bypassCache } = params;
    const response = await listSitesService({ bypassCache });
    return response;
  }
);

export const getSite = createAsyncThunk(
  "sites/getSite",
  async (params: { code: string; type: SiteTypes }, thunkAPI: any) => {
    const { code, type } = params;
    const response = await getSiteService({
      code,
    });
    return { ...response, type };
  }
);

export const updateSite = createAsyncThunk(
  "sites/updateSite",
  async (params: { site: Site }, thunkAPI: any) => {
    const { site } = params;
    const response = await updateSiteService(site as UpdateSiteCommandInput);
    return response;
  }
);
/*eslint-enable @typescript-eslint/no-unused-vars*/

export const createSite = createAsyncThunk(
  "sites/createSite",
  async (
    params: { site: Site },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkAPI: any
  ) => {
    const { site } = params;
    const response = await createSiteService(site as CreateSiteCommandInput);
    return response;
  }
);
export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    resetSitesState: () => {
      return initialState;
    },
    setValueInSitesState: (
      state: SitesState,
      action: PayloadAction<{
        key: string;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setIncidentSiteAsHomeSite: (state: SitesState) => {
      state[SiteTypes.INCIDENT_SITE] =
        current(state)[SiteTypes.ASSOCIATE_HOME_SITE];
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      //Resolvers for listSites
      .addCase(
        listSites.fulfilled,
        (state: SitesState, action: PayloadAction<any>) => {
          const { payload } = action;
          state.sitesList = payload.sites;
          state.listingSites = false;
          state.siteApiError = null;
        }
      )
      .addCase(listSites.pending, (state: SitesState) => {
        state.listingSites = true;
      })
      .addCase(listSites.rejected, (state: SitesState, action: any) => {
        const { error } = action;
        state.siteApiError = { type: "listSites", error };
        state.listingSites = false;
      })
      //Resolvers for getSite
      .addCase(
        getSite.fulfilled,
        (state: SitesState, action: PayloadAction<any>) => {
          const { payload } = action;
          const currentSite = payload;
          /**
           * When no site has been found the service returns a 200 with an empty response so
           * we need to handle this case in the fulfilled callback.
           */
          if (!currentSite?.name) {
            state.siteApiError = { type: "getSite", payload: "Site not Found" };
            state.gettingSite = false;
            state.formattedSiteAddress = [];
            state.emailNotificationList = [];
            if (
              currentSite.type &&
              currentSite.type === SiteTypes.ALL_CLAIM_SITES
            ) {
              state[SiteTypes.ASSOCIATE_HOME_SITE] = null;
              state[SiteTypes.INCIDENT_SITE] = null;
            } else if (currentSite.type) {
              state[currentSite.type] = null;
            }
            return;
          }
          delete currentSite.$metadata;

          if (
            currentSite.type &&
            currentSite.type === SiteTypes.ALL_CLAIM_SITES
          ) {
            state[SiteTypes.ASSOCIATE_HOME_SITE] = currentSite;
            state[SiteTypes.INCIDENT_SITE] = currentSite;
          } else if (currentSite.type) {
            state[currentSite.type] = currentSite;
          }
          state.gettingSite = false;
          state.siteApiError = null;
          state.formattedSiteAddress = [
            `${currentSite?.name}`,
            `${currentSite?.addressLine1} ${currentSite?.addressLine2}`,
            `${currentSite?.city}, ${currentSite?.state} ${currentSite?.zipCode}`,
            `${currentSite?.country}`,
          ];
          state.emailNotificationList = Array.isArray(
            currentSite.notificationAliasList
          )
            ? currentSite.notificationAliasList.map((alias: string) => {
                return `${alias}@amazon.com`;
              })
            : [];
        }
      )
      .addCase(getSite.pending, (state: SitesState) => {
        state.gettingSite = true;
        state.formattedSiteAddress = [];
        // state.currentSite = null;
      })
      .addCase(getSite.rejected, (state: SitesState, action: any) => {
        const { error } = action;
        state.siteApiError = { type: "getSite", error };
        state.gettingSite = false;
      })
      //Resolvers for updateSite
      .addCase(updateSite.fulfilled, (state: SitesState) => {
        state.updatingSite = false;
        state.siteApiError = null;
      })
      .addCase(updateSite.pending, (state: SitesState) => {
        state.updatingSite = true;
      })
      .addCase(updateSite.rejected, (state: SitesState, action: any) => {
        const { error } = action;
        state.siteApiError = { type: "updateSite", error };
        state.updatingSite = false;
      })

      //Resolvers for createSite
      .addCase(createSite.fulfilled, (state: SitesState) => {
        state.creatingSite = false;
        state.siteApiError = null;
      })
      .addCase(createSite.pending, (state: SitesState) => {
        state.creatingSite = true;
      })
      .addCase(createSite.rejected, (state: SitesState, action: any) => {
        const { error } = action;
        state.siteApiError = { type: "createSite", error };
        state.creatingSite = false;
      });
  },
});

export const {
  resetSitesState,
  setValueInSitesState,
  setIncidentSiteAsHomeSite,
} = sitesSlice.actions;

export default sitesSlice.reducer;
