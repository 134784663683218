import {
  CreateWcClaimAttachmentUrlCommand,
  CreateWcClaimAttachmentInput,
} from "@amzn/ttechclaimintakeservice-client";

import { getAuthenticationToken } from "../../auth/helpers";
import { setupClaimIntakeClient } from "../setup/setupClaimIntakeClient";
import { MetricsPublisher } from "../../metrics/metrics";

export async function createWcClaimAttachmentUrlService(
  input: CreateWcClaimAttachmentInput
) {
  const token = await getAuthenticationToken();
  if (!token) {
    return null;
  }
  const client = setupClaimIntakeClient(token);
  const command = new CreateWcClaimAttachmentUrlCommand(input);
  const metricsPublisher = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService"
  );
  const metricsPublisherError = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService.error"
  );
  return await client
    .send(command)
    .then((response: any) => {
      metricsPublisher.publishFileUploadURLLatencyMetrics();
      metricsPublisher.publishFileUploadURLSuccessMetrics();
      return response.url;
    })
    .catch((error: any) => {
      metricsPublisher.publishFileUploadURLLatencyMetrics();
      metricsPublisher.publishFileUploadURLFailureMetrics();
      metricsPublisherError.publishCreateClaimURLError(error.name);
      throw error;
    });
}
