import { Role, User } from "../../auth/types/interfaces";
import { redirect } from "react-router-dom";
import { ROUTES } from "../../app/constants";

/**
 * Loader function for React Router that runs when the user navigates to the Claim Intake Form page.
 * This function checks if the user has the required roles to access the page.
 *
 * @param {User} user - The user object containing the user's information, including roles.
 * @returns {Promise<Response>} A Promise that resolves with a Response object.
 *   If the user has the required roles, it returns a 200 "OK" response.
 *   If the user only has the product group role it redirects to the site configuration page.
 */
export const loader = async (user: User) => {
  if (
    !user.roles?.includes(Role.WC_CLAIM_USER) &&
    !user.roles?.includes(Role.WC_CLAIM_USER_LIMITED) &&
    user.roles?.includes(Role.WC_CLAIM_PRODUCT_GROUP)
  ) {
    // Redirect user to admin site if they only have product group role permissions
    return redirect(ROUTES.SITE_CONFIGURATION);
  }
  return new Response("OK", { status: 200 });
};
