export type ConfigMapperObject = {
  endpoint: string;
};
export type Mapper = Record<string, ConfigMapperObject>;

export enum HttpStatusCodes {
  OK = "200",
  CREATED = "201",
  ACCEPTED = "202",
  NO_CONTENT = "204",
  BAD_REQUEST = "400",
  UNAUTHORIZED = "401",
  FORBIDDEN = "403",
  NOT_FOUND = "404",
  REQUEST_TIMEOUT = "408",
  INTERNAL_SERVER_ERROR = "500",
  NOT_IMPLEMENTED = "501",
  BAD_GATEWAY = "502",
  SERVICE_UNAVAILABLE = "503",
  GATEWAY_TIMEOUT = "504",
}
export const SUCCESS_RESPONSES = [
  HttpStatusCodes.OK,
  HttpStatusCodes.CREATED,
  HttpStatusCodes.ACCEPTED,
  HttpStatusCodes.NO_CONTENT,
];

export const CLIENT_ERROR_RESPONSES = [
  HttpStatusCodes.BAD_REQUEST,
  HttpStatusCodes.UNAUTHORIZED,
  HttpStatusCodes.FORBIDDEN,
  HttpStatusCodes.NOT_FOUND,
  HttpStatusCodes.REQUEST_TIMEOUT,
];

export const SERVER_ERROR_RESPONSES = [
  HttpStatusCodes.INTERNAL_SERVER_ERROR,
  HttpStatusCodes.NOT_IMPLEMENTED,
  HttpStatusCodes.BAD_GATEWAY,
  HttpStatusCodes.SERVICE_UNAVAILABLE,
  HttpStatusCodes.GATEWAY_TIMEOUT,
];
export interface ResponseField {
  fieldName: string;
  fieldValue: any;
  errorCode?: string;
  errorMessage?: string;
}
export interface ProcessedApiResponse {
  data: ResponseField[];
  statusCode?: string;
  warningList?: string[];
  message?: string;
}
export enum Exceptions {
  INVALID_INPUT = "InvalidInputException",
  DATA_MISMATCH = "DataMismatchException",
  EMPLOYEE_ALIAS = "EmployeeAliasException",
}
