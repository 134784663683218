import { FormFieldType } from "../types";
import { Textarea, TextareaProps } from "@amzn/awsui-components-react";
import React from "react";

const renderTextArea = (
  field: FormFieldType,
  onChange: ({ detail }: { detail: TextareaProps.ChangeDetail }) => void,
  onBlur: (field: FormFieldType) => void
) => {
  const name = field.config.uniqueName || field.config.name;
  return (
    <Textarea
      value={field.state.value}
      onChange={onChange}
      placeholder={field.config.placeholderText}
      onBlur={() => onBlur(field)}
      data-testid={field.config.uniqueName || field.config.name}
      key={`${name}-textArea`}
      rows={5}
    />
  );
};

export default renderTextArea;
