import { CognitoAuth } from "amazon-cognito-auth-js";
import { authConfig } from "./config";
import { getEnvironment } from "../environmentInfo/EnvironmentInfo";
import { MetricsPublisher } from "../metrics/metrics";

function getAuthConfigFromEnvironment() {
  return authConfig[getEnvironment() as keyof typeof authConfig];
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable.
//  Sometimes the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
  // Replace the href because the Cognito passes the OAuth2 grant code in the query string
  // And the grant code is not reusable
  if (window.history.length > 0) {
    let newHref = window.location.href.split("?")[0];

    const params = new URLSearchParams(window.location.href.split("?")[1]);
    params.delete("code");
    params.delete("state");

    //Add rest of the query params if they exist
    newHref = params.entries().next().value
      ? `${newHref}?${params.toString()}`
      : newHref;

    window.history.replaceState(undefined, "", newHref);
  }
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated(): Promise<CognitoAuth> {
  return new Promise((resolve) => {
    const auth = new CognitoAuth(getAuthConfigFromEnvironment());
    const metricsPublisher = new MetricsPublisher("api.authenticate");
    auth.useCodeGrantFlow();

    // Register callback functions
    auth.userhandler = {
      onFailure: () => {
        removeQueryFromLocation();
        metricsPublisher.publishAuthenticationTokenInvalidMetrics();
        /* Log in may fail if an authorized user's refresh token has expired.
         * Clear the cache will redirect them to midway page for authentication and acquire a new refresh token
         * Yet this may result in infinite loop for unauthorized user.
         * May need to change this to sign out button in the future.
         */
        auth.clearCachedTokensScopes();
        window.location.reload();
      },
      onSuccess: () => {
        // We only need to remove the query string if the original href saved before
        // getting the session is not present.

        if (!sessionStorage.getItem("hrefBeforeGetSession")) {
          removeQueryFromLocation();
        }
        metricsPublisher.publishAuthenticationTokenValidMetrics();
        //Getting tokens here as well to get them on first load
        resolve(auth);
      },
    };

    const href = window.location.href;
    const session = auth.getSignInUserSession();

    if (session.isValid()) {
      resolve(auth);
    } else if (href.indexOf("?code") > 0) {
      // This is required because Cognito needs to get the authentication result from the query string
      // The parsing is done asynchronously, and the result will be passed to the userHandler.
      // Once the result is parsed, onSuccess userhandler will resolve the promise.
      auth.parseCognitoWebResponse(href);
    } else {
      // Save href before getting the session to redirect in case we are not coming from the base URL
      // Using session storage to avoid redirecting to root when opening new tabs as session storage is
      // not shared between tabs
      sessionStorage.setItem("hrefBeforeGetSession", window.location.href);

      // Cognito SDK will handle session refresh / authentication.
      auth.getSession();
    }
  });
}
