import { FILE_ATTACHMENTS_CONSTANTS } from "../../constants";
import { FormValidationType } from "../../types";

export const formFieldsValidation: FormValidationType = {
  [FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM]: {
    required: {
      value: true,
      message: FILE_ATTACHMENTS_CONSTANTS.IRF_REQUIRED_ERROR,
    },
  },
  [FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE]: {
    required: { value: false },
  },
  [FILE_ATTACHMENTS_CONSTANTS.AUSTIN_NOTES]: {
    required: {
      value: true,
      message: FILE_ATTACHMENTS_CONSTANTS.AUSTIN_NOTES_REQUIRED_ERROR,
    },
  },
  [FILE_ATTACHMENTS_CONSTANTS.AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION]: {
    required: {
      value: true,
      message:
        FILE_ATTACHMENTS_CONSTANTS.AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION_REQUIRED_ERROR,
    },
  },
  [FILE_ATTACHMENTS_CONSTANTS.STATE_FORMS]: {
    required: { value: false },
  },
  [FILE_ATTACHMENTS_CONSTANTS.WITNESS_STATEMENTS]: {
    required: { value: false },
  },
  [FILE_ATTACHMENTS_CONSTANTS.PHOTOS]: {
    required: { value: false },
  },
  [FILE_ATTACHMENTS_CONSTANTS.OTHER_RELEVANT]: {
    required: { value: false },
  },
};
