import { INCIDENT_INFORMATION_CONSTANTS } from "../../constants";
import { FormStateType } from "../../types";

export const formFieldsState: FormStateType = {
  [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
    hidden: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
    value: "",
    errors: [],
    hidden: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
    value: INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_BODY_PART,
    hidden: false,
    ignoreInFinalForm: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
    value: "",
    errors: [],
    hidden: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
    value: "",
    errors: [],
    hidden: true,
    ignoreInFinalForm: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST]: {
    value: [],
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.LOSS_DESCRIPTION]: {
    value: "",
    errors: [],
    hidden: false,
  },

  [INCIDENT_INFORMATION_CONSTANTS.REFERRAL_TYPE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM]: {
    value: null,
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP]: {
    hidden: true,
    errors: [],
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS]: {
    value: [],
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON]: {
    value: "",
    errors: [],
    hidden: false,
    showCustomError: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE]: {
    value: "false",
    hidden: false,
    errors: [],
  },

  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0]: {
    hidden: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_FIRST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_LAST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_ALIAS]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_EMAIL]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_PHONE]: {
    value: "",
    errors: [],
    hidden: false,
  },

  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1]: {
    hidden: true,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_FIRST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_LAST_NAME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_ALIAS]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_EMAIL]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_PHONE]: {
    value: "",
    errors: [],
    hidden: false,
  },

  [INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS]: {
    value: INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_WITNESS,
    errors: [],
    hidden: true,
    ignoreInFinalForm: true,
  },
};
