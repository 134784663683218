import { Environments } from "src/environmentInfo/Constants";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
  REVIEW_PAGE_CONSTANTS,
} from "src/forms/constants";

export const FORM_PAGE_ERROR_MESSAGE =
  "Please fix all the errors in the form before proceeding.";

export const STEP_NAMES = [
  ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME,
  INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME,
  INCIDENT_INFORMATION_CONSTANTS.FORM_NAME,
  PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME,
  FILE_ATTACHMENTS_CONSTANTS.FORM_NAME,
  REVIEW_PAGE_CONSTANTS.FORM_NAME,
];
export const MAX_STEPS = STEP_NAMES.length;

export const DUPLICATE_MODAL_TITLE = "Similar Claim Found";
export enum ROUTES {
  CLAIM_INTAKE_FORM = "/",
  MEDICAL_PANEL_DATA = "/medical-panel-data",
  SITE_CONFIGURATION = "/admin/site-configuration",
  MY_SUBMITTED_CLAIMS = "/my-submitted-claims",
  CLAIMS_HISTORY = "/claims-history",
}
export enum PAGE_TITLES {
  CLAIM_INTAKE_FORM = "Claim Intake",
  MEDICAL_PANEL_DATA = "Preferred Provider List/Medical Provider Panel",
  SITE_CONFIGURATION = "Nucleus Sites Admin Panel",
  MY_SUBMITTED_CLAIMS = "My Submitted Claims",
  CLAIMS_HISTORY = "Claims History",
}

// Use this definition to change where the local environment points to when testing
export const LOCALHOST_SERVICE_ENVIRONMENT = Environments.BETA;
export const USER_GUIDE_URL =
  "https://w.amazon.com/bin/view/Treasury/TreasuryTech/ClaimIntake/ClientDocumentation/GettingStarted/";

export const PROVIDE_FEEDBACK_URL =
  "https://survey.fieldsense.whs.amazon.dev/survey/fa5532a5-f001-4f2e-8328-4e145d9d8944";

export const AMAZON_CLAIM_REFERENCE_ID_MAX_SIZE = 10;
