import { NOTIFICATIONS_CONSTANTS } from "../../constants";
import { FormStateType } from "../../types";

export const formFieldsState: FormStateType = {
  [NOTIFICATIONS_CONSTANTS.READ_ONLY_SITE_NOTIFICATIONS]: {
    value: "",
    hidden: false,
    ignoreInFinalForm: true,
  },
};
