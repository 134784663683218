import { DateRangePickerProps } from "@amzn/awsui-components-react";
import { WcClaimSummary } from "@amzn/ttechclaimintakeservice-client";

export enum SEARCH_PARAMETERS {
  ALIAS = "alias",
  EMPLOYEE_ID = "employeeId",
  SITE = "site",
  START_DATE = "startDate",
  END_DATE = "endDate",
}
export enum RELATIVE_OPTION_KEYS {
  PAST_24_HOURS = "past-24-hours",
  PAST_WEEK = "past-week",
  PAST_MONTH = "past-month",
  PAST_6_MONTHS = "past-6-months",
  PAST_YEAR = "past-year",
  PAST_10_YEARS = "past-10-years",
}

export const RELATIVE_RANGE_STRINGS: { [key: string]: string } = {
  [RELATIVE_OPTION_KEYS.PAST_24_HOURS]: "Past 24 Hours",
  [RELATIVE_OPTION_KEYS.PAST_WEEK]: "Past Week",
  [RELATIVE_OPTION_KEYS.PAST_MONTH]: "Past Month",
  [RELATIVE_OPTION_KEYS.PAST_6_MONTHS]: "Past 6 Months",
  [RELATIVE_OPTION_KEYS.PAST_YEAR]: "Past Year",
  [RELATIVE_OPTION_KEYS.PAST_10_YEARS]: "Past 10 Years",
};

export const RELATIVE_OPTIONS: DateRangePickerProps.RelativeOption[] = [
  {
    key: RELATIVE_OPTION_KEYS.PAST_24_HOURS,
    amount: 1,
    unit: "day",
    type: "relative",
  },
  {
    key: RELATIVE_OPTION_KEYS.PAST_WEEK,
    amount: 7,
    unit: "day",
    type: "relative",
  },
  {
    key: RELATIVE_OPTION_KEYS.PAST_MONTH,
    amount: 1,
    unit: "month",
    type: "relative",
  },
  {
    key: RELATIVE_OPTION_KEYS.PAST_6_MONTHS,
    amount: 6,
    unit: "month",
    type: "relative",
  },
  {
    key: RELATIVE_OPTION_KEYS.PAST_YEAR,
    amount: 1,
    unit: "year",
    type: "relative",
  },
  {
    key: RELATIVE_OPTION_KEYS.PAST_10_YEARS,
    amount: 10,
    unit: "year",
    type: "relative",
  },
];
export interface ParsedClaimSummary extends WcClaimSummary {
  incidentDate?: string;
  createdAtDate?: string;
}

export const CSV_CUSTOM_HEADERS = [
  { label: "Incident Site", key: "incidentSiteName" },
  { label: "Claim Number", key: "claimNumber" },
  { label: "Employee Login", key: "associateAlias" },
  { label: "Employee ID", key: "associateEmployeeId" },
  { label: "Incident Date", key: "incidentDate" },
  { label: "Submitted Date", key: "createdAtDate" },
  { label: "Submitter Login", key: "claimReporterAlias" },
  { label: "Body Part", key: "bodyPart" },
];
