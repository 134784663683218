import { CUSTOM_VALIDATION_TYPES, FormValidationType } from "../../types";
import {
  EMAIL_REGEX,
  GENERAL_FORM_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  SECONDARY_INJURED_BODY_PART_REQUIRED_ERROR,
  SECONDARY_INJURED_SIDE_REQUIRED_ERROR,
} from "../../constants";
import { Alert } from "@amzn/awsui-components-react/polaris";

const CONCERNS_VALIDATE_TOGETHER_ALERT = (
  <div role="alert">
    <Alert
      statusIconAriaLabel="Error"
      type={"error"}
      header={
        INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_AT_LEAST_ONE_REQUIRED_ERROR
      }
      data-testid={`quetionable-claims-error-alert`}
    ></Alert>
  </div>
);
export const formFieldsValidation: FormValidationType = {
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
    required: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
    required: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
    required: {
      value: true,
      message: SECONDARY_INJURED_BODY_PART_REQUIRED_ERROR,
    },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
    required: { value: true, message: SECONDARY_INJURED_SIDE_REQUIRED_ERROR },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
    required: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT]: {
    required: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST]: {
    required: { value: true },
    maxOptionsSelected: { value: 4 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.LOSS_DESCRIPTION]: {
    required: { value: true },
    maxCharacters: { value: 2000 },
    minCharacters: { value: 5 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.REFERRAL_TYPE]: {
    required: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM]: {
    required: {
      value: true,
      message: "Please answer this question before proceeding.",
    },
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS]: {
    required: { value: false },
    [CUSTOM_VALIDATION_TYPES.AT_LEAST_ONE_REQUIRED]: {
      value: true,
      joinWith: {
        value:
          INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON,
        message: " ",
        customError: CONCERNS_VALIDATE_TOGETHER_ALERT,
      },
      message: " ",
    },
  },
  [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON]: {
    required: { value: false },
    maxCharacters: { value: 700 },
    minCharacters: { value: 5 },
    [CUSTOM_VALIDATION_TYPES.AT_LEAST_ONE_REQUIRED]: {
      value: true,
      joinWith: {
        value: INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS,
        message: " ",
      },
      message: " ",
      customError: CONCERNS_VALIDATE_TOGETHER_ALERT,
    },
  },

  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_FIRST_NAME]: {
    required: {
      value: true,
      message:
        "Please provide the witness's first name when adding a witness to the form",
    },
    maxCharacters: { value: 20 },
    minCharacters: { value: 2 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_LAST_NAME]: {
    required: {
      value: true,
      message:
        "Please provide the witness's last name when adding a witness to the form",
    },
    maxCharacters: { value: 25 },
    minCharacters: { value: 2 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_ALIAS]: {
    required: { value: false },
    maxCharacters: { value: 11 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_EMAIL]: {
    required: { value: false },
    maxCharacters: { value: 320 },
    matchRegExp: {
      value: new RegExp(EMAIL_REGEX),
      message: GENERAL_FORM_CONSTANTS.EMAIL_INVALID_MESSAGE,
    },
    [CUSTOM_VALIDATION_TYPES.REMOVE_WHITE_SPACE]: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_PHONE]: {
    required: { value: false },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.PHONE_REGEX),
      message: GENERAL_FORM_CONSTANTS.PHONE_INVALID_MESSAGE,
    },
  },

  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1]: {
    required: { value: false },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_FIRST_NAME]: {
    required: {
      value: true,
      message:
        "Please provide the witness's first name when adding a witness to the form",
    },
    maxCharacters: { value: 20 },
    minCharacters: { value: 2 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_LAST_NAME]: {
    required: {
      value: true,
      message:
        "Please provide the witness's last name when adding a witness to the form",
    },
    maxCharacters: { value: 25 },
    minCharacters: { value: 2 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_ALIAS]: {
    required: { value: false },
    maxCharacters: { value: 11 },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_EMAIL]: {
    required: { value: false },
    maxCharacters: { value: 320 },
    matchRegExp: {
      value: new RegExp(EMAIL_REGEX),
      message: GENERAL_FORM_CONSTANTS.EMAIL_INVALID_MESSAGE,
    },
    [CUSTOM_VALIDATION_TYPES.REMOVE_WHITE_SPACE]: { value: true },
  },
  [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_PHONE]: {
    required: { value: false },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.PHONE_REGEX),
      message: GENERAL_FORM_CONSTANTS.PHONE_INVALID_MESSAGE,
    },
  },

  [INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS]: {
    required: { value: false },
  },
};
