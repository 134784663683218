import React from "react";
import {
  Alert,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react/polaris";

const PAGE_ONE_MESSAGE = (
  <TextContent>
    <SpaceBetween direction="vertical" size="xxs">
      <span>
        <b>Reminder: </b>For the following states{" "}
        <b>
          <u>only</u>
        </b>
        : Alaska, Arizona, California, Colorado, Hawaii, Iowa, Idaho, Illinois,
        Kansas, Missouri, Minnesota, Montana, Nebraska, New Mexico, Nevada,
        Oregon, South Dakota, and Utah.
      </span>
      <span>
        <b>For date of injury BEFORE 10/1/24: </b> List Sedgwick on
        workers&apos; compensation documentation where applicable.{" "}
      </span>
      <span>
        <b>For date of injury ON or AFTER 10/1/24: </b> List Helmsman on
        workers&apos; compensation documentation where applicable.
      </span>
    </SpaceBetween>
  </TextContent>
);

const PageOneBanner = () => {
  return (
    <Alert data-testid="page-one-banner" type="info">
      {PAGE_ONE_MESSAGE}
    </Alert>
  );
};

export default PageOneBanner;
