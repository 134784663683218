import { formFieldsConfigProcessed } from "./config/IncidentTimeAndPlaceConfig";
import { INCIDENT_TIME_AND_PLACE_CONSTANTS } from "../constants";
import { formFieldsState } from "./state/IncidentTimeAndPlaceState";
import { formFieldsValidation } from "./validations/IncidentTimeAndPlaceValidations";

export const formConfig = formFieldsConfigProcessed;
export const formState = formFieldsState;
export const formValidation = formFieldsValidation;

export const formHeader = "Incident Time and Place";
export const formDescription =
  "Enter the details of when and where the incident ocurred.";
export const formName = INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME;
