import { ParsedClaimSummary } from "./constants";

export const convertTimestampsToDates = (
  resultClaims: ParsedClaimSummary[]
) => {
  if (resultClaims?.length > 0) {
    return resultClaims.map((claim) => {
      let incidentDate = "";
      let createdAtDate = "";

      if (claim?.incidentTimestamp) {
        incidentDate = new Date(
          claim.incidentTimestamp! * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
      }

      if (claim?.createdAtTimestamp) {
        createdAtDate = new Date(
          claim.createdAtTimestamp! * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
      }

      return {
        ...claim,
        incidentDate,
        createdAtDate,
      };
    });
  } else return resultClaims;
};

export const adjustDayTtimestampToLatestTime = (timeStamp: number) => {
  return timeStamp;
};
