import { useLayoutEffect, useEffect } from "react";
import { RootState } from "src/redux/store";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  setAlert,
  setLoaded,
  setValidating,
  setValueInFormsState,
  validateAll,
} from "src/forms/formsSlice";
import { copyLocalState, setGoingBack, setSkipToStep } from "./wizardSlice";
import { isDevEnvironment } from "../../environmentInfo/EnvironmentInfo";
import * as React from "react";
import { FormValidationType } from "src/forms/types";
import { STEP_NAMES } from "src/app/constants";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  REVIEW_PAGE_CONSTANTS,
} from "src/forms/constants";
import {
  Button,
  Wizard,
  WizardProps,
} from "@amzn/awsui-components-react/polaris";
import PageOneBanner from "src/forms/WorkersCompensation/PageOneBanner";

interface WizardWrapperProps {
  steps: WizardProps.Step[];
  onSubmit: any;
  onNext?: any;
  onReset?: any;
  onPrevious: any;
  currentStep: number;
  onSkipTo?: any;
  stepNames: string[];
  allFormValidations?: {
    [key: string]: FormValidationType;
  };
}

interface WizardWrapperState {
  currentIndex: number;
  farthestStepIndex: number;
  skipToActive: number;
  goingBack: boolean;
}
function WizardWrapper(props: WizardWrapperProps) {
  const dispatch = useAppDispatch();
  //Redux Form state
  const {
    errors,
    validating,
    invalidForms,
    employeeDetailsRetrieved,
    uploadingFile,
  } = useAppSelector((state: RootState) => state.forms);

  //Redux Wizard state
  const state = useAppSelector((state: RootState) => state.wizard);

  //Redux App state
  const { tpaAssigned, tpaMaintenance } = useAppSelector(
    (state: RootState) => state.app
  );

  //Redux Sites state
  const { associateHomeSite } = useAppSelector(
    (state: RootState) => state.sites
  );
  const { goingBack, skipToStep } = state;

  function setState(stateToUpdate: WizardWrapperState) {
    dispatch(copyLocalState({ localState: { ...stateToUpdate } }));
  }
  useLayoutEffect(() => {
    if (validating) return;
    const { length } = props.steps;
    const nextIndex = resolveCurrentIndex(length, state);
    const farthestStepIndex = resolveFarthestIndex(length, state, nextIndex);

    if (state.currentIndex !== nextIndex) {
      dispatch(setLoaded({ value: false }));
    }
    assignTpa(nextIndex);
    setState({
      ...state,
      currentIndex: nextIndex,
      farthestStepIndex,
    });
    //Scroll to top
    window.scrollTo(0, 0);
  }, [errors, validating, state.goingBack]);

  useEffect(() => {
    if (goingBack) {
      dispatch(setGoingBack({ value: false }));
    }
  }, [goingBack]);

  useEffect(() => {
    if (skipToStep >= 0) {
      handleSkipTo(skipToStep);
      dispatch(setSkipToStep({ value: -1 }));
    }
  }, [skipToStep]);

  function triggerValidation() {
    const formName = props.stepNames[state.currentIndex];
    const formValidation = props.allFormValidations?.[formName];
    dispatch(setValidating({ value: true }));
    dispatch(validateAll({ formName, formValidation }));
  }
  function resolveCurrentIndex(length: number, state: WizardWrapperState) {
    const { currentIndex, skipToActive } = state;
    // If we don't have a validation error OR we are going to a previous page
    if (!errors.length || state.goingBack) {
      // dispatch(setGoingBack({ value: false }));
      return Math.min(skipToActive, length - 1);
    }
    // Stay on current page
    return Math.min(currentIndex, length - 1);
  }
  function resolveFarthestIndex(
    length: number,
    state: WizardWrapperState,
    nextIndex: number
  ) {
    const { currentIndex, farthestStepIndex } = state;
    // If user caused a validation error set error page as farthest
    if (invalidForms[props.stepNames[state.currentIndex]]) {
      return currentIndex;
    }
    return nextIndex > farthestStepIndex
      ? Math.min(nextIndex, length - 1)
      : farthestStepIndex;
  }
  function onNext() {
    if (isFirstStepValid()) {
      triggerValidation();
      setState({
        ...state,
        goingBack: false,
        skipToActive: state.currentIndex + 1,
      });
    }
  }

  function onPrevious() {
    props.onPrevious();
    handleSkipTo(Math.max(state.currentIndex - 1, 0));
  }

  function handleSkipTo(nextStep: number) {
    if (isDevEnvironment()) {
      setState({
        ...state,
        skipToActive: nextStep,
        currentIndex: nextStep,
      });
      dispatch(setLoaded({ value: false }));
      dispatch(setAlert({ text: "", type: "error" }));
      return;
    }

    if (isFirstStepValid()) {
      setState({
        ...state,
        goingBack: false,
        skipToActive: nextStep,
      });
      triggerValidation();
    }
  }

  function isFirstStepValid() {
    if (state.currentIndex === 0 && !employeeDetailsRetrieved) {
      dispatch(
        setAlert({
          text: `Please make sure you have retrieved the correct employee data by clicking on "Search Associate" before continuing.`,
          type: "error",
        })
      );
      return false;
    }
    dispatch(setAlert({ text: "", type: "error" }));
    return true;
  }

  function assignTpa(nextIndex: number) {
    // Assign the TPA for this claim when we reach page 3 of the form
    if (
      nextIndex ===
        STEP_NAMES.indexOf(INCIDENT_INFORMATION_CONSTANTS.FORM_NAME) &&
      !tpaAssigned
    ) {
      // This value is used to check if TPA has been assigned and user wants to modify the Incident site or date
      dispatch(setValueInFormsState({ key: "tpaAssigned", value: true }));
    }
  }

  function onNavigate({ detail }: { detail: WizardProps.NavigateDetail }) {
    if (detail.reason === "next") {
      onNext();
    }
    if (detail.reason === "previous") {
      onPrevious();
    }
    if (detail.reason === "skip" || detail.reason === "step") {
      handleSkipTo(detail.requestedStepIndex);
    }
  }

  function renderSkipToReviewPage() {
    const showSkipToReviewPage =
      state.farthestStepIndex >= props.steps.length - 1 &&
      state.currentIndex < props.steps.length - 1;
    if (!showSkipToReviewPage) {
      return null;
    }
    return (
      <Button onClick={() => handleSkipTo(props.steps.length - 1)}>
        Skip to Review Page
      </Button>
    );
  }
  // Prevent currentIndex from being greater than available steps
  if (state.currentIndex > props.steps.length - 1) {
    setState({
      ...state,
      currentIndex: props.steps.length - 1,
    });
  }
  const disableNext: boolean =
    state.currentIndex ===
      STEP_NAMES.indexOf(FILE_ATTACHMENTS_CONSTANTS.FORM_NAME) &&
    !!uploadingFile;
  const disableSubmit: boolean =
    (state.currentIndex ===
      STEP_NAMES.indexOf(REVIEW_PAGE_CONSTANTS.FORM_NAME) &&
      !associateHomeSite) ||
    !!tpaMaintenance;
  return (
    <div>
      {STEP_NAMES[state.currentIndex] ===
        ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME && <PageOneBanner />}
      <Wizard
        onSubmit={props.onSubmit}
        onNavigate={onNavigate}
        onCancel={props.onReset}
        activeStepIndex={state.currentIndex}
        steps={props.steps}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Reset Form",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Submit Claim",
          optional: `optional${isDevEnvironment() ? " (dev)" : ""}`,
        }}
        secondaryActions={renderSkipToReviewPage()}
        isLoadingNextStep={
          !isDevEnvironment() && (disableNext || disableSubmit)
        }
        allowSkipTo={isDevEnvironment()}
      />
    </div>
  );
}

export default WizardWrapper;
